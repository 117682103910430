import { Button } from '@mui/material';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from './logo.png';

import { Fab } from '@mui/material';

import ShareIcon from '@mui/icons-material/Share';
//import { formatNumber } from '../../../components/MaskedInput/masks';
import { Box } from '@mui/system';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { formatNumber } from 'utils/formatNumber';

import { combustiveis } from 'constantes/combustivel';

export default function Test({ eficiencia, dados }) {
  const baixarPDF = async (baixar) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const details = [
      {
        style: 'tableExample',
        table: {
          widths: ['*', '*', '*', '*', 120, '*', '*', '*', '*'],
          //headerRows: 6,
          body: [
            [
              {
                text: 'Pressão Máxima de Trabalho Admissível',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(pressaoMaximaTrabalho)} kgf/cm²`,
                alignment: 'center',
                fillColor: '#002060',
                color: '#fff',
                bold: true,
                border: [false, true, false, true],
              },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'Pressão de Operação',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getPressaoOperacao()
                )} kgf/cm²`,
                alignment: 'center',
                bold: true,
                border: [false, true, false, true],
              },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'Vazão de vapor',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(vazaoVapor)} kg/h`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'Vapor Saturado  / Vapor Superaquecido',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${vaporSaturadoSuperaquecido == 'vaporSaturado' ? 'Vapor Saturado' : 'Vapor Superaquecido'}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'Temperatura do Vapor Superaquecido',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${vaporSaturadoSuperaquecido == 'vaporSaturado' ? 'Saturado' : temperaturaVaporSuperaquecido + '°C'}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: '% da condensação',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${condensacao} %`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: 'COMBUSTIVEL',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${combustivel}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {
                text: '% Umidade do Combustível',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${umidadeCombustivel} %`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, false, true] },
              { text: '', border: [false, true, true, true] },
            ],
            [{ text: '', colSpan: 9, margin: [0, 5, 0, 5] }],
            [
              {
                text: 'Combustível: Sólido e Líquido (1) / Gás (2)',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${estadoCombustivel}`,
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                alignment: 'center',
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'Composição do combustível - % Peso',
                colSpan: 4,
                margin: [0, 5, 0, 5],
              },
              {},
              {},
              {},
              {
                text: `Base seca`,
                margin: [0, 5, 0, 5],
                alignment: 'center',
              },
              {
                text: `Base úmida`,
                margin: [0, 5, 0, 5],
                alignment: 'center',
              },
              {
                text: ``,
                border: [true, true, false, false],
              },
              {
                text: ``,
                border: [false, true, false, false],
              },
              {
                text: ``,
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: 'Carbono',
                colSpan: 3,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: '%',
                alignment: 'right',
                border: [false, true, true, true],
              },
              {
                text: `${formatNumber(carbono)}`,
                alignment: 'center',
              },
              {
                text: `${formatNumber(
                  eficiencia.getBaseUmida().carbono.toFixed(2)
                )}`,
                alignment: 'center',
              },
              {
                text: ``,
                border: [true, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'Hidrogênio',
                colSpan: 3,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: '%',
                alignment: 'right',
                border: [false, true, true, true],
              },
              {
                text: `${formatNumber(hidrogenio)}`,
                alignment: 'center',
              },
              {
                text: `${formatNumber(
                  eficiencia.getBaseUmida().hidrogenio.toFixed(2)
                )}`,
                alignment: 'center',
              },
              {
                text: ``,
                border: [true, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'Oxigênio',
                colSpan: 3,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: '%',
                alignment: 'right',
                border: [false, true, true, true],
              },
              {
                text: `${formatNumber(oxigenio)}`,
                alignment: 'center',
              },
              {
                text: `${formatNumber(
                  eficiencia.getBaseUmida().oxigenio.toFixed(2)
                )}`,
                alignment: 'center',
              },
              {
                text: ``,
                border: [true, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'Enxofre',
                colSpan: 3,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: '%',
                alignment: 'right',
                border: [false, true, true, true],
              },
              {
                text: `${formatNumber(enxofre)}`,
                alignment: 'center',
              },
              {
                text: `${formatNumber(
                  eficiencia.getBaseUmida().enxofre.toFixed(2)
                )}`,
                alignment: 'center',
              },
              {
                text: ``,
                border: [true, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'Nitrogênio',
                colSpan: 3,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: '%',
                alignment: 'right',
                border: [false, true, true, true],
              },
              {
                text: `${formatNumber(nitrogenio)}`,
                alignment: 'center',
              },
              {
                text: `${formatNumber(
                  eficiencia.getBaseUmida().nitrogenio.toFixed(2)
                )}`,
                alignment: 'center',
              },
              {
                text: ``,
                border: [true, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'Cinzas',
                colSpan: 3,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: '%',
                alignment: 'right',
                border: [false, true, true, true],
              },
              {
                text: `${formatNumber(cinzas)}`,
                alignment: 'center',
              },
              {
                text: `${formatNumber(
                  eficiencia.getBaseUmida().cinzas.toFixed(2)
                )}`,
                alignment: 'center',
              },
              {
                text: ``,
                border: [true, false, false, false],
              },
              {
                text: ``,
                border: [false, false, false, false],
              },
              {
                text: ``,
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'Total é igual a 100',
                colSpan: 3,
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: '%',
                alignment: 'right',
                border: [false, true, true, true],
              },
              {
                text: `${somaBaseSeca.toFixed(0)}`,
                alignment: 'center',
              },
              {
                text: `${somaBaseUmida.toFixed(0)}`,
                alignment: 'center',
              },
              {
                text: ``,
                border: [true, false, false, true],
              },
              {
                text: ``,
                border: [false, false, false, true],
              },
              {
                text: ``,
                border: [false, false, true, true],
              },
            ],
            [{ text: '', colSpan: 9, margin: [0, 5, 0, 5] }],
            [
              {
                text: 'PCS - Base seca (Kcal/Kg) teorico para composição informada',
                colSpan: 4,
                border: [true, true, false, true],
                margin: [0, 5, 0, 5],
                fillColor: '#d8d8d8',
                fontSize: 10,
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getPCSTeorico(), {
                  maximumFractionDigits: 0
                })}`,
                margin: [0, 2.5, 0, 0],
                fillColor: '#d8d8d8',
                color: '#0070c0',
                alignment: 'center',
                fontSize: 16,
                bold: true,
                border: [false, true, false, true],
              },
              {
                text: `Kcal/Kg`,
                margin: [0, 5, 0, 5],
                fillColor: '#d8d8d8',
                fontSize: 10,
                border: [false, true, true, true],
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'PCS - Base seca (Kcal/Kg)',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(pcs)}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'Excesso de ar na fornalha (%)',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${excessoDeAr}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '%',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                text: 'Altitude local',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(altituteLocal)}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: 'm',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                text: 'Pressão atmosférica local (mmHg)',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getPressaoAtmosfericaLocal(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                bold: true,
                border: [false, true, false, true],
              },
              {
                text: 'mmHg',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                text: 'Temperatura do combustível',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${temperaturaCombustivel}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '°C',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                text: 'Temperatura de entrada da água',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${temperaturaEntradaAgua}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '°C',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                text: 'Temperatura de entrada do ar',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${temperaturaEntradaAr}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '°C',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                text: 'Temperatura de saída dos gases',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${temperaturaSaidaGases}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '°C',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: 'Eficiência ao PCI - as fired',
                colSpan: 3,
                alignment: 'center',
              },
              {},
              {},
            ],
            [
              {
                text: 'Porcentagem de overfire',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(overfire)}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '%',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: `${formatNumber(eficiencia.getEficienciaAoPci(), {
                  maximumFractionDigits: 2,
                })} %`,
                alignment: 'center',
                colSpan: 3,
                rowSpan: 5,
                fontSize: 36,
                bold: true,
                margin: [0, 24, 0, 0],
              },
              {},
              {},
            ],
            [
              {
                text: 'Horas trabalhadas por dia',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${horasTrabalhadasDia}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'Dias trabalhados por mês',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${diasTrabalhadosMes}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'Perdas por radiação e descarga de fundos',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(perdasRadiacao)}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '%',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'Perdas por combustível não queimado',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(perdasCombustivelNaoQueimado)}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '%',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'Margem do fabricante',
                colSpan: 4,
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(margemFabricante)}`,
                alignment: 'center',
                bold: true,
                fillColor: '#002060',
                color: '#fff',
                border: [false, true, false, true],
              },
              {
                text: '%',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, false, true, true],
                colSpan: 3,
              },
              {},
              {},
            ],
            [{ text: '', colSpan: 9, margin: [0, 5, 0, 5] }],
            [
              {
                text: 'COMPOSIÇÃO DOS GASES NA CHAMINÉ',
                bold: true,
                colSpan: 7,
                alignment: 'center',
                border: [true, true, false, true],
                margin: [0, 5, 0, 5],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: 'EMISSÕES',
                bold: true,
                colSpan: 2,
                alignment: 'center',
                border: [false, true, true, true],
                margin: [0, 5, 0, 5],
              },
              {},
            ],
            [
              {
                text: '',
                border: [true, true, false, true],
                colSpan: 3,
              },
              {},
              {},
              {
                text: 'Un. de gás',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '%massa',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'N. moles',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '%volume',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'mg/Nm³',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'mg/m³',
                alignment: 'center',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: 'N2',
                colSpan: 2,
                border: [true, true, false, true],
              },
              {},
              {
                text: `${formatNumber(eficiencia.getComposicaoN2naChamine(), {
                  maximumFractionDigits: eficiencia.getComposicaoN2naChamine() < 0.01 ? 4 : 2
                })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getUnidadeGasN2naChamine(), {
                  maximumFractionDigits: eficiencia.getUnidadeGasN2naChamine() < 0.01 ? 4 : 2
                })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemMassaN2naChamine(), {
                    maximumFractionDigits: eficiencia.getPorcentagemMassaN2naChamine() < 0.01 ? 4 : 2
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
              },
              {
                text: `${formatNumber(eficiencia.getNumeroMolesN2naChamine(), {
                  maximumFractionDigits: eficiencia.getNumeroMolesN2naChamine() < 0.01 ? 4 : 2
                })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemVolumeN2naChamine(), {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesN2ChamineMgPorNm3(), {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [true, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesN2ChamineMgPorM3(), {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: 'H2O',
                colSpan: 2,
                border: [true, true, false, true],
              },
              {},
              {
                text: `${formatNumber(eficiencia.getComposicaoH2OnaChamine(),
                  {
                    maximumFractionDigits: eficiencia.getComposicaoH2OnaChamine() < 0.01 ? 4 : 2
                  }
                  )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getUnidadeGasH2OnaChamine(),
                  {
                    maximumFractionDigits: eficiencia.getUnidadeGasH2OnaChamine() < 0.01 ? 4 : 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemMassaH2OnaChamine(),
                  {
                    maximumFractionDigits: eficiencia.getPorcentagemMassaH2OnaChamine() < 0.01 ? 4 : 2
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
              },
              {
                text: `${formatNumber(
                  eficiencia.getNumeroMolesH2OnaChamine(),
                  {
                    maximumFractionDigits: eficiencia.getNumeroMolesH2OnaChamine() < 0.01 ? 4 : 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemVolumeH2OnaChamine(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesH20ChamineMgPorNm3(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [true, false, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesH20ChamineMgPorM3(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [false, false, true, true],
              },
            ],
            [
              {
                text: 'SO2',
                colSpan: 2,
                border: [true, true, false, true],
              },
              {},
              {
                text: `${formatNumber(eficiencia.getComposicaoSO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getComposicaoSO2naChamine() < 0.01 ? 4 : 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getComposicaoSO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getComposicaoSO2naChamine() < 0.01 ? 4 : 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemMassaSO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getPorcentagemMassaSO2naChamine() < 0.01 ? 4 : 2
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
              },
              {
                text: `${formatNumber(
                  eficiencia.getNumeroMolesSO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getNumeroMolesSO2naChamine() < 0.01 ? 4 : 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemVolumeSO2naChamine(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesSO2ChamineMgPorNm3(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [true, false, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesSO2ChamineMgPorM3(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [false, false, true, true],
              },
            ],
            [
              {
                text: 'CO2',
                colSpan: 2,
                border: [true, true, false, true],
              },
              {},
              {
                text: `${formatNumber(eficiencia.getComposicaoCO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getComposicaoCO2naChamine() < 0.01 ? 4 : 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getComposicaoCO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getComposicaoCO2naChamine() < 0.01 ? 4 : 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemMassaCO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getPorcentagemMassaCO2naChamine() < 0.01 ? 4 : 2
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
              },
              {
                text: `${formatNumber(
                  eficiencia.getNumeroMolesCO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getNumeroMolesCO2naChamine() < 0.01 ? 4 : 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemVolumeCO2naChamine(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesCO2ChamineMgPorNm3(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [true, false, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesCO2ChamineMgPorM3(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [false, false, true, true],
              },
            ],
            [
              {
                text: 'O2',
                colSpan: 2,
                border: [true, true, false, true],
              },
              {},
              {
                text: ``,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getUnidadeGasO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getUnidadeGasO2naChamine() < 0.01 ? 4 : 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemMassaO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getPorcentagemMassaO2naChamine() < 0.01 ? 4 : 2
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
              },
              {
                text: `${formatNumber(eficiencia.getNumeroMolesO2naChamine(),
                  {
                    maximumFractionDigits: eficiencia.getNumeroMolesO2naChamine() < 0.01 ? 4 : 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getPorcentagemVolumeO2naChamine(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesO2ChamineMgPorNm3(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [true, false, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEmissoesO2ChamineMgPorM3(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                fillColor: '#92d050',
                border: [false, false, true, true],
              },
            ],
            [
              {
                text: 'TOTAL',
                colSpan: 2,
                border: [true, true, false, true],
              },
              {},
              {
                text: `${formatNumber(eficiencia.getTotalGasNaChamine())}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getTotalUnidadeGasNaChamine()
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getTotalPercentagemMassaNaChamine()
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getTotalNumeroMolesNaChamine()
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getTotalPorcentagemVolumeNaChamine()
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: ``,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: ``,
                alignment: 'center',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: 'PCS - Base seca (Btu/lbs)',
                colSpan: 5,
                fillColor: '#d8d8d8',
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `(Btu/lbs)`,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getPcsBaseSeca(), {
                  maximumFractionDigits: 0
                })}`,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: ``,
                fillColor: '#d8d8d8',
                border: [false, true, true, true],
                colSpan: 2,
                alignment: 'center',
              },
              {},
            ],
            [
              {
                text: 'PCS-Base úmida (Kcal/Kg) :',
                colSpan: 5,
                fillColor: '#d8d8d8',
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `(Kcal/Kg)`,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getPcsBaseUmida(), {
                  maximumFractionDigits: 0
                })}`,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: ``,
                fillColor: '#d8d8d8',
                border: [false, true, true, true],
                colSpan: 2,
                alignment: 'center',
              },
              {},
            ],
            [
              {
                text: 'PCI',
                colSpan: 5,
                rowSpan: 2,
                fillColor: '#d8d8d8',
                border: [true, true, false, true],
                bold: true,
                fontSize: 18,
                marginTop: 5,
              },
              {},
              {},
              {},
              {},
              {
                text: `Kcal/Kg`,
                fillColor: '#d8d8d8',
                border: [false, true, false, false],
              },
              {
                text: `${formatNumber(eficiencia.getPciKcalPorKg(), 
                  {
                    maximumFractionDigits: 0
                  })}`,
                fillColor: '#d8d8d8',
                border: [false, true, false, false],
                alignment: 'center',
              },
              {
                text: ``,
                fillColor: '#d8d8d8',
                border: [false, true, false, false],
                alignment: 'center',
              },
              {
                text: ``,
                fillColor: '#d8d8d8',
                border: [false, true, true, false],
                alignment: 'center',
              },
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {
                text: `(Btu/lbs)`,
                fillColor: '#d8d8d8',
                border: [false, false, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getPciBtuPorLbs(), 
                  {
                    maximumFractionDigits: 0
                  })}`,
                fillColor: '#d8d8d8',
                border: [false, false, false, true],
                alignment: 'center',
              },
              {
                text: ``,
                fillColor: '#d8d8d8',
                border: [false, false, true, true],
                colSpan: 2,
                alignment: 'center',
              },
              {},
            ],
            [
              {
                text: 'Temperatura da agua na caldeira',
                colSpan: 3,
                fillColor: '#d8d8d8',
                border: [true, true, false, true],
                bold: true,
              },
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getTemperaturaAguaCaldeira()
                )} °C`,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
                alignment: 'center',
                bold: true,
              },
              {
                text: ``,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
              },
              {
                text: `Saturado`,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
                alignment: 'center',
                bold: true,
              },
              {
                text: `320°C`,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
                alignment: 'center',
                bold: true,
              },
              {
                text: `420°C`,
                fillColor: '#d8d8d8',
                border: [false, true, false, true],
                alignment: 'center',
                bold: true,
              },
              {
                text: `450°C`,
                fillColor: '#d8d8d8',
                border: [false, true, true, true],
                alignment: 'center',
                bold: true,
              },
            ],
            [
              {
                text: 'Entalpia vapor',
                colSpan: 5,
                fillColor: '#d8d8d8',
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getEntalpiaVapor())} kcal/kg`,
                fillColor: '#d8d8d8',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEntalpiaVapor320()
                )} kcal/kg`,
                fillColor: '#d8d8d8',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEntalpiaVapor420()
                )} kcal/kg`,
                fillColor: '#d8d8d8',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getEntalpiaVapor450()
                )} kcal/kg`,
                fillColor: '#d8d8d8',
                alignment: 'center',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: 'Salto Entalpico',
                colSpan: 4,
                fillColor: '#d8d8d8',
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getSaltoEntalpico())} kcal/kg`,
                fillColor: '#92d050',
                alignment: 'center',
              },
              {
                text: '',
                fillColor: '#d8d8d8',
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'Ar teórico',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getArTeorico(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Gás umido na fornalha',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getGasUmidoNaFornalha(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Ar úmido total',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getArUmidoTotal(), {
                  maximumFractionDigits: 2,
                })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Gás úmido no feixe',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getGasUmidoNoFeixe(),{
                  maximumFractionDigits: 2
                })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Gás seco no feixe',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getGasSecoNoFeixe(), {
                  maximumFractionDigits: 2,
                })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Umidade produzida',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getUmidadeProduzida(), {
                  maximumFractionDigits: 2,
                })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Umidade no ar (feixe)',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getUmidadeNoAr(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Umidade no combustível',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getUmidadeNoCombustivel(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Massa molecular dos gase úmidos',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getMassaMolecularDosGasesUmidos(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kmol',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Massa molecular dos gases secos',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getMassaMolecularDosGasesSecos(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kmol',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Volume espec. gases úmidos (Nm3/Kg)',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getVolumeGasesUmidos(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Nm³/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Pressão parcial -  CO2',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getPressaoParcialCO2(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Pressão parcial -  H2O',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getPressaoParcialH2O(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Soma pressões parciais',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getSomaPressoesParciais(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Umidade nos gases ( % )',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getUmidadeNosGases(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Gás seco no feixe',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: 'Unitário',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getGasSecoNoFeixeUnitario(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Umidade produzida',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: 'Unitário',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getUmidadeProduzidaUnitario(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Umidade no combustível',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: 'Unitário',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(
                  eficiencia.getUmidadeNoCombustivelUnitario(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Umidade no ar ( feixe )',
                colSpan: 4,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {
                text: 'Unitário',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: `${formatNumber(eficiencia.getUmidadeNoArUnitario(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Kg',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Densidade dos gases',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getDensidadeDosGases(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Nm³',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Densidade dos gases na temperatura',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getDensidadeDosGasesNaTemperatura(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/m³',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Calor total transferido (Output)',
                colSpan: 5,
                rowSpan: 2,
                border: [true, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
                fontSize: 14,
                margin: [0, 8, 0, 0],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getCalorTotalTransferidoKjPorKg(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, false],
                fillColor: '#d8d8d8',
              },
              {
                text: 'KJ/Kg',
                alignment: 'center',
                border: [false, true, false, false],
                fillColor: '#d8d8d8',
              },
              {
                text: '',
                border: [false, true, true, false],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getCalorTotalTransferidoKcalPorKg(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: '#d8d8d8',
              },
              {
                text: 'Kcal/Kg',
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: '#d8d8d8',
              },
              {
                text: '',
                border: [false, false, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Calor total liberado na fornalha (input ao PCS)',
                colSpan: 5,
                rowSpan: 2,
                border: [true, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
                fontSize: 14,
                margin: [0, 8, 0, 0],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getCalorTotalLiberadoNaFornalhaKjPorHr(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, false],
                fillColor: '#d8d8d8',
              },
              {
                text: 'KJ/h',
                alignment: 'center',
                border: [false, true, false, false],
                fillColor: '#d8d8d8',
              },
              {
                text: '',
                border: [false, true, true, false],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getCalorTotalLiberadoNaFornalhaKcalPorHr(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: '#d8d8d8',
              },
              {
                text: 'Kcal/h',
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: '#d8d8d8',
              },
              {
                text: '',
                border: [false, false, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Consumo de combustível',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getConsumoDeCombustivelKgPorHr(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [true, true, false, true],
                fillColor: '#92d050',
              },
              {
                text: 'Kg/h',
                alignment: 'center',
                border: [false, true, true, true],
                fillColor: '#92d050',
              },
              {
                text: ``,
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, true, true, true],
                alignment: 'center',
              },
            ],
            [
              {
                text: 'Consumo de combustível',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getConsumoDeCombustivelKgPorDia(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Dia',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: ``,
                border: [false, true, false, true],
                alignment: 'center',
              },
              {
                text: '',
                border: [false, true, true, true],
                alignment: 'center',
              },
            ],
            [
              {
                text: 'Consumo de combustível',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getConsumoDeCombustivelKgPorMes(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/Mês',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Input burned',
                colSpan: 5,
                rowSpan: 2,
                border: [true, true, false, true],
                margin: [0, 8, 0, 0],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getInputBurnedKjPorHr(),
                  {
                    maximumFractionDigits: 0
                  })}`,
                alignment: 'center',
                border: [false, true, false, false],
              },
              {
                text: 'KJ/hr',
                alignment: 'center',
                border: [false, true, false, false],
              },
              {
                text: '',
                border: [false, true, true, false],
                colSpan: 2,
              },
              {},
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getInputBurnedKcalPorHr(),
                  {
                    maximumFractionDigits: 0
                  })}`,
                alignment: 'center',
                border: [false, false, false, true],
              },
              {
                text: 'Kcal/hr',
                alignment: 'center',
                border: [false, false, false, true],
              },
              {
                text: '',
                border: [false, false, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Combustível queimado "as burned"',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getCombustivelQueimado(),
                  {
                    maximumFractionDigits: 0
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/hr',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Vazão de Gases',
                colSpan: 4,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#a5a5a5',
              },
              {},
              {},
              {},
              {
                text: 'APÓS A COMBUSTÃO',
                bold: true,
                border: [false, true, false, true],
                fillColor: '#a5a5a5',
              },
              {
                text: `${formatNumber(eficiencia.getVazaoDeGases(),
                  {
                    maximumFractionDigits: 0
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#a5a5a5',
                bold: true,
              },
              {
                text: 'Kg/hr',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#a5a5a5',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#a5a5a5',
              },
              {},
            ],
            [
              {
                text: 'Vazão dos gases Volumetrica após o pré ar.',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#a5a5a5',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getVazaoDosGasesVolumetricaAposPreArM3PorHr(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#a5a5a5',
                bold: true,
              },
              {
                text: 'm³/hr',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#a5a5a5',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#a5a5a5',
              },
              {},
            ],
            [
              {
                text: 'Vazão dos gases Volumetrica após o pré ar.',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#a5a5a5',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getVazaoDosGasesVolumetricaAposPreArNm3PorHr(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#a5a5a5',
                bold: true,
              },
              {
                text: 'Nm³/hr',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#a5a5a5',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#a5a5a5',
              },
              {},
            ],
            [
              {
                text: 'Suprimento de ar para caldeira',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getSuprimentoArCaldeira(),
                  {
                    maximumFractionDigits: 0
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: 'Kg/hr',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Suprimento volumetrico de ar para caldeira',
                colSpan: 5,
                rowSpan: 2,
                border: [true, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
                margin: [0, 8, 0, 0],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getSuprimentoVolumetricoArCaldeiraNm3PorHr(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, false],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: 'Nm³/h',
                alignment: 'center',
                border: [false, true, false, false],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, false],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getSuprimentoVolumetricoArCaldeiraM3PorHr(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: 'm³/h',
                alignment: 'center',
                border: [false, false, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, false, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Vazão do ventilador primario',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getVazaoVentiladorPrimario(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: 'm³/h',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Vazão do ventilador secundario (overfire)',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getVazaoVentiladorSecundario(),
                  {
                    maximumFractionDigits: 0
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: 'm³/h',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Gás úmido do feixe',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getGasUmidoFeixe(),
                  {
                    maximumFractionDigits: 0
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: 'Kg/h',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Perdas por gases secos',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getPerdasPorGasesSecos(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Perdas por umidade produzida',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getPerdasPorUmidadeProduzida(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Perdas por umidade de combustível',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getPerdasPorUmidadeDeCombustivel(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Perdas por umidade no ar',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getPerdasPorUmidadeNoAr(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Total de perdas',
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getPerdasTotal(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: 'Eficiência ao PCS - as fired',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getEficienciaAoPcs(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Eficiência ao PCS - as burned (Excluido perdas não queimados)',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getEficienciaAoPcsSemPerdas(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Eficiência ao PCI - as fired',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getEficienciaAoPci(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Massa de agua condensada',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getMassaAguaCondensada(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Calor recuperado',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(eficiencia.getCalorRecuperado(),
                  {
                    maximumFractionDigits: 2
                  })}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
            [
              {
                text: 'Eficiencia com condensação ao PCI as fired',
                colSpan: 5,
                border: [true, true, false, true],
                bold: true,
                fillColor: '#d8d8d8',
              },
              {},
              {},
              {},
              {},
              {
                text: `${formatNumber(
                  eficiencia.getEficienciaComCondensacaoAoPci(),
                  {
                    maximumFractionDigits: 2
                  }
                )}`,
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '%',
                alignment: 'center',
                border: [false, true, false, true],
                fillColor: '#d8d8d8',
                bold: true,
              },
              {
                text: '',
                border: [false, true, true, true],
                colSpan: 2,
                fillColor: '#d8d8d8',
              },
              {},
            ],
          ],
        },
      },
    ];

    const rodape = [];

    const docDefinitions = {
      pageSize: 'A3',
      pageMargins: [15, 168, 15, 40],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            style: 'tableExample',
            margin: [15, 15, 15, 0],
            table: {
              widths: ['*', '*', '*', '*', 120, '*', '*', '*', '*'],
              body: [
                [
                  {
                    image: 'logo',
                    width: 140,
                    height: 60,
                    alignment: 'center',
                    colSpan: 3,
                    rowSpan: 3,
                    margin: [0, 10, 0, 10],
                  },
                  {},
                  {},
                  {
                    text: 'Sistema de Gestão da Qualidade',
                    colSpan: 4,
                    alignment: 'center',
                    margin: [0, 5, 0, 5],
                  },
                  {},
                  {},
                  {},
                  { text: 'N°: COMB-1', colSpan: 2 },
                  {},
                ],
                [
                  {},
                  {},
                  {},
                  {
                    text: 'Folha de Dados',
                    colSpan: 4,
                    alignment: 'center',
                    margin: [0, 5, 0, 5],
                  },
                  {},
                  {},
                  {},
                  { text: 'Rev: 0', colSpan: 2 },
                  {},
                ],
                [
                  {},
                  {},
                  {},
                  {
                    text: 'Cálculo da combustão',
                    colSpan: 4,
                    alignment: 'center',
                    margin: [0, 5, 0, 5],
                  },
                  {},
                  {},
                  {},
                  {
                    text: `N° págs: ${currentPage}/${pageCount}`,
                    colSpan: 2,
                  },
                  {},
                ],
                [{ text: '', colSpan: 9 }],
                [
                  {
                    text: 'Projeto / Cliente:',
                    colSpan: 2,
                    alignment: 'center',
                    margin: [0, 5, 0, 5],
                    border: [true, false, false, false],
                  },
                  {},
                  {
                    border: [false, false, false, false],
                    text: 'Preliminar',
                    colSpan: 5,
                    margin: [0, 5, 0, 5],
                  },
                  {},
                  {},
                  {},
                  {},
                  { text: 'OV: padrão', colSpan: 2, margin: [0, 5, 0, 5] },
                  {},
                ],
                [
                  {
                    text: 'Elaborado por:',
                    colSpan: 2,
                    margin: [0, 5, 0, 5],
                    border: [true, true, false, true],
                  },
                  {},
                  {
                    text: '',
                    colSpan: 2,
                    margin: [0, 5, 0, 5],
                    border: [false, true, false, true],
                  },
                  {},
                  {
                    text: 'Verificado por:',
                    margin: [0, 5, 0, 5],
                    border: [false, true, false, true],
                  },
                  {
                    text: '',
                    colSpan: 2,
                    margin: [0, 5, 0, 5],
                    border: [false, true, true, true],
                  },
                  {},
                  {
                    text: `Data: ${new Date().toLocaleDateString('pt-BR')}`,
                    colSpan: 2,
                    margin: [0, 5, 0, 5],
                  },
                  {},
                ],
              ],
            },
          },
        ];
      },
      content: [details],
      footer: [rodape],
      images: {
        logo: {
          url: await getBase64ImageFromURL(logo),
        },
      },
    };
    if (baixar) {
      pdfMake.createPdf(docDefinitions).download('relatorio.pdf');
    } else {
      const pdf = pdfMake.createPdf(docDefinitions);

      pdf.getBuffer(function (buffer) {
        const blob = new Blob([buffer], { type: 'application/pdf' });
        share(blob);
      });
    }
  };

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  const share = async (shareFile) => {
    try {
      const file = new File([shareFile], `Relatório_Eficiência.pdf`, {
        type: shareFile.type,
      });

      await navigator.share({
        title: `Relatório_Eficiência.pdf`,
        files: [file],
      });
    } catch (err) {
      console.log(err.name, err.message);
    }
  };

  const pressaoMaximaTrabalho = parseInt(dados[0].pressao);
  const vazaoVapor = parseInt(dados[0].vazaoVapor.replace('.', ''));
  const vaporSaturadoSuperaquecido = dados[0].tipoVapor;
  const temperaturaVaporSuperaquecido = parseInt(dados[0].temperatura);

  const condensacao = 0;

  const combustivel = dados[1].combustivel;
  const umidadeCombustivel = parseInt(dados[1].umidade);

  var estadoCombustivel = '';
  if (combustivel === 'outro') {
    estadoCombustivel =
      dados[1].tipo_combustivel === 'solido_ou_liquido' ? 1 : 2;
  } else {
    estadoCombustivel =
      combustiveis.filter((comb) => comb.nome === combustivel)[0].tipo ===
      'solido_ou_liquido'
        ? 1
        : 2;
  }
  const carbono = parseFloat(dados[1].carbono_seca);
  const hidrogenio = parseFloat(dados[1].hidrogenio_seca);
  const oxigenio = parseFloat(dados[1].oxigenio_seca);
  const enxofre = parseFloat(dados[1].enxofre_seca);
  const nitrogenio = parseFloat(dados[1].nitrogenio_seca);
  const cinzas = parseFloat(dados[1].cinzas_seca);
  const pcs = parseInt(dados[1].pcs.replace('.', ''));

  const excessoDeAr = parseInt(dados[2].excessoAr);
  const altituteLocal = parseInt(dados[2].altitude);

  const temperaturaCombustivel = parseInt(dados[3].temperaturaCombustivel);
  const temperaturaEntradaAgua = parseInt(dados[3].temperaturaEntradaAgua);
  const temperaturaEntradaAr = parseInt(dados[3].temperaturaEntradaAr);
  const temperaturaSaidaGases = parseInt(dados[3].temperaturaSaidaGases);

  const overfire = parseInt(dados[3].porcentagemOverfire);

  const horasTrabalhadasDia = 24;
  const diasTrabalhadosMes = 30;

  const perdasRadiacao = parseFloat(dados[4].perdasRadiacao.replace(',', '.'));
  const perdasCombustivelNaoQueimado = parseFloat(
    dados[4].perdasNaoQueimado.replace(',', '.')
  );
  const margemFabricante = parseFloat(
    dados[4].margemFabricante.replace(',', '.')
  );

  const somaBaseSeca =
    carbono + hidrogenio + oxigenio + enxofre + nitrogenio + cinzas;
  const somaBaseUmida =
    eficiencia.getBaseUmida().carbono +
    eficiencia.getBaseUmida().hidrogenio +
    eficiencia.getBaseUmida().oxigenio +
    eficiencia.getBaseUmida().enxofre +
    eficiencia.getBaseUmida().nitrogenio +
    eficiencia.getBaseUmida().cinzas +
    umidadeCombustivel;

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          right: '1rem',
          top: '-2rem',
        }}
      >
        <Fab aria-label="add" onClick={() => baixarPDF(false)}>
          <ShareIcon fontSize="large" />
        </Fab>
        <Fab sx={{ ml: 1 }} aria-label="add" onClick={() => baixarPDF(true)}>
          <ArrowDownwardIcon fontSize="large" />
        </Fab>
      </Box>
      {/* <Button
        onClick={() => baixarPDF(true)}
        variant="contained"
        color="success"
      >
        Baixar PDF
      </Button> */}
    </>
  );
}
