import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PopupPedido({ open, handleClose }) {
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" align="center">
        A <span style={{ color: '#ff3131' }}>Steammaster</span> tem um oferta
        para você!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" align="center">
          Clique no botão abaixo para entrar em contato com um especialista
          sobre o produto ideal com base nos seus cálculos.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus fullWidth color="error">
          Cancelar
        </Button>
        <Button
          onClick={() => navigate('/contato')}
          autoFocus
          fullWidth
          variant="contained"
          color="success"
        >
          Criar Pedido
        </Button>
      </DialogActions>
    </Dialog>
  );
}
