export const combustiveis = [
  {
    id: 1,
    nome: 'GN',
    carbono: 73.62,
    hidrogenio: 23.49,
    oxigenio: 0.87,
    enxofre: 0.0,
    nitrogenio: 2.02,
    cinzas: 0.0,
    tipo: 'gases',
  },
  {
    id: 2,
    nome: 'Óleo combustível 1A',
    carbono: 87.0,
    hidrogenio: 10.5,
    oxigenio: 0.0,
    enxofre: 2.5,
    nitrogenio: 0.0,
    cinzas: 0.0,
    tipo: 'solido_ou_liquido',
  },
  {
    id: 3,
    nome: 'Óleo Diesel',
    carbono: 86.0,
    hidrogenio: 13.1,
    oxigenio: 0.0,
    enxofre: 0.9,
    nitrogenio: 0.0,
    cinzas: 0.0,
    tipo: 'solido_ou_liquido',
  },
  {
    id: 4,
    nome: 'Cavaco de Eucalipto',
    carbono: 48.3,
    hidrogenio: 5.8,
    oxigenio: 45.2,
    enxofre: 0.1,
    nitrogenio: 0.6,
    cinzas: 0.0,
    tipo: 'solido_ou_liquido',
  },
  {
    id: 5,
    nome: 'Casca de Eucalipto',
    carbono: 44.1,
    hidrogenio: 5.0,
    oxigenio: 47.5,
    enxofre: 0.1,
    nitrogenio: 0.8,
    cinzas: 2.5,
    tipo: 'solido_ou_liquido',
  },
  {
    id: 6,
    nome: 'Bagaço de Cana',
    carbono: 47.8,
    hidrogenio: 6.0,
    oxigenio: 44.2,
    enxofre: 0.1,
    nitrogenio: 0.8,
    cinzas: 1.1,
    tipo: 'solido_ou_liquido',
  },
  {
    id: 7,
    nome: 'Casca de Arroz',
    carbono: 32.37,
    hidrogenio: 4.29,
    oxigenio: 43.28,
    enxofre: 0.0,
    nitrogenio: 0.0,
    cinzas: 20.06,
    tipo: 'solido_ou_liquido',
  },
  {
    id: 8,
    nome: 'GLP',
    carbono: 82.19,
    hidrogenio: 17.81,
    oxigenio: 0.0,
    enxofre: 0.0,
    nitrogenio: 0.0,
    cinzas: 0.0,
    tipo: 'gases',
  },
  {
    id: 9,
    nome: 'Lenha',
    carbono: 48.3,
    hidrogenio: 5.8,
    oxigenio: 45.2,
    enxofre: 0.1,
    nitrogenio: 0.6,
    cinzas: 0.0,
    tipo: 'solido_ou_liquido',
  },
];
