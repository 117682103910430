import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { datasheets } from 'constantes/datasheets';

export default function ListaDatasheets() {
  const listaDatasheets = [
    'jpo.pdf',
    'caldeira543.pdf',
    'oleo.pdf',
    'cascata.pdf',
    'caldeiraALenha.pdf',
    'caldeira2.pdf',
  ];

  const [search, setSearch] = useState('');
  const [lista, setLista] = useState(datasheets);

  useEffect(() => {
    searchInList();
  }, [search]);

  function searchInList() {
    const newList = datasheets.filter((item) =>
      item.nome.toLowerCase().includes(search.toLowerCase())
    );
    setLista(newList);
  }

  return (
    <Paper style={{ flex: 1, padding: '2rem' }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Datasheets
        </Typography>
        <TextField
          label="Buscar Datasheet"
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Stack spacing={2} sx={{ width: '100%' }} mt={3}>
          {lista.map((item) => (
            <DatasheetBox
              nome={item.nome}
              key={item.id}
              pdf={item.pdf}
              url={item.url}
            />
          ))}
        </Stack>
      </Box>
    </Paper>
  );
}

function DatasheetBox({ nome, pdf, url }) {
  return (
    <Button
      sx={{
        display: 'block',
        padding: 0,
        textTransform: 'none',
      }}
      onClick={() => window.open(url, '_blank')}
    >
      <Card sx={{ display: 'flex' }} elevation={5}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          <PictureAsPdfIcon
            sx={{
              height: 30,
              width: 30,
            }}
            color="primary"
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5" align="left">
              {nome}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{
                textAlign: 'left',
              }}
            >
              {pdf}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Button>
  );
}
