import React, { createContext, useContext, useState } from 'react';

export const OpenSideBarContext = createContext();

export default function OpenSideBarProvider({ children }) {
  const [state, setState] = useState(false);

  return (
    <OpenSideBarContext.Provider value={{ state, setState }}>
      {children}
    </OpenSideBarContext.Provider>
  );
}

export function useOpenSideBar() {
  const context = useContext(OpenSideBarContext);
  const { state, setState } = context;
  return { state, setState };
}
