import { useState, useEffect } from 'react';

import styles from '../../../styles/Resultados.module.scss';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';

import ItemPrincipal from '../../../components/ItemPrincipal';
import ItemResultado from '../../../components/ItemResultado';
import Header from '../../../components/Header/Header';
import { Container } from '../Eficiencia/styles';
import Eficiencia from '../../../../Classes/eficiencia';

import PdfButton from './Pdf';
import { formatNumber } from 'utils/formatNumber';
import PopupPedido from 'view/components/PopupPedido';

import { combustiveis } from 'constantes/combustivel';

export default function VentiladorResultados() {
  const navigate = useNavigate();

  const [carregando, setCarregando] = useState(true);
  const [eficiencia, setEficiencia] = useState(null);
  const [dados, setDados] = useState(null);

  const [resultados, setResultados] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const dados = JSON.parse(localStorage.getItem('eficiencia'));

    if (dados) {
      setResultados({
        ...dados[0],
        ...dados[1],
        ...dados[2],
        ...dados[3],
        ...dados[4],
      });

      const pressaoMaximaTrabalho = parseInt(dados[0].pressao);
      const vazaoVapor = parseInt(dados[0].vazaoVapor.replace('.', ''));
      const vaporSaturadoSuperaquecido = dados[0].tipoVapor;
      const temperaturaVaporSuperaquecido = parseInt(dados[0].temperatura);

      const condensacao = 0;

      const combustivel = dados[1].combustivel;
      const umidadeCombustivel = parseInt(dados[1].umidade);

      var estadoCombustivel = '';
      if (combustivel === 'outro') {
        estadoCombustivel =
          dados[1].tipo_combustivel === 'solido_ou_liquido' ? 1 : 2;
      } else {
        estadoCombustivel =
          combustiveis.filter((comb) => comb.nome === combustivel)[0].tipo ===
          'solido_ou_liquido'
            ? 1
            : 2;
      }

      const carbono = parseFloat(dados[1].carbono_seca);
      const hidrogenio = parseFloat(dados[1].hidrogenio_seca);
      const oxigenio = parseFloat(dados[1].oxigenio_seca);
      const enxofre = parseFloat(dados[1].enxofre_seca);
      const nitrogenio = parseFloat(dados[1].nitrogenio_seca);
      const cinzas = parseFloat(dados[1].cinzas_seca);
      const pcs = parseInt(dados[1].pcs.replace('.', ''));

      const excessoDeAr = parseInt(dados[2].excessoAr);
      const altituteLocal = parseInt(dados[2].altitude);

      const temperaturaCombustivel = parseInt(dados[3].temperaturaCombustivel);
      const temperaturaEntradaAgua = parseInt(dados[3].temperaturaEntradaAgua);
      const temperaturaEntradaAr = parseInt(dados[3].temperaturaEntradaAr);
      const temperaturaSaidaGases = parseInt(dados[3].temperaturaSaidaGases);

      const overfire = parseInt(dados[3].porcentagemOverfire);

      const horasTrabalhadasDia = 24;
      const diasTrabalhadosMes = 30;

      const perdasRadiacao = parseFloat(
        dados[4].perdasRadiacao.replace(',', '.')
      );
      const perdasCombustivelNaoQueimado = parseFloat(
        dados[4].perdasNaoQueimado.replace(',', '.')
      );
      const margemFabricante = parseFloat(
        dados[4].margemFabricante.replace(',', '.')
      );

      const efi = new Eficiencia(
        pressaoMaximaTrabalho,
        vazaoVapor,
        vaporSaturadoSuperaquecido,
        temperaturaVaporSuperaquecido,
        condensacao,
        combustivel,
        umidadeCombustivel,
        estadoCombustivel,
        carbono,
        hidrogenio,
        oxigenio,
        enxofre,
        nitrogenio,
        cinzas,
        pcs,
        excessoDeAr,
        altituteLocal,
        temperaturaCombustivel,
        temperaturaEntradaAgua,
        temperaturaEntradaAr,
        temperaturaSaidaGases,
        overfire,
        horasTrabalhadasDia,
        diasTrabalhadosMes,
        perdasRadiacao,
        perdasCombustivelNaoQueimado,
        margemFabricante
      );

      setEficiencia(efi);
      setDados(dados);

      setCarregando(false);
    }

    setTimeout(() => {
      setOpenPopup(true);
    }, 4000);
  }, []);

  const handleClick = () => {
    navigate('/eficiencia');
  };

  return (
    <>
      <PopupPedido open={openPopup} handleClose={() => setOpenPopup(false)} />

      <Container>
        <Header title="Resultado Eficiência" />

        <div className={styles.container}>
          {!carregando && (
            <>
              <img src="/assets/smoke.jpg" alt="smoke" />

              <ItemPrincipal
                resultados={[]}
                nome="Efciência ao PCI - as fired"
                valor={formatNumber(eficiencia.getEficienciaAoPci(),
                  {
                    maximumFractionDigits: 2
                  })}
                medida="%"
                //shareTitle="Resultados de eficiencia"
              />

              <div
                className={styles.infos}
                style={{ display: 'flex', flex: 1 }}
              >
                <ItemResultado
                  nome="Vazão de Gases"
                  valor={formatNumber(
                    parseFloat(eficiencia.getVazaoDeGases()).toFixed(0)
                  )}
                  medida="Kg/h"
                />
                <ItemResultado
                  nome="Suprimento de ar para caldeira"
                  valor={formatNumber(
                    parseFloat(eficiencia.getSuprimentoArCaldeira()).toFixed(0)
                  )}
                  medida="Kg/h"
                />
                <ItemResultado
                  nome="Consumo de combustível"
                  valor={formatNumber(
                    parseFloat(
                      eficiencia.getConsumoDeCombustivelKgPorHr()
                    ).toFixed(0)
                  )}
                  medida="Kg/h"
                />
                <ItemResultado
                  nome="Vazão de vapor"
                  valor={formatNumber(resultados.vazaoVapor.replace('.', ''))}
                  s
                  medida="Kg/h"
                />
                <ItemResultado
                  nome="Pressão Máxima de Trabalho Admissível"
                  valor={formatNumber(resultados.pressao)}
                  medida="Kgf/cm²"
                />
                <ItemResultado
                  nome="Vapor Saturado  / Vapor Superaquecido"
                  valor={
                    resultados.tipoVapor === 'superAquecido'
                      ? 'Superaquecido'
                      : 'Saturado'
                  }
                  medida=""
                />
                <ItemResultado
                  nome="Temperatura dos gases na sáida da chaminé"
                  valor={dados[3].temperaturaSaidaGases}
                  medida="°C"
                />

                {eficiencia && (
                  <PdfButton eficiencia={eficiencia} dados={dados} />
                )}

                <Button onClick={handleClick} variant="contained">
                  Recalcular
                </Button>
              </div>
            </>
          )}
        </div>
      </Container>
    </>
  );
}
