import { formatNumber } from 'utils/formatNumber';

export default class Ventilador {
  constructor(
    temperaturaAr,
    contraPressao,
    quantidadeGas,
    fatorSegurancaPressao,
    fatorSegurancaGas,
    eficiencia
  ) {
    this.temperaturaAr = temperaturaAr;
    this.contraPressao = contraPressao;
    this.quantidadeGas = quantidadeGas;
    this.fatorSegurancaPressao = fatorSegurancaPressao;
    this.fatorSegurancaGas = fatorSegurancaGas;
    this.eficiencia = eficiencia;
  }

  getContraPressao() {
    return Math.round(this.contraPressao * this.fatorSegurancaPressao).toFixed(
      0
    );
  }

  getQuantidadeGas() {
    const gas =
      (this.quantidadeGas * ((this.temperaturaAr + 273) / 273)) /
      (this.contraPressao / 10000 + 1);
    return Math.round(gas).toFixed(0);
  }

  getQuantidadeGasAdotada() {
    return Math.round(this.getQuantidadeGas() * this.fatorSegurancaGas).toFixed(
      0
    );
  }

  getMotor() {
    const motor =
      (this.getQuantidadeGasAdotada() * this.getContraPressao()) /
      (270000 * this.eficiencia);
    return motor.toFixed(2);
  }

  getResultados() {
    const resultados = [
      {
        nome: 'Potência Mínima Necessária',
        valor: formatNumber(this.getMotor()),
        medida: 'Cv',
      },
      {
        nome: 'Temperatura do Ar/Gáses',
        valor: formatNumber(this.temperaturaAr),
        medida: '°C',
      },
      {
        nome: 'Contra Pressão',
        valor: formatNumber(this.contraPressao),
        medida: 'mmca',
      },
      {
        nome: 'Contra pressão adotada',
        valor: formatNumber(this.getContraPressao()),
        medida: 'mmca',
      },
      {
        nome: 'Quantidade de Gás',
        valor: formatNumber(this.quantidadeGas),
        medida: 'Nm³/h',
      },
      {
        nome: 'Quantidade de gás corrigido na Temperatura',
        valor: formatNumber(this.getQuantidadeGas()),
        medida: 'm³/h',
      },
      {
        nome: 'Quantidade de gás adotada',
        valor: formatNumber(this.getQuantidadeGasAdotada()),
        medida: 'm³/h',
      },
      {
        nome: 'Fator de Segurança para Contra Pressão',
        valor: formatNumber(this.fatorSegurancaPressao),
        medida: '',
      },
      {
        nome: 'Fator de Segurança para Quantidade de Gás',
        valor: formatNumber(this.fatorSegurancaGas),
        medida: '',
      },
      {
        nome: 'Eficiência do Ventilador',
        valor: formatNumber(Math.round(this.eficiencia * 100)),
        medida: '%',
      },
    ];

    return resultados;
  }
}
