import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'view/components/Header/Header';
import { Container } from 'view/components/Input/styles';

export default function Datasheets() {
  return (
    <Container>
      <Header title="Datasheets" />
      <Outlet />
    </Container>
  );
}
