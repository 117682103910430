import { formatNumber } from 'utils/formatNumber';
import { combustiveis } from '../constantes/combustivel';
import { entalpiaVapor as entalpia } from '../constantes/entalpia';

export default class Eficiencia {
  constructor(
    pressaoMaximaTrabalho,
    vazaoVapor,
    vaporSaturadoSuperaquecido,
    temperaturaVaporSuperaquecido,
    condensacao,
    combustivel,
    umidadeCombustivel,
    estadoCombustivel,
    carbono,
    hidrogenio,
    oxigenio,
    enxofre,
    nitrogenio,
    cinzas,
    pcs,
    excessoDeAr,
    altituteLocal,
    temperaturaCombustivel,
    temperaturaEntradaAgua,
    temperaturaEntradaAr,
    temperaturaSaidaGases,
    overfire,
    horasTrabalhadasDia,
    diasTrabalhadosMes,
    perdasRadiacao,
    perdasCombustivelNaoQueimado,
    margemFabricante
  ) {
    this.pressaoMaximaTrabalho = pressaoMaximaTrabalho;
    this.vazaoVapor = vazaoVapor;
    this.vaporSaturadoSuperaquecido = vaporSaturadoSuperaquecido;
    this.temperaturaVaporSuperaquecido = temperaturaVaporSuperaquecido;
    this.condensacao = condensacao;
    this.combustivel = combustivel;
    this.umidadeCombustivel = umidadeCombustivel;
    this.estadoCombustivel = estadoCombustivel;
    this.carbono = carbono;
    this.hidrogenio = hidrogenio;
    this.oxigenio = oxigenio;
    this.enxofre = enxofre;
    this.nitrogenio = nitrogenio;
    this.cinzas = cinzas;
    this.pcs = pcs;
    this.excessoDeAr = excessoDeAr;
    this.altituteLocal = altituteLocal;
    this.temperaturaCombustivel = temperaturaCombustivel;
    this.temperaturaEntradaAgua = temperaturaEntradaAgua;
    this.temperaturaEntradaAr = temperaturaEntradaAr;
    this.temperaturaSaidaGases = temperaturaSaidaGases;
    this.overfire = overfire;
    this.horasTrabalhadasDia = horasTrabalhadasDia;
    this.diasTrabalhadosMes = diasTrabalhadosMes;
    this.perdasRadiacao = perdasRadiacao;
    this.perdasCombustivelNaoQueimado = perdasCombustivelNaoQueimado;
    this.margemFabricante = margemFabricante;

    this.F13 = pressaoMaximaTrabalho;
    this.F15 = vazaoVapor;
    this.F16 = vaporSaturadoSuperaquecido;
    this.F17 = temperaturaVaporSuperaquecido;
    this.F18 = condensacao;
    this.F19 = combustivel;
    this.F20 = umidadeCombustivel;
    this.F24 = estadoCombustivel;
    this.F25 = carbono;
    this.F26 = hidrogenio;
    this.F27 = oxigenio;
    this.F28 = enxofre;
    this.F29 = nitrogenio;
    this.F30 = cinzas;
    this.F35 = pcs;
    this.F36 = excessoDeAr;
    this.F37 = altituteLocal;
    this.F39 = temperaturaCombustivel;
    this.F40 = temperaturaEntradaAgua;
    this.F41 = temperaturaEntradaAr;
    this.F42 = temperaturaSaidaGases;
    this.F43 = overfire;
    this.F44 = horasTrabalhadasDia;
    this.F45 = diasTrabalhadosMes;
    this.F46 = perdasRadiacao;
    this.F47 = perdasCombustivelNaoQueimado;
    this.F48 = margemFabricante;
  }

  getEstadoCombustivel() {
    return this.estadoCombustivel;
  }

  getPressaoOperacao() {
    const resultado = this.pressaoMaximaTrabalho - 2;
    return resultado.toFixed(4);
  }

  getPressaoAtmosfericaLocal() {
    //  760 * ((1 - (((6,5 / 288) * this.altituteLocal) / 1000))^5,25500)
    const primeiro = 6.5 / 288;
    const segundo = (primeiro * this.altituteLocal) / 1000;
    const terceiro = 1 - segundo;
    const quarto = terceiro ** 5.255;
    const resultado = 760 * quarto;

    return resultado.toFixed(4);
  }

  getTemperaturaAguaCaldeira() {
    const pressao = `${this.pressaoMaximaTrabalho + 1}`;

    const resultado = entalpia.filter(
      (item) => item.pressao_vapor === pressao
    )[0].temperatura_vapor_saturado;

    return resultado;
  }

  getEntalpiaVapor() {
    const pressao = `${this.pressaoMaximaTrabalho + 1}`;

    const resultado = entalpia.filter(
      (item) => item.pressao_vapor === pressao
    )[0].entalpia_vapor_saturado;

    return resultado;
  }

  getEntalpiaVapor320() {
    const pressao = `${this.pressaoMaximaTrabalho + 1}`;

    const resultado = entalpia.filter(
      (item) => item.pressao_vapor === pressao
    )[0].entalpia_vapor_saturado_320;

    return resultado;
  }

  getEntalpiaVapor420() {
    const pressao = `${this.pressaoMaximaTrabalho + 1}`;

    const resultado = entalpia.filter(
      (item) => item.pressao_vapor === pressao
    )[0].entalpia_vapor_saturado_420;

    return resultado;
  }

  getEntalpiaVapor450() {
    const pressao = `${this.pressaoMaximaTrabalho + 1}`;

    const resultado = entalpia.filter(
      (item) => item.pressao_vapor === pressao
    )[0].entalpia_vapor_saturado_450;

    return resultado;
  }

  getSaltoEntalpico() {
    if (this.vaporSaturadoSuperaquecido === 'superAquecido') {
      if (this.temperaturaVaporSuperaquecido === 320) {
        return this.getEntalpiaVapor320() - this.temperaturaEntradaAgua;
      }
      if (this.temperaturaVaporSuperaquecido === 420) {
        return this.getEntalpiaVapor420() - this.temperaturaEntradaAgua;
      }
      if (this.temperaturaVaporSuperaquecido === 450) {
        return this.getEntalpiaVapor450() - this.temperaturaEntradaAgua;
      }
    } else {
      return this.getEntalpiaVapor() - this.temperaturaEntradaAgua;
    }
  }

  getBaseUmida() {
    const umidade = this.umidadeCombustivel / 100;
    return {
      carbono: this.carbono * (1 - umidade),
      hidrogenio: this.hidrogenio * (1 - umidade),
      oxigenio: this.oxigenio * (1 - umidade),
      enxofre: this.enxofre * (1 - umidade),
      nitrogenio: this.nitrogenio * (1 - umidade),
      cinzas: this.cinzas * (1 - umidade),
    };
  }

  //Começa aqui
  getComposicaoN2naChamine() {
    var composicao =
      (8.819 * this.getBaseUmida().carbono +
        26.271 * this.getBaseUmida().hidrogenio -
        3.31 * this.getBaseUmida().oxigenio +
        3.304 * this.getBaseUmida().enxofre +
        this.getBaseUmida().nitrogenio) /
      100;

    return composicao.toFixed(4);
  }

  getComposicaoH2OnaChamine() {
    var composicao =
      (8.936 * this.getBaseUmida().hidrogenio + this.umidadeCombustivel) / 100;

    return composicao.toFixed(4);
  }

  getComposicaoSO2naChamine() {
    var composicao = (1.998 * this.getBaseUmida().enxofre) / 100;

    return composicao.toFixed(4);
  }

  getComposicaoCO2naChamine() {
    var composicao = (3.664 * this.getBaseUmida().carbono) / 100;

    return composicao.toFixed(4);
  }

  getTotalGasNaChamine() {
    var totalComposicao =
      parseFloat(this.getComposicaoN2naChamine()) +
      parseFloat(this.getComposicaoH2OnaChamine()) +
      parseFloat(this.getComposicaoSO2naChamine()) +
      parseFloat(this.getComposicaoCO2naChamine());

    return totalComposicao.toFixed(4);
  }

  getUnidadeGasN2naChamine() {
    var unidadeGas =
      parseFloat(this.getComposicaoN2naChamine()) +
      ((0.768 * this.excessoDeAr) / 100) * this.getArTeorico();

    return unidadeGas.toFixed(4);
  }

  getUnidadeGasH2OnaChamine() {
    var unidadeGas =
      parseFloat(this.getComposicaoH2OnaChamine()) +
      this.getArTeorico() * (1 + this.excessoDeAr / 100) * 0.0132;

    return unidadeGas.toFixed(4);
  }

  getUnidadeGasO2naChamine() {
    var unidadeGas = 0.232 * ((this.excessoDeAr / 100) * this.getArTeorico());
    return unidadeGas.toFixed(4);
  }

  getTotalUnidadeGasNaChamine() {
    var totalUnidadeGas =
      parseFloat(this.getUnidadeGasN2naChamine()) +
      parseFloat(this.getUnidadeGasH2OnaChamine()) +
      parseFloat(this.getComposicaoSO2naChamine()) +
      parseFloat(this.getComposicaoCO2naChamine()) +
      parseFloat(this.getUnidadeGasO2naChamine());

    return totalUnidadeGas.toFixed(4);
  }

  getPorcentagemMassaN2naChamine() {
    var porcentagemMassa =
      (parseFloat(this.getUnidadeGasN2naChamine()) /
        parseFloat(this.getTotalUnidadeGasNaChamine())) *
      100;

    return porcentagemMassa.toFixed(4);
  }

  getPorcentagemMassaH2OnaChamine() {
    var porcentagemMassa =
      (parseFloat(this.getUnidadeGasH2OnaChamine()) /
        parseFloat(this.getTotalUnidadeGasNaChamine())) *
      100;

    return porcentagemMassa.toFixed(4);
  }

  getPorcentagemMassaSO2naChamine() {
    var porcentagemMassa =
      (parseFloat(this.getComposicaoSO2naChamine()) /
        parseFloat(this.getTotalUnidadeGasNaChamine())) *
      100;

    return porcentagemMassa.toFixed(4);
  }

  getPorcentagemMassaCO2naChamine() {
    var porcentagemMassa =
      (parseFloat(this.getComposicaoCO2naChamine()) /
        parseFloat(this.getTotalUnidadeGasNaChamine())) *
      100;

    return porcentagemMassa.toFixed(4);
  }

  getPorcentagemMassaO2naChamine() {
    var porcentagemMassa =
      (parseFloat(this.getUnidadeGasO2naChamine()) /
        parseFloat(this.getTotalUnidadeGasNaChamine())) *
      100;

    return porcentagemMassa.toFixed(4);
  }

  getTotalPercentagemMassaNaChamine() {
    var totalPercentagemMassa =
      parseFloat(this.getPorcentagemMassaN2naChamine()) +
      parseFloat(this.getPorcentagemMassaH2OnaChamine()) +
      parseFloat(this.getPorcentagemMassaSO2naChamine()) +
      parseFloat(this.getPorcentagemMassaCO2naChamine()) +
      parseFloat(this.getPorcentagemMassaO2naChamine());

    return totalPercentagemMassa.toFixed(4);
  }

  getNumeroMolesN2naChamine() {
    var numeroMoles = this.getPorcentagemMassaN2naChamine() / 28;

    return numeroMoles.toFixed(4);
  }

  getNumeroMolesH2OnaChamine() {
    var numeroMoles = this.getPorcentagemMassaH2OnaChamine() / 18;

    return numeroMoles.toFixed(4);
  }

  getNumeroMolesSO2naChamine() {
    var numeroMoles = this.getPorcentagemMassaSO2naChamine() / 54;

    return numeroMoles.toFixed(4);
  }

  getNumeroMolesCO2naChamine() {
    var numeroMoles = this.getPorcentagemMassaCO2naChamine() / 44;

    return numeroMoles.toFixed(4);
  }

  getNumeroMolesO2naChamine() {
    var numeroMoles = this.getPorcentagemMassaO2naChamine() / 32;

    return numeroMoles.toFixed(4);
  }

  getTotalNumeroMolesNaChamine() {
    var totalNumeroMoles =
      parseFloat(this.getNumeroMolesN2naChamine()) +
      parseFloat(this.getNumeroMolesH2OnaChamine()) +
      parseFloat(this.getNumeroMolesSO2naChamine()) +
      parseFloat(this.getNumeroMolesCO2naChamine()) +
      parseFloat(this.getNumeroMolesO2naChamine());

    return totalNumeroMoles.toFixed(4);
  }

  getPorcentagemVolumeN2naChamine() {
    var percentagemVolume =
      (parseFloat(this.getNumeroMolesN2naChamine()) /
        parseFloat(this.getTotalNumeroMolesNaChamine())) *
      100;

    return percentagemVolume.toFixed(4);
  }

  getPorcentagemVolumeH2OnaChamine() {
    var percentagemVolume =
      (parseFloat(this.getNumeroMolesH2OnaChamine()) /
        parseFloat(this.getTotalNumeroMolesNaChamine())) *
      100;

    return percentagemVolume.toFixed(4);
  }

  getPorcentagemVolumeSO2naChamine() {
    var percentagemVolume =
      (parseFloat(this.getNumeroMolesSO2naChamine()) /
        parseFloat(this.getTotalNumeroMolesNaChamine())) *
      100;

    return percentagemVolume.toFixed(4);
  }

  getPorcentagemVolumeCO2naChamine() {
    var percentagemVolume =
      (parseFloat(this.getNumeroMolesCO2naChamine()) /
        parseFloat(this.getTotalNumeroMolesNaChamine())) *
      100;

    return percentagemVolume.toFixed(4);
  }

  getPorcentagemVolumeO2naChamine() {
    var percentagemVolume =
      (parseFloat(this.getNumeroMolesO2naChamine()) /
        parseFloat(this.getTotalNumeroMolesNaChamine())) *
      100;

    return percentagemVolume.toFixed(4);
  }

  getTotalPorcentagemVolumeNaChamine() {
    var totalPercentagemVolume =
      parseFloat(this.getPorcentagemVolumeN2naChamine()) +
      parseFloat(this.getPorcentagemVolumeH2OnaChamine()) +
      parseFloat(this.getPorcentagemVolumeSO2naChamine()) +
      parseFloat(this.getPorcentagemVolumeCO2naChamine()) +
      parseFloat(this.getPorcentagemVolumeO2naChamine());

    return totalPercentagemVolume.toFixed(4);
  }

  getArTeorico() {
    var arTeorico =
      ((this.getBaseUmida().carbono * 2.664 +
        this.getBaseUmida().hidrogenio * 7.936 -
        this.getBaseUmida().oxigenio +
        this.getBaseUmida().enxofre * 0.998) *
        4.31) /
      100;

    return arTeorico.toFixed(4);
  }

  getPcsBaseSeca() {
    var resultado = this.pcs * 1.8;
    return resultado.toFixed(4);
  }

  getPcsBaseUmida() {
    var resultado =
      parseFloat(this.getPcsBaseSeca()) * (1 - this.umidadeCombustivel / 100);
    return resultado.toFixed(4);
  }

  getPciBtuPorLbs() {
    var H59 = parseFloat(this.getPcsBaseUmida());
    var F24 = this.estadoCombustivel;
    var H26 = parseFloat(this.getBaseUmida().hidrogenio);
    var F20 = this.umidadeCombustivel;
    var resultado =
      H59 - ((2 - F24) * 1030 - (1 - F24) * 1060) * ((9 * H26 + F20) / 100);
    return resultado.toFixed(4);
  }

  getPciKcalPorKg() {
    var resultado = parseFloat(this.getPciBtuPorLbs()) / 1.8;
    return resultado.toFixed(4);
  }

  getGasUmidoNaFornalha() {
    var G75 = parseFloat(this.getArTeorico());
    var F36 = this.F36;
    var H30 = parseFloat(this.getBaseUmida().cinzas);

    var resultado = G75 * (1 + F36 / 100) * 1.0132 + 1 - H30 / 100;

    return resultado.toFixed(4);
  }

  getArUmidoTotal() {
    var G75 = parseFloat(this.getArTeorico());
    var F36 = this.F36;

    var resultado = G75 * (1 + F36 / 100) * 1.0132;
    return resultado.toFixed(4);
  }

  getGasUmidoNoFeixe() {
    var G75 = parseFloat(this.getArTeorico());
    var F36 = this.F36;
    var H30 = parseFloat(this.getBaseUmida().cinzas);

    var resultado = G75 * (1 + F36 / 100) * 1.0132 + 1 - H30 / 100;
    return resultado.toFixed(4);
  }

  getGasSecoNoFeixe() {
    var G75 = parseFloat(this.getArTeorico());
    var F36 = this.F36;
    var F20 = this.F20;
    var G78 = parseFloat(this.getGasUmidoNoFeixe());
    var H26 = parseFloat(this.getBaseUmida().hidrogenio);

    var resultado =
      G78 - (H26 * 8.936 + F20) / 100 - 0.0132 * G75 * (1 + F36 / 100);

    return resultado.toFixed(4);
  }

  getUmidadeProduzida() {
    var H26 = parseFloat(this.getBaseUmida().hidrogenio);
    var resultado = (8.936 * H26) / 100;
    return resultado.toFixed(4);
  }

  getUmidadeNoAr() {
    var F38 = parseFloat(this.getPressaoAtmosfericaLocal());
    var F36 = this.F36;
    var G75 = parseFloat(this.getArTeorico());
    var resultado = ((0.0132 * 760) / F38) * G75 * (1 + F36 / 100);
    return resultado.toFixed(4);
  }

  getUmidadeNoCombustivel() {
    var F20 = this.F20;
    var resultado = F20 / 100;
    return resultado.toFixed(4);
  }

  getMassaMolecularDosGasesUmidos() {
    var G57 = parseFloat(this.getTotalNumeroMolesNaChamine());
    var resultado = 100 / G57;
    return resultado.toFixed(4);
  }

  getMassaMolecularDosGasesSecos() {
    var G57 = parseFloat(this.getTotalNumeroMolesNaChamine());
    var F53 = parseFloat(this.getPorcentagemMassaH2OnaChamine());
    var G53 = parseFloat(this.getNumeroMolesH2OnaChamine());
    var resultado = (100 - F53) / (G57 - G53);
    return resultado.toFixed(4);
  }

  getVolumeGasesUmidos() {
    var G83 = parseFloat(this.getMassaMolecularDosGasesUmidos());
    var resultado = 22.4 / G83;
    return resultado.toFixed(4);
  }

  getPressaoParcialCO2() {
    var G57 = parseFloat(this.getTotalNumeroMolesNaChamine());
    var F38 = parseFloat(this.getPressaoAtmosfericaLocal());
    var G55 = parseFloat(this.getNumeroMolesCO2naChamine());
    var resultado = ((G55 / G57) * F38) / 760;
    return resultado.toFixed(4);
  }

  getPressaoParcialH2O() {
    var G57 = parseFloat(this.getTotalNumeroMolesNaChamine());
    var F38 = parseFloat(this.getPressaoAtmosfericaLocal());
    var G53 = parseFloat(this.getNumeroMolesH2OnaChamine());
    var resultado = ((G53 / G57) * F38) / 760;
    return resultado.toFixed(4);
  }

  getSomaPressoesParciais() {
    var G86 = parseFloat(this.getPressaoParcialCO2());
    var G87 = parseFloat(this.getPressaoParcialH2O());
    var resultado = G86 + G87;
    return resultado.toFixed(4);
  }

  getUmidadeNosGases() {
    var F53 = parseFloat(this.getPorcentagemMassaH2OnaChamine());
    var resultado = F53 - 0.1;
    return resultado.toFixed(4);
  }

  getGasSecoNoFeixeUnitario() {
    var G75 = parseFloat(this.getArTeorico());
    var F36 = this.F36;
    var F20 = this.F20;
    var G78 = parseFloat(this.getGasUmidoNoFeixe());
    var H26 = parseFloat(this.getBaseUmida().hidrogenio);
    var F38 = parseFloat(this.getPressaoAtmosfericaLocal());

    var resultado =
      G78 -
      (H26 * 8.936 + F20) / 100 -
      ((0.0132 * 760) / F38) * G75 * (1 + F36 / 100);
    return resultado.toFixed(4);
  }

  getUmidadeProduzidaUnitario() {
    var H26 = parseFloat(this.getBaseUmida().hidrogenio);
    var resultado = (8.936 * H26) / 100;

    return resultado.toFixed(4);
  }

  getUmidadeNoCombustivelUnitario() {
    var F20 = this.F20;
    var resultado = F20 / 100;
    return resultado.toFixed(4);
  }

  getUmidadeNoArUnitario() {
    var F38 = parseFloat(this.getPressaoAtmosfericaLocal());
    var F36 = this.F36;
    var G75 = parseFloat(this.getArTeorico());

    var resultado = ((0.0132 * 760) / F38) * G75 * (1 + F36 / 100);
    return resultado.toFixed(4);
  }

  getDensidadeDosGases() {
    var G85 = parseFloat(this.getVolumeGasesUmidos());
    var resultado = 1 / G85;

    return resultado.toFixed(4);
  }

  getDensidadeDosGasesNaTemperatura() {
    var G85 = parseFloat(this.getVolumeGasesUmidos());
    var F42 = this.F42;
    var resultado = (1 / G85) * (273 / (273 + F42));

    return resultado.toFixed(4);
  }

  getCalorTotalTransferidoKcalPorKg() {
    var F15 = this.F15;
    var F74 = parseFloat(this.getSaltoEntalpico());
    var resultado = F15 * F74;
    return resultado.toFixed(4);
  }

  getCalorTotalTransferidoKjPorKg() {
    var G97 = parseFloat(this.getCalorTotalTransferidoKcalPorKg());
    var resultado = G97 * 4.184;

    return resultado.toFixed(4);
  }

  getCalorTotalLiberadoNaFornalhaKcalPorHr() {
    var G120 = parseFloat(this.getEficienciaAoPcs());
    var G97 = parseFloat(this.getCalorTotalTransferidoKcalPorKg());
    var resultado = (G97 / G120) * 100;

    return resultado.toFixed(4);
  }

  getCalorTotalLiberadoNaFornalhaKjPorHr() {
    var G99 = parseFloat(this.getCalorTotalLiberadoNaFornalhaKcalPorHr());
    var resultado = G99 * 4.184;

    return resultado.toFixed(4);
  }

  getPerdasPorGasesSecos() {
    var G90 = parseFloat(this.getGasSecoNoFeixeUnitario());
    var F41 = this.F41;
    var F42 = this.F42;
    var H59 = parseFloat(this.getPcsBaseUmida());

    var resultado =
      (G90 * 0.24 * (F42 * 1.8 + 32 - (F41 * 1.8 + 32)) * 100) / H59;
    return resultado.toFixed(4);
  }

  getPerdasPorUmidadeProduzida() {
    var G91 = parseFloat(this.getUmidadeProduzidaUnitario());
    var F41 = this.F41;
    var F42 = this.F42;
    var H59 = parseFloat(this.getPcsBaseUmida());
    var resultado =
      (G91 * (1089 - (F41 * 1.8 + 32) + 0.46 * (F42 * 1.8 + 32)) * 100) / H59;

    return resultado.toFixed(4);
  }

  getPerdasPorUmidadeDeCombustivel() {
    var G92 = parseFloat(this.getUmidadeNoCombustivelUnitario());
    var F39 = this.F39;
    var F42 = this.F42;
    var H59 = parseFloat(this.getPcsBaseUmida());
    var resultado =
      (G92 * (1089 - (F39 * 1.8 + 32) + 0.46 * (F42 * 1.8 + 32)) * 100) / H59;

    return resultado.toFixed(4);
  }

  getPerdasPorUmidadeNoAr() {
    var G93 = parseFloat(this.getUmidadeNoArUnitario());
    var F41 = this.F41;
    var F42 = this.F42;
    var H59 = parseFloat(this.getPcsBaseUmida());
    var resultado =
      (G93 * 0.46 * (F42 * 1.8 + 32 - (F41 * 1.8 + 32)) * 100) / H59;

    return resultado.toFixed(4);
  }

  getPerdasTotal() {
    var G115 = parseFloat(this.getPerdasPorGasesSecos());
    var G116 = parseFloat(this.getPerdasPorUmidadeProduzida());
    var G117 = parseFloat(this.getPerdasPorUmidadeDeCombustivel());
    var G118 = parseFloat(this.getPerdasPorUmidadeNoAr());
    var F46 = this.F46;
    var F47 = this.F47;
    var F48 = this.F48;
    var resultado = G115 + G116 + G117 + G118 + F46 + F47 + F48;

    return resultado.toFixed(4);
  }

  getEficienciaAoPcs() {
    var G119 = parseFloat(this.getPerdasTotal());
    var resultado = 100 - G119;

    return resultado.toFixed(4);
  }

  getConsumoDeCombustivelKgPorHr() {
    var G99 = parseFloat(this.getCalorTotalLiberadoNaFornalhaKcalPorHr());
    var H59 = parseFloat(this.getPcsBaseUmida());
    var resultado = (G99 / H59) * 1.8;

    return resultado.toFixed(4);
  }

  getConsumoDeCombustivelM3porHr() {
    var G100 = parseFloat(this.getConsumoDeCombustivelKgPorHr());
    var resultado = G100 / 450;

    return resultado.toFixed(4);
  }

  getConsumoDeCombustivelKgPorDia() {
    var G100 = parseFloat(this.getConsumoDeCombustivelKgPorHr());
    var F44 = this.F44;
    var resultado = G100 * F44;

    return resultado.toFixed(4);
  }

  getConsumoDeCombustivelM3porDia() {
    var G101 = parseFloat(this.getConsumoDeCombustivelKgPorDia());
    var resultado = G101 / 450;

    return resultado.toFixed(4);
  }

  getConsumoDeCombustivelKgPorMes() {
    var G101 = parseFloat(this.getConsumoDeCombustivelKgPorDia());
    var F45 = this.F45;
    var resultado = G101 * F45;

    return resultado.toFixed(4);
  }

  getEficienciaAoPcsSemPerdas() {
    var G120 = parseFloat(this.getEficienciaAoPcs());
    var F47 = this.F47;
    var F48 = this.F48;
    var resultado = G120 + F47 + (2 / 3) * F48;
    return resultado.toFixed(4);
  }

  getInputBurnedKcalPorHr() {
    var G121 = parseFloat(this.getEficienciaAoPcsSemPerdas());
    var G97 = parseFloat(this.getCalorTotalTransferidoKcalPorKg());
    var resultado = (G97 / G121) * 100;
    return resultado.toFixed(4);
  }

  getInputBurnedKjPorHr() {
    var G104 = parseFloat(this.getInputBurnedKcalPorHr());
    var resultado = G104 * 4.184;
    return resultado.toFixed(4);
  }

  getCombustivelQueimado() {
    var G104 = parseFloat(this.getInputBurnedKcalPorHr());
    var H59 = parseFloat(this.getPcsBaseUmida());
    var resultado = G104 / (H59 / 1.8);
    return resultado.toFixed(4);
  }

  getVazaoDeGases() {
    var G105 = parseFloat(this.getCombustivelQueimado());
    var G76 = parseFloat(this.getGasUmidoNaFornalha());
    var resultado = G76 * G105;
    return resultado.toFixed(4);
  }

  getVazaoDosGasesVolumetricaAposPreArM3PorHr() {
    var G106 = parseFloat(this.getVazaoDeGases());
    var G95 = parseFloat(this.getDensidadeDosGasesNaTemperatura());
    var resultado = G106 / G95;
    return resultado.toFixed(4);
  }

  getVazaoDosGasesVolumetricaAposPreArNm3PorHr() {
    var G107 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArM3PorHr());
    var F42 = this.F42;
    var resultado = G107 / ((273 + F42) / 273);
    return resultado.toFixed(4);
  }

  getSuprimentoArCaldeira() {
    var G105 = parseFloat(this.getCombustivelQueimado());
    var G77 = parseFloat(this.getArUmidoTotal());
    var resultado = G77 * G105;
    return resultado.toFixed(4);
  }

  getSuprimentoVolumetricoArCaldeiraNm3PorHr() {
    var G109 = parseFloat(this.getSuprimentoArCaldeira());
    var resultado = G109 / 1.23;
    return resultado.toFixed(4);
  }

  getSuprimentoVolumetricoArCaldeiraM3PorHr() {
    var G110 = parseFloat(this.getSuprimentoVolumetricoArCaldeiraNm3PorHr());
    var F41 = this.F41;
    var resultado = G110 * ((273 + F41) / 273);
    return resultado.toFixed(4);
  }

  getVazaoVentiladorPrimario() {
    var G111 = parseFloat(this.getSuprimentoVolumetricoArCaldeiraM3PorHr());
    var G113 = parseFloat(this.getVazaoVentiladorSecundario());
    var resultado = G111 - G113;
    return resultado.toFixed(4);
  }

  getVazaoVentiladorSecundario() {
    var G111 = parseFloat(this.getSuprimentoVolumetricoArCaldeiraM3PorHr());
    var F43 = this.F43;
    var resultado = G111 * (F43 / 100);
    return resultado.toFixed(4);
  }

  getGasUmidoFeixe() {
    var G105 = parseFloat(this.getCombustivelQueimado());
    var G78 = parseFloat(this.getGasUmidoNoFeixe());
    var resultado = G78 * G105;
    return resultado.toFixed(4);
  }

  getEficienciaAoPci() {
    var G97 = parseFloat(this.getCalorTotalTransferidoKcalPorKg());
    var G100 = parseFloat(this.getConsumoDeCombustivelKgPorHr());
    var H60 = parseFloat(this.getPciKcalPorKg());

    var resultado = (G97 / (G100 * H60)) * 100;
    return resultado.toFixed(4);
  }

  getMassaAguaCondensada() {
    var G100 = parseFloat(this.getConsumoDeCombustivelKgPorHr());
    var G105 = parseFloat(this.getCombustivelQueimado());
    var F18 = this.F18;
    var G91 = parseFloat(this.getUmidadeProduzidaUnitario());
    var G82 = parseFloat(this.getUmidadeNoCombustivel());
    var resultado = (G91 * G105 + G82 * G100) * F18;
    return resultado.toFixed(4);
  }

  getCalorRecuperado() {
    var G100 = parseFloat(this.getConsumoDeCombustivelKgPorHr());
    var G123 = parseFloat(this.getMassaAguaCondensada());
    var F35 = this.F35;
    var resultado = (G123 * 540) / (G100 * F35);
    return resultado.toFixed(4);
  }

  getEficienciaComCondensacaoAoPci() {
    var G124 = parseFloat(this.getCalorRecuperado());
    var G122 = parseFloat(this.getEficienciaAoPci());
    // var resultado = (G122 + G124 * 100) / 100;
    var resultado = G122 + G124 * 100;
    return resultado.toFixed(4);
  }

  getPCSTeorico() {
    var F25 = this.F25;
    var F26 = this.F26;
    var F27 = this.F27;
    var F28 = this.F28;
    var F29 = this.F29;
    var F30 = this.F30;
    var resultado =
      (0.3491 * F25 +
        1.1783 * F26 -
        0.1034 * F27 -
        0.0151 * F29 -
        0.0211 * F30 +
        0.1005 * F28) *
      239.005736;
    return resultado.toFixed(4);
  }

  getEmissoesN2ChamineMgPorNm3() {
    var F52 = parseFloat(this.getPorcentagemMassaN2naChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G108 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArNm3PorHr());
    var resultado = ((F52 / 100) * G106 * 1000000) / G108;
    return resultado.toFixed(4);
  }

  getEmissoesN2ChamineMgPorM3() {
    var F52 = parseFloat(this.getPorcentagemMassaN2naChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G107 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArM3PorHr());
    var resultado = ((F52 / 100) * G106 * 1000000) / G107;
    return resultado.toFixed(4);
  }

  getEmissoesH20ChamineMgPorNm3() {
    var F53 = parseFloat(this.getPorcentagemMassaH2OnaChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G108 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArNm3PorHr());
    var resultado = ((F53 / 100) * G106 * 1000000) / G108;
    return resultado.toFixed(4);
  }

  getEmissoesH20ChamineMgPorM3() {
    var F53 = parseFloat(this.getPorcentagemMassaH2OnaChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G107 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArM3PorHr());
    var resultado = ((F53 / 100) * G106 * 1000000) / G107;
    return resultado.toFixed(4);
  }

  getEmissoesSO2ChamineMgPorNm3() {
    var F54 = parseFloat(this.getPorcentagemMassaSO2naChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G108 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArNm3PorHr());
    var resultado = ((F54 / 100) * G106 * 1000000) / G108;
    return resultado.toFixed(4);
  }

  getEmissoesSO2ChamineMgPorM3() {
    var F54 = parseFloat(this.getPorcentagemMassaSO2naChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G107 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArM3PorHr());
    var resultado = ((F54 / 100) * G106 * 1000000) / G107;
    return resultado.toFixed(4);
  }

  getEmissoesCO2ChamineMgPorNm3() {
    var F55 = parseFloat(this.getPorcentagemMassaCO2naChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G108 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArNm3PorHr());
    var resultado = ((F55 / 100) * G106 * 1000000) / G108;
    return resultado.toFixed(4);
  }

  getEmissoesCO2ChamineMgPorM3() {
    var F55 = parseFloat(this.getPorcentagemMassaCO2naChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G107 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArM3PorHr());
    var resultado = ((F55 / 100) * G106 * 1000000) / G107;
    return resultado.toFixed(4);
  }

  getEmissoesO2ChamineMgPorNm3() {
    var F56 = parseFloat(this.getPorcentagemMassaO2naChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G108 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArNm3PorHr());
    var resultado = ((F56 / 100) * G106 * 1000000) / G108;
    return resultado.toFixed(4);
  }

  getEmissoesO2ChamineMgPorM3() {
    var F56 = parseFloat(this.getPorcentagemMassaO2naChamine());
    var G106 = parseFloat(this.getVazaoDeGases());
    var G107 = parseFloat(this.getVazaoDosGasesVolumetricaAposPreArM3PorHr());
    var resultado = ((F56 / 100) * G106 * 1000000) / G107;
    return resultado.toFixed(4);
  }
}
