import { formatNumber } from 'utils/formatNumber';

const temp = {
  100: 0.958,
  90: 0.965,
  80: 0.971,
  70: 0.977,
  60: 0.983,
  50: 0.988,
  40: 0.992,
  30: 0.995,
  25: 0.997,
  20: 0.998,
  15: 0.999,
  10: 0.999,
  5: 0.999,
  0: 1.0,
};

export default class bombaDeAgua {
  constructor(vazaoAgua, pressaoAgua, temperatura, fatorSeguranca, eficiencia) {
    this.vazaoAgua = vazaoAgua;
    this.pressaoAgua = pressaoAgua;
    this.temperatura = temperatura;
    this.fatorSeguranca = fatorSeguranca;
    this.eficiencia = eficiencia;
  }

  getVazaoAgua() {
    return Math.round(this.vazaoAgua * this.fatorSeguranca).toFixed(0);
  }

  getPressaoAgua() {
    return Math.round(this.pressaoAgua * this.fatorSeguranca).toFixed(0);
  }

  getMotorBomba() {
    const resp =
      ((this.getVazaoAgua() / 1000) *
        (this.getPressaoAgua() * 10) *
        temp[this.temperatura]) /
      270;
    return (resp * (1 / this.eficiencia)).toFixed(2);
  }

  getResultados() {
    const resultados = [
      {
        nome: 'Motor da Bomba de Água',
        valor: formatNumber(this.getMotorBomba()),
        medida: 'Cv',
      },
      {
        nome: 'Vazão de Água',
        valor: formatNumber(this.vazaoAgua),
        medida: 'kg/h',
      },
      {
        nome: 'Vazão de Água Adotada',
        valor: formatNumber(this.getVazaoAgua()),
        medida: 'kg/h',
      },
      {
        nome: 'Pressão de Água',
        valor: formatNumber(this.pressaoAgua),
        medida: 'kgf/cm²',
      },
      {
        nome: 'Pressão de Água Adotada',
        valor: formatNumber(this.getPressaoAgua()),
        medida: 'kgf/cm²',
      },
      {
        nome: 'Temperatura',
        valor: formatNumber(this.temperatura),
        medida: '°C',
      },
      {
        nome: 'Densidade de Água',
        valor: formatNumber(temp[this.temperatura]),
        medida: 'g/cm³',
      },
      {
        nome: 'Fator de Segurança',
        valor: formatNumber(this.fatorSeguranca),
        medida: '',
      },
      {
        nome: 'Eficiência',
        valor: formatNumber(Math.round(this.eficiencia * 100)),
        medida: '%',
      },
    ];

    return resultados;
  }
}
