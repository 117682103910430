import { forwardRef } from "react";
import { MaskedInput } from "../MaskedInput";
import { Container } from "./styles";

const InputBase = (props, ref) => {
  const { title, metric } = props;
  return (
    <Container>
      <span className="title">{title}</span>
      <div className="inputBox">
        <MaskedInput {...props} ref={ref} />
        <span className="metric">{metric}</span>
      </div>
    </Container>
  );
};

export const Input = forwardRef(InputBase);
