import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

    :root {
        --primary: #ff3131;
        --red: #e52e4d;
        --blue: #5429cc;
        --green: #33cc95;
        
        --blue-light: #6933ff;
        
        --text-title: #363f5f;
        --text-body: #969cb3;
        
        --background: #f0f2f5;
        --shape: #FFFFFF;
    }

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    // font-size : 16px (Desktop)
    html{
        @media (max-width: 1080px) {
            font-size: 93.75%; // 15px
        }

        @media (max-width: 720px) {
            font-size: 87.5%; // 14px
        }
    }

    //input, textarea, button não pegam a font do body
    body, input, textarea, button{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong{
        font-weight: 600;
    }

    body{
        background: var(--background);
        -webkit-font-smoothing: antialiased;
        margin:0px;
        width:100vw;
        height:100vh;
        overflow:auto;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    #root{
        height: 100%;
    }
`;
