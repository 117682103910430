export const caldeiras = {
  //TODO: completar caldeiras
  oleo_e_gas: [
    {
      id: 1,
      nome: 'JPO',
      path_foto:
        'https://steammaster.com.br/wp-content/uploads/2021/07/jpo-1.jpg',
      path_datasheet: '/datasheets/JPO-R1.pdf',
      descricao:
        'Caldeira aquatubular tipo D, de alta eficiência. Projeto inovador, compacto e com um único tubulão externo.',
      descricao_completa:
        'Sua tubulação com design robusto acoplado a grandes coletores externos, garante uma efetiva circulação natural da água pelas diversas partes que compõe a caldeira. Queimador de fábrica com baixíssimas emissões de NOx, menor que 50ppm. Aliado a um controlador com certificação UL e CE, controle de O2 online e economizador, garantem rendimento superior a 96%. Tem capacidade de produzir vapor superaquecido até 450°C em altas pressões, para aplicações específicas ou mesmo geração de energia elétrica. Para a caldeira JPO, a produção de vapor considera a água de alimentação a 105°C.',
      fotos: [
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/jpo-1.jpg',
          title: 'image title 1',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/jpo-6-1024x454.jpg',
          title: 'image title 2',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/DF-4-scaled.jpg',
          title: 'image title 3',
        },
      ],
      capacidade: {
        min: 6000,
        max: 60000,
      },
      pressao: {
        max: 164,
      },
    },
    {
      id: 2,
      nome: 'DF',
      path_foto:
        'https://steammaster.com.br/wp-content/uploads/2021/07/DF-4-scaled.jpg',
      path_datasheet: '/datasheets/DF-R1.pdf',
      descricao:
        'Caldeira flamotubular com dois queimadores e 3 passes dos gases que, além da alta eficiência, garante maiores produções de vapor e alto turndown.',
      descricao_completa:
        'O queimador axial moderno de fábrica trabalha em conjunto com a caldeira, proporcionando baixíssimas emissões de CO, além do NOx menor que 70ppm, sem necessidade de recirculação de gases. Possui controlador com certificação UL e CE, controle de O2 online e traseira úmida (wet back) e economizador, que garantem o seu alto rendimento (superior a 94%). Para a caldeira DF, a produção de vapor considera a água de alimentação a 20°C.',
      fotos: [
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/DF-5-scaled.jpg',
          title: 'image title 1',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/DF-1-scaled.jpg',
          title: 'image title 2',
        },
      ],
      capacidade: {
        min: 20000,
        max: 30000,
      },
      pressao: {
        max: 21,
      },
    },
    {
      id: 3,
      nome: 'Four',
      path_foto:
        'https://steammaster.com.br/wp-content/uploads/2021/07/FOUR-10-1-scaled.jpg',
      path_datasheet: '/datasheets/FOUR-R1.pdf',
      descricao:
        'Caldeira flamotubular de alta eficiência, a única brasileira com 4 passes dos gases, que garante economia de combustível e mais eficiência na produção de vapor.',
      descricao_completa:
        'Possui queimador de difusor axial, que proporciona estabilidade inigualável permitindo o funcionamento silencioso e livre de vibrações; que já vem de fábrica com tecnologia Low NOx, assegurando baixíssimas emissões de NOx (menor que 70ppm) sem necessidade de recirculação de gases. É dotada de controlador de chama e da caldeira com certificação UL e CE, com gerenciamento eletrônico total específico, capaz de modular todas as variáveis do equipamento. Para a caldeira FOUR, a produção de vapor considera a água de alimentação a 20°C.',
      fotos: [
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/FOUR-10-1-scaled.jpg',
          title: 'image title 1',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/FOUR-7-scaled.jpg',
          title: 'image title 2',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/FOUR-3-1.jpg',
          title: 'image title 3',
        },
      ],
      capacidade: {
        min: 2000,
        max: 15000,
      },
      pressao: {
        max: 21,
      },
    },
    {
      id: 4,
      nome: 'VMO-S',
      path_foto: '/assets/vmo.png',
      path_datasheet: '/datasheets/VMO-S-R1.pdf',
      descricao:
        'Caldeira flamotubular, vapor saturado de 03' +
        'passes com alta velocidade dos gases e fornalha' +
        'interna de grande diâmetro com wet-back, fator' +
        'crucial para uma ótima eficiência.',
      descricao_completa:
        'Fornalha com reversão interna de chama para' +
        'máxima transferência de calor por radiação.' +
        'Baixa temperatura na chaminé e um melhor' +
        'rendimento.' +
        'Grande margem de segurança entre a fornalha e' +
        'área de vaporização e câmara de vapor dotada de' +
        'separador de vapor, garantindo ótimo grau de' +
        'pureza do vapor.' +
        'Queimador de alta eficiente e baixa emissão de' +
        'poluentes, queima de combustível diversos como' +
        'óleos , gases residuais, gás natural, GLP ou dual.' +
        'A chama é observada diretamente pelo visor' +
        'frontal do queimador e no visor na parte traseira' +
        'da fornalha.' +
        'Portas frontais e traseira com dobradiças e acesso' +
        'total aos tubos sem interferência e refratários,' +
        'podendo a caldeira ser aberta, sem a necessidade' +
        ' de ferramentas especiais.' +
        'Porta de acesso ao interno do casco para pessoa' +
        'na parte superior.' +
        'Acesso de inspeção de mão na parte inferior do' +
        'casco.' +
        'Chaminé Stayada espessura de 3,2mm com altura' +
        'personalizavel e damper manual.' +
        'Plataforma ao longo de toda parte superior do' +
        'casco da caldeira, facilitando o acesso às válvulas' +
        'e acessórios.' +
        'Isolamento térmico em lã de rocha com' +
        'espessura de 100 mm com revestimento em aço' +
        'de telha trapezoidais, com caixas de fumaça' +
        'dianteira e traseira com portas isoladas.' +
        'Controle da combustão por controlador dedicado' +
        'e integrado, que inclui toda sequência da' +
        ' combustão, controle de modulação da relação ar/' +
        'combustível, controle de estanqueidade, controle' +
        'de ignição e supervisão de chama. No sistema de' +
        'proporção ar/ combustível é instalado um atuador' +
        'na válvula de combustível, outro no damper do ar' +
        'e um variador de frequência opcional no ar de' +
        'combustão, neste sistema serão feitas as' +
        'regulagens, gerando uma curva da relação da' +
        'posição dos atuadores, estas são armazenadas,' +
        'fornecendo uma repetitividade necessária' +
        'alcançando uma combustão mais eficiente, com' +
        'precisão de ± 0,1 °.' +
        'Fabricado conforme normas internas do sistema' +
        'de gestão de qualidade Steammaster NR10, NR12,' +
        'NR13 e ASME',
      fotos: [],
      capacidade: {
        min: 500,
        max: 4000,
      },
      pressao: {
        max: 12,
      },
    },
  ],
  biomassa: [
    {
      id: 1,
      nome: 'JPD',
      path_foto:
        'https://steammaster.com.br/wp-content/uploads/2021/07/JPD-3.jpg',
      path_datasheet: '/datasheets/JPD-R1.pdf',
      descricao:
        'Caldeira aquatubular modular para altas pressões e temperaturas, capaz de produzir vapor superaquecido para aplicações específicas ou mesmo geração de energia elétrica.',
      descricao_completa:
        'É fabricada em 3 módulos: grelha reciprocante, fornalha aquatubular e corpo flamotubular. Configuração que acelera a montagem de campo garantindo a economia de recursos. Após saída dos gases, a caldeira possui mais dois trocadores de calor, o economizador e o pré- aquecedor de ar, aumentando a sua eficiência graças a entrada de água quente na caldeira que melhora a combustão devido a injeção de ar quente na grelha. Além disso, possui injeção de ar na chama (over fire) para melhoria da combustão e redução das emissões. O sistema de combustão é feito por meio de grelha móvel do tipo reciprocante, capaz de aceitar uma alta variedade de biomassa. O movimento reciproco é ajustável em sua frequência e velocidade, o que assegura a queima completa mesmo em combustíveis em altas umidades. Possui alto nível de automatização, capaz de controlar e receber feedback das mais avançadas variáveis de controle da caldeira, permitindo ajuste fino da caldeira e garantindo a maior eficiência possível para queima de biomassa. Pré-aquecedor de ar e produção de vapor considerando água de alimentação à 105°C.',
      fotos: [
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/JPD-4.jpg',
          title: 'image title 1',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/JPD-1.png',
          title: 'image title 2',
        },
      ],
      capacidade: {
        min: 15000,
        max: 40000,
      },
      pressao: {
        max: 42,
      },
    },
    {
      id: 2,
      nome: 'VME-RG',
      path_foto:
        'https://steammaster.com.br/wp-content/uploads/2021/07/VME-1.jpeg',
      path_datasheet: '/datasheets/VME-RG-R1.pdf',
      descricao:
        'Caldeira Steammaster do tipo mista e modular para maiores capacidades.',
      descricao_completa:
        'É fabricada em 3 módulos: grelha reciprocante, fornalha aquatubular e corpo flamotubular. Configuração que acelera o processo de montagem de campo, garantindo a economia de recursos. Após saída dos gases da caldeira, possui mais dois trocadores de calor, sendo o primeiro o economizador e o segundo o pré-aquecedor de ar, aumentando a eficiência por causa da entrada de água quente na caldeira, melhorando a combustão devido a injeção de ar quente na grelha. Além disso, possui injeção de ar na chama (over fire) melhorando da combustão e redução das emissões. O sistema de combustão é feito por meio de grelha móvel do tipo reciprocante, capaz de suportar uma alta variedade de biomassa. O movimento reciproco é ajustável em sua frequência e velocidade, garantindo a queima completa para combustíveis em altas umidades.',
      fotos: [
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/VME-1.jpg',
          title: 'Caldeira biomassa com queimador a gás 1',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/VME-2.jpg',
          title: 'Caldeira biomassa com queimador a gás 2',
        },
      ],
      capacidade: {
        min: 25000,
        max: 40000,
      },
      pressao: {
        max: 32,
      },
    },
    {
      id: 3,
      nome: 'VMIC-RG',
      path_foto:
        'https://steammaster.com.br/wp-content/uploads/2021/07/VMIC-RG-1.jpg',
      path_datasheet: '/datasheets/VMIC-RG-R1.pdf',
      descricao:
        'Caldeira do tipo mista e modular fabricada em 2 módulos: a fornalha aquatubular e o corpo flamotubular.',
      descricao_completa:
        'Essa configuração modular acelera o processo de montagem de campo assegurando a economia de recursos. Possui pré-aquecedor de ar integrado como quarta passagem dos gases, o que aumenta a eficiência e melhora a combustão, graças a injeção de ar quente na grelha. Além disso, possui injeção de ar na chama (over fire) para melhoria da combustão e redução das emissões. O sistema de combustão é feito por meio de grelha móvel do tipo reciprocante, capaz de aceitar uma alta variedade de biomassa. O movimento recíproco é ajustável em sua frequência e velocidade, o que garante a queima completa mesmo em combustíveis em altas umidades.',
      fotos: [
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/VMIC-RG-1.jpg',
          title: 'Foto 1',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/VMIC-RG-1.png',
          title: 'Foto 2',
        },
      ],
      capacidade: {
        min: 10000,
        max: 20000,
      },
      pressao: {
        max: 21,
      },
    },
    {
      id: 4,
      nome: 'VMFI',
      path_foto:
        'https://steammaster.com.br/wp-content/uploads/2021/07/VMFI-19-2048x1362.jpg',
      path_datasheet: '/datasheets/VMFI-R1.pdf',
      descricao:
        'Caldeira para maiores capacidades de queima de lenha, com fornalha aquatubular de grande altura e corpo flamotubular.',
      descricao_completa:
        'Possui 3 passagens de gases pela caldeira e quarta passagem dos gases por um pré-aquecedor de ar, proporcionando o aumento de eficiência da caldeira e melhora a combustão da lenha, devido a injeção de ar quente na grelha. Além disso, possui sistema de injeção de ar na chama (over-fire), que melhora a combustão e diminui a emissão de poluentes. A caldeira é equipada com porta do tipo guilhotina e esteira alimentadora de lenha em toras de 10 metros de comprimento, que garante uma rápida alimentação, eliminando assim, a perda de energia por entrada de ar frio e radiação.',
      fotos: [
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/VMFI-21-scaled.jpg',
          title: 'Foto 1',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/VMFI-26-1-scaled.jpg',
          title: 'Foto 2',
        },
      ],
      capacidade: {
        min: 10000,
        max: 30000,
      },
      pressao: {
        max: 21,
      },
    },
    {
      id: 5,
      nome: 'VMU',
      path_foto:
        'https://steammaster.com.br/wp-content/uploads/2021/07/VMU-3-2048x1536.jpg',
      path_datasheet: '/datasheets/VMU-R1.pdf',
      descricao:
        'Caldeira dual mista, com fornalha aquatubular e corpo flamotubular de alta eficiência.',
      descricao_completa:
        'Além dos 3 passes dos gases pela caldeira, possui quarta passagem dos gases por um pré-aquecedor de ar, que já vai integrado ao corpo da caldeira, proporcionando aumento da eficiência da caldeira e melhora a combustão da lenha, motivado pela injeção de ar quente na grelha. A caldeira VMU possui grelha em forma de U, equipada com elementos em ferro fundido com cromo, para queima de biomassa picada e lenha. A alimentação de lenha é feita de forma manual por portas na frente da fornalha de fácil abertura e manuseio. Para queima de biomassa picada é utilizado um alimentador de roscas, que introduz o combustível dentro da fornalha.',
      fotos: [
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/VMU-3-scaled.jpg',
          title: 'Foto 1',
        },
        {
          url: 'https://steammaster.com.br/wp-content/uploads/2021/07/VMU-8-1-scaled.jpg',
          title: 'Foto 2',
        },
      ],
      capacidade: {
        min: 3000,
        max: 8000,
      },
      pressao: {
        min: 14,
        max: 21,
      },
    },
    {
      id: 6,
      nome: 'VMI',
      path_foto: '/assets/vmi.png',
      path_datasheet: '/datasheets/VMI-R1.pdf',
      descricao:
        'Caldeira flamotubular, vapor saturado, fornalha externa aquatubular, com circuito de troca térmica de 03 passes dos gases, fornecida montada em um único conjunto.',
      descricao_completa:
        'Grelha plana refrigerada pela própria água da caldeira.' +
        'Paredes d’água da fornalha formadas por tubos aletados com solda de vedação total.' +
        'Câmara de vapor dotada de separador de vapor,' +
        'garantindo ótimo grau de pureza do vapor.' +
        'Acesso fácil aos tubos, podendo a caldeira ser' +
        'aberta, sem a necessidade de ferramentas' +
        'especiais.' +
        'Conta com exaustor para perfeita tiragem dos' +
        ' gases da combustão.' +
        'Porta de acesso ao interno do casco para pessoa' +
        'na parte superior através de escada.' +
        'Chaminé Stayada espessura de 3,2mm com' +
        'altura personalizável a partir do piso e damper' +
        'manual.' +
        'Alimentação de lenha por esteira e porta' +
        'semiautomática.' +
        'Extração de Cinza manual.' +
        'Opção de fornalha com bocal para instalação' +
        'de queimadores a gás/óleo.' +
        'Não há materiais refratários, proporcionando' +
        'simplicidade de manutenção e resposta rápida' +
        'na geração de vapor. Isolamento térmico em lã' +
        'de rocha com espessura de 100 mm com' +
        'revestimento em aço inoxidável AISI 430.' +
        'Controle da combustão através do controle do' +
        ' fluxo de gases da combustão e ar injetado na' +
        'fornalha, por variação da rotação do exaustor' +
        'através de inversor de frequência, modulado ' +
        'por controlador de processo dedicado, tendo ' +
        'como parâmetro o limite ideal da pressão do ' +
        'vapor. ' +
        'Controle de nível de água na caldeira para ' +
        'controle intermitente, parametrizado para ligar ' +
        'e desligar a bomba de alimentação de água,' +
        'alarmes e desligamento geral em caso de' +
        'emergência;' +
        'Fabricado conforme normas internas do' +
        'sistema de gestão de qualidade Steammaster' +
        'NR10, NR12, NR13 e ASME.',
      fotos: [],
      capacidade: {
        min: 3000,
        max: 8000,
      },
      pressao: {
        max: 21,
      },
    },
  ],
  // caldeira_a_lenha: [],
  // biomassa_dual: [],
};
