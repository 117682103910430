import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

//import { createDeal } from 'services/deals';

export default function Test() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const sumbitDeal = () => {
    const deal = {
      title,
      description,
    };
    console.log(deal);

    try {
      //createDeal(deal);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div>alow</div>
      <TextField
        label="Titulo negocio"
        variant="outlined"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      <TextField
        label="Descricao"
        variant="outlined"
        multiline
        maxRows={4}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />

      <Button onClick={sumbitDeal}>Enviar negocio</Button>
    </>
  );
}
