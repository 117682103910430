import { useState, useEffect } from 'react';

import styles from '../../../styles/Resultados.module.scss';
import Button from '@mui/material/Button';

import DutoDeAr from '../../../../Classes/dutoDeAr';
import { useNavigate } from 'react-router-dom';

import ItemPrincipal from '../../../components/ItemPrincipal';
import ItemResultado from '../../../components/ItemResultado';
import { replaceDotAndComma } from 'utils/formatNumber';
import PopupPedido from 'view/components/PopupPedido';

export default function VentiladorResultados() {
  const navigate = useNavigate();

  const [carregando, setCarregando] = useState(true);

  const [resultados, setResultados] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const dados = JSON.parse(localStorage.getItem('dutoDeAr'));

    if (dados) {
      const { vazaoArAmbiente, velocidadeDuto } = dados;

      const dutoDeAr = new DutoDeAr(
        replaceDotAndComma(vazaoArAmbiente),
        replaceDotAndComma(velocidadeDuto)
      );

      const resultados = dutoDeAr.getResultados();

      setResultados(resultados);
      setCarregando(false);
    }

    setTimeout(() => {
      setOpenPopup(true);
    }, 4000);
  }, []);

  const handleClick = () => {
    navigate('/dutoDeAr');
  };

  return (
    <>
      <PopupPedido open={openPopup} handleClose={() => setOpenPopup(false)} />

      <div className={styles.container}>
        {!carregando && (
          <>
            <img src="/assets/smoke.jpg" alt="smoke" />

            <ItemPrincipal
              resultados={resultados}
              nome={resultados[0].nome}
              valor={resultados[0].valor}
              medida={resultados[0].medida}
              shareTitle="Resultados do Duto de Ar"
            />

            <div className={styles.infos}>
              {resultados.map((item, index) => {
                if (index > 0) {
                  return (
                    <ItemResultado
                      key={index}
                      nome={item.nome}
                      valor={item.valor}
                      medida={item.medida}
                    />
                  );
                }
                return null;
              })}

              <Button onClick={handleClick} variant="contained">
                Recalcular
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
