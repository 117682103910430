import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
//MUI
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

//SCSS
import styles from '../../../styles/Calculo.module.scss';

//React Router
import { useNavigate } from 'react-router-dom';

export default function CalculoPropiedadesVapor() {
  const navigate = useNavigate();

  //list with numbers 1 to 60
  const numbers = Array.from(Array(60).keys()).map((x) => x + 1);

  //Inicia campos com valor guardado no localStorage
  useEffect(() => {
    const propiedadesVapor = JSON.parse(
      localStorage.getItem('propiedadesVapor')
    );
    if (propiedadesVapor) {
      Object.keys(propiedadesVapor).forEach((key) => {
        setValue(key, propiedadesVapor[key]);
      });
    }
  }, []);

  //Calcula o valor do ventilador Ideal
  const onSubmit = (data) => {
    localStorage.setItem('propiedadesVapor', JSON.stringify(data));
    navigate('resultado');
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  return (
    <div className={styles.container}>
      <Paper elevation={0} className={styles.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <FormControl sx={{ mr: 1, ml: 1, mb: 0, mt: 2 }} variant="outlined">
            <InputLabel id="temperatura">Pressão do Vapor</InputLabel>
            {/* <Select
              labelId="temperatura"
              id="demo-simple-select"
              //value={temperatura}
              label="Pressão do Vapor"
              //onChange={(e) => setTemperatura(e.target.value)}
              {...register('pressaoVapor', { required: true })}
            >
              {numbers.map((number) => (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              ))}
            </Select> */}
            <Controller
              defaultValue={1}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="temperatura"
                  id="demo-simple-select"
                  label="Pressão do Vapor"
                >
                  {numbers.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="pressaoVapor"
              control={control}
            />
          </FormControl>

          <Box sx={{ m: 1 }}>
            <Button
              className={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              Calcular Propiedades do Vapor
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}

function Input({ label, metric, value, onChange, tip, register, name, error }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ m: 1 }} variant="outlined">
      {!tip && (
        <FormHelperText id="outlined-weight-helper-text">
          {label}
        </FormHelperText>
      )}
      {tip && (
        <Stack direction="row" alignItems="center">
          <FormHelperText id="outlined-weight-helper-text">
            {label}
          </FormHelperText>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tip}
              >
                <Button onClick={handleTooltipOpen}>?</Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Stack>
      )}

      <OutlinedInput
        id="outlined-adornment-weight"
        endAdornment={<InputAdornment position="end">{metric}</InputAdornment>}
        value={value}
        onChange={onChange}
        aria-describedby="outlined-weight-helper-text"
        {...register(name, { required: true })}
        error={error}
        type="number"
      />
      {error && (
        <FormHelperText error id="outlined-weight-helper-text">
          Campo obrigatório!
        </FormHelperText>
      )}
    </FormControl>
  );
}
