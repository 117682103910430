import Login from './view/pages/Login/Login';
import Home from './view/pages/Home/Home';

import { GlobalStyle } from './view/styles/global';

//Calculos
import { Eficiencia } from './view/pages/Calculos/Eficiencia/Eficiencia';
import EficienciaResultado from './view/pages/Calculos/Eficiencia/Resultados';

import VentiladorIdeal from './view/pages/Calculos/Ventilador';
import VentiladorCalculo from './view/pages/Calculos/Ventilador/Calculo';
import VentiladorResultado from './view/pages/Calculos/Ventilador/Resultados';

import BombaDeAgua from './view/pages/Calculos/BombaDeAgua';
import BombaDeAguaCalculo from './view/pages/Calculos/BombaDeAgua/Calculo';
import BombaDeAguaResultado from './view/pages/Calculos/BombaDeAgua/Resultados';

import DutoDeAr from './view/pages/Calculos/DutoDeAr';
import DutoDeArCalculo from './view/pages/Calculos/DutoDeAr/Calculo';
import DutoDeArResultado from './view/pages/Calculos/DutoDeAr/Resultados';

import LinhaDeAgua from './view/pages/Calculos/LinhaDeAgua';
import LinhaDeAguaCalculo from './view/pages/Calculos/LinhaDeAgua/Calculo';
import LinhaDeAguaResultado from './view/pages/Calculos/LinhaDeAgua/Resultados';

import LinhaDeVapor from './view/pages/Calculos/LinhaDeVapor';
import LinhaDeVaporCalculo from './view/pages/Calculos/LinhaDeVapor/Calculo';
import LinhaDeVaporResultado from './view/pages/Calculos/LinhaDeVapor/Resultados';

import ComparativoCustoCombustivel from './view/pages/Calculos/ComparativoCustoCombustivel';
import ComparacaoCombustiveis from './view/pages/Calculos/ComparativoCustoCombustivel/ComparacaoCombustiveis';
import DadosProjeto from './view/pages/Calculos/ComparativoCustoCombustivel/DadosProjeto';
import CadastroCombustivel from './view/pages/Calculos/ComparativoCustoCombustivel/CadastroCombustivel';
import ComparativoCustoCombustivelResultados from './view/pages/Calculos/ComparativoCustoCombustivel/Resultados';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSwitchTheme } from './Context/SwitchTheme';
import { Routes, Route } from 'react-router-dom';
import Test from './view/pages/test';

import ConsumoVaporDesareador from 'view/pages/Calculos/ConsumoVaporDesareador';
import ConsumoVaporDesareadorCalculo from 'view/pages/Calculos/ConsumoVaporDesareador/Calculo';
import ConsumoVaporDesareadorResultado from 'view/pages/Calculos/ConsumoVaporDesareador/Resultados';

import PropiedadesVapor from 'view/pages/Calculos/PropiedadesVapor';
import PropiedadesVaporCalculo from 'view/pages/Calculos/PropiedadesVapor/Calculo';
import PropiedadesVaporResultado from 'view/pages/Calculos/PropiedadesVapor/Resultados';

import Caldeiras from 'view/pages/Caldeiras';
import ListaCaldeiras from 'view/pages/Caldeiras/ListaCaldeiras';
import InfosCaldeira from 'view/pages/Caldeiras/InfosCaldeira';

import Selecao from 'view/pages/Caldeiras/Selecao';
import Datasheets from 'view/pages/Datasheets';
import ListaDatasheets from 'view/pages/Datasheets/ListaDatasheets';
import Configuracoes from 'view/pages/Configuracoes';
import Contato from 'view/pages/Contato';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import OpenSideBarProvider from 'Context/OpenSidebar';

function App() {
  const { theme } = useSwitchTheme();

  const light = createTheme({
    palette: {
      primary: {
        main: '#ff3131',
      },
    },
  });

  const dark = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#00cece',
      },
      background: {
        paper: 'rgb(5, 30, 52)',
      },
    },
  });

  return (
    <>
      <ToastContainer />
      <OpenSideBarProvider>
        <ThemeProvider theme={theme === 'light' ? light : dark}>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route path="testes" element={<Test />} />
            <Route path="eficiencia" element={<Eficiencia />} />
            <Route
              path="eficiencia/resultado"
              element={<EficienciaResultado />}
            />

            {/* Cálculo de Ventilador */}
            <Route path="ventiladorIdeal" element={<VentiladorIdeal />}>
              <Route index element={<VentiladorCalculo />} />
              <Route path="resultado" element={<VentiladorResultado />} />
            </Route>

            {/* Cálculo de Bomba de água */}
            <Route path="bombaDeAgua" element={<BombaDeAgua />}>
              <Route index element={<BombaDeAguaCalculo />} />
              <Route path="resultado" element={<BombaDeAguaResultado />} />
            </Route>

            <Route path="dutoDeAr" element={<DutoDeAr />}>
              <Route index element={<DutoDeArCalculo />} />
              <Route path="resultado" element={<DutoDeArResultado />} />
            </Route>

            <Route path="linhaDeAgua" element={<LinhaDeAgua />}>
              <Route index element={<LinhaDeAguaCalculo />} />
              <Route path="resultado" element={<LinhaDeAguaResultado />} />
            </Route>

            <Route path="linhaDeVapor" element={<LinhaDeVapor />}>
              <Route index element={<LinhaDeVaporCalculo />} />
              <Route path="resultado" element={<LinhaDeVaporResultado />} />
            </Route>

            <Route path="vaporDesareador" element={<ConsumoVaporDesareador />}>
              <Route index element={<ConsumoVaporDesareadorCalculo />} />
              <Route
                path="resultado"
                element={<ConsumoVaporDesareadorResultado />}
              />
            </Route>

            <Route path="propiedadesVapor" element={<PropiedadesVapor />}>
              <Route index element={<PropiedadesVaporCalculo />} />
              <Route path="resultado" element={<PropiedadesVaporResultado />} />
            </Route>

            <Route
              path="comparativoCombustiveis"
              element={<ComparativoCustoCombustivel />}
            >
              <Route index element={<ComparacaoCombustiveis />} />
              <Route path="dadosProjeto" element={<DadosProjeto />} />
              <Route
                path="cadastroCombustivel"
                element={<CadastroCombustivel />}
              />
              <Route
                path="cadastroCombustivel/:index"
                element={<CadastroCombustivel />}
              />
              <Route
                path="resultados"
                element={<ComparativoCustoCombustivelResultados />}
              />
            </Route>

            <Route path="home" element={<Home />}></Route>
            <Route path="caldeiras" element={<Caldeiras />}>
              <Route index element={<Selecao />} />
              <Route path=":tipo" element={<ListaCaldeiras />} />
              <Route path=":tipo/:caldeira" element={<InfosCaldeira />} />
            </Route>

            <Route path="datasheets" element={<Datasheets />}>
              <Route index element={<ListaDatasheets />} />
            </Route>
            <Route index element={<Home />} />

            <Route path="contato" element={<Contato />} />

            <Route path="configuracoes" element={<Configuracoes />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </ThemeProvider>
      </OpenSideBarProvider>
      <GlobalStyle />
    </>
  );
}

export default App;
