import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './comparativo.module.scss';
import DadosProjeto from '../../../../Classes/dadosProjeto';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';

export default function ComparativoCustoCombustivel() {
  let navigate = useNavigate();
  const [dadosProjeto, setDadosProjeto] = useState();

  const [combustivel1, setCombustivel1] = useState('');
  const [combustivel2, setCombustivel2] = useState('');

  const [combustiveis, setCombustiveis] = useState([
    {
      nome: 'Gás Natural',
      metrica: 'Nm³',
      custo: '2',
      pci: '9065',
      rendimentoTermico: '95',
    },
    {
      nome: 'Óleo Pesado',
      metrica: 'Litro',
      custo: '2',
      pci: '9750',
      rendimentoTermico: '95',
    },
    {
      nome: 'Óleo Diesel',
      metrica: 'Litro',
      custo: '2',
      pci: '8620',
      rendimentoTermico: '95',
    },
    {
      nome: 'Lenha',
      metrica: 'Kg',
      custo: '2',
      pci: '2500',
      rendimentoTermico: '84',
    },
    {
      nome: 'Cavaco',
      metrica: 'Kg',
      custo: '2',
      pci: '2500',
      rendimentoTermico: '84',
    },
  ]);

  useEffect(() => {
    const dadosProjeto = JSON.parse(localStorage.getItem('dadosProjeto'));
    if (!dadosProjeto) {
      navigate('/comparativoCombustiveis/dadosProjeto');
    }
    if (dadosProjeto) {
      const {
        producaoVapor,
        temperaturaEntrada,
        pressaoMaxima,
        horasTrabalhadas,
        rendimentoTermicoCombustivel1,
        rendimentoTermicoCombustivel2,
      } = dadosProjeto;

      const dados = new DadosProjeto(
        producaoVapor,
        temperaturaEntrada,
        pressaoMaxima,
        horasTrabalhadas,
        rendimentoTermicoCombustivel1,
        rendimentoTermicoCombustivel2
      );
      setDadosProjeto(dados);
    }
  }, []);

  useEffect(() => {
    const dados = localStorage.getItem('combustiveis');
    if (dados) {
      const dataParsed = JSON.parse(dados);
      setCombustiveis([...dataParsed]);
    } else {
      localStorage.setItem('combustiveis', JSON.stringify(combustiveis));
    }
  }, []);

  const editarDadosProjeto = () => {
    navigate('/comparativoCombustiveis/dadosProjeto');
  };

  const handleComparar = () => {
    const data = {
      combustivel1,
      combustivel2,
    };

    localStorage.setItem('combustiveisSelecionados', JSON.stringify(data));
    navigate('/comparativoCombustiveis/resultados');
  };

  const isCompararValid = () => {
    if (combustivel1 !== '' && combustivel2 !== '') {
      return true;
    } else {
      console.log(combustivel1);
      console.log(combustivel2);
    }
    return false;
  };

  return (
    <div className={styles.container}>
      <Box
        className={styles.box}
        sx={{ background: (theme) => theme.palette.background.paper }}
      >
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            variant="outlined"
            endIcon={<EditIcon />}
            onClick={editarDadosProjeto}
          >
            Editar Dados do Projeto
          </Button>
        </div>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Combustivel 1</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={combustivel1}
            label="Combustivel 1"
            onChange={(e) => setCombustivel1(e.target.value)}
            displayEmpty
          >
            {combustiveis.map((combustivel, index) => (
              <MenuItem key={index} value={index}>
                {combustivel.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Combustivel 2</InputLabel>
          <Select
            labelId="combustivel2"
            id="combustivel2"
            value={combustivel2}
            label="Combustivel 2"
            onChange={(e) => setCombustivel2(e.target.value)}
            displayEmpty
          >
            {combustiveis.map((combustivel, index) => (
              <MenuItem key={index} value={index}>
                {combustivel.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          disabled={!isCompararValid()}
          onClick={handleComparar}
        >
          Comparar Combustíveis
        </Button>

        <InputLabel id="Cards">Cadastro de Combustíveis</InputLabel>
        <div className={styles.containerCards}>
          {combustiveis.map((combustivel, index) => (
            <CombustivelCard
              key={index}
              combustivel={combustivel}
              setCombustivel2={setCombustivel2}
              setCombustiveis={setCombustiveis}
              indexCombustivel={index}
              combustiveis={combustiveis}
            />
          ))}

          <Paper
            className={styles.cardButton}
            elevation={2}
            onClick={() =>
              navigate('/comparativoCombustiveis/cadastroCombustivel')
            }
          >
            <Typography variant="h3">+</Typography>
          </Paper>
        </div>
      </Box>
    </div>
  );
}

function CombustivelCard({
  combustivel,
  setCombustivel2,
  indexCombustivel,
  setCombustiveis,
  combustiveis,
}) {
  let navigate = useNavigate();

  const handleClick = () => {
    setCombustivel2(indexCombustivel);
  };

  const handleDelete = () => {
    const combustiveisFiltrados = combustiveis.filter(
      (combustivel, index) => index !== indexCombustivel
    );
    setCombustiveis(combustiveisFiltrados);
    localStorage.setItem('combustiveis', JSON.stringify(combustiveisFiltrados));
  };

  const handleEdit = () => {
    navigate(
      `/comparativoCombustiveis/cadastroCombustivel/${indexCombustivel}`
    );
  };

  return (
    <Paper className={styles.card} elevation={2}>
      <div onClick={handleClick} className={styles.container}>
        <div className={styles.cardHeader}>
          <Typography variant="h6" color="primary">
            {combustivel.nome}
          </Typography>
        </div>
        <div className={styles.cardBody}>
          <div className={styles.content}>
            <span>PCI Sugerido: </span>
            <strong>
              {Number(combustivel.pci).toLocaleString(undefined)} kcal/
              {combustivel.metrica}
            </strong>
          </div>
          <div className={styles.content}>
            <span>Preco: </span>
            <strong>
              {Number(combustivel.custo).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              /{combustivel.metrica}
            </strong>
          </div>
          <div className={styles.content}>
            <span>Rendimento Térmico </span>
            <strong>{combustivel.rendimentoTermico} %</strong>
          </div>
        </div>
      </div>
      <Stack direction="row">
        <Button
          variant="contained"
          fullWidth
          onClick={handleDelete}
          sx={{ borderRadius: 0 }}
          color="error"
          disabled={indexCombustivel < 5 ? true : false}
        >
          Excluir
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleEdit}
          sx={{ borderRadius: 0 }}
        >
          Editar
        </Button>
      </Stack>
    </Paper>
  );
}
