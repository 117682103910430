import { api } from './api';

export async function createTask(data) {
  try {
    const resp = await api.post('/', data);
    return resp;
  } catch (e) {
    return e;
  }
}
