import { useState, useEffect } from 'react';

//MUI
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Slider,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { MaskedInput } from 'view/components/MaskedMuiInput';

//SCSS
import styles from '../../../styles/Calculo.module.scss';

//React Router
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

export default function BombaDeAgua() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  //Campos do formulário
  const [vazaoAgua, setVazaoAgua] = useState(0);
  const [pressaoAgua, setPressaoAgua] = useState(0);
  const [temperatura, setTemperatura] = useState(0);
  const [fatorSeguranca, setFatorSeguranca] = useState(1.2);
  const [eficiencia, setEficiencia] = useState(55);

  //Tooltip
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const temps = [100, 90, 80, 70, 60, 50, 40, 30, 25, 20, 15, 10, 5, 0];

  //Inicia campos com valor guardado no localStorage
  useEffect(() => {
    const bombaDeAgua = JSON.parse(localStorage.getItem('bombaDeAgua'));
    if (bombaDeAgua) {
      // const {
      //   vazaoAgua,
      //   pressaoAgua,
      //   temperatura,
      //   fatorSeguranca,
      //   eficiencia,
      // } = bombaDeAgua;

      // setVazaoAgua(vazaoAgua);
      // setPressaoAgua(pressaoAgua);
      // setTemperatura(temperatura);
      // setFatorSeguranca(fatorSeguranca);
      // setEficiencia(eficiencia * 100);

      Object.keys(bombaDeAgua).forEach((key) => {
        setValue(key, bombaDeAgua[key]);
      });
    }
  }, []);

  //Validação dos campos
  const isFormValid = () => {
    return vazaoAgua && pressaoAgua && fatorSeguranca && eficiencia;
  };

  //Calcula o valor do ventilador Ideal
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const data = {
  //     vazaoAgua: parseInt(vazaoAgua),
  //     pressaoAgua: parseInt(pressaoAgua),
  //     temperatura: parseInt(temperatura),
  //     fatorSeguranca: parseFloat(fatorSeguranca),
  //     eficiencia: parseFloat(eficiencia / 100),
  //   };

  //   localStorage.setItem('bombaDeAgua', JSON.stringify(data));
  //   navigate('resultado');
  // };

  const onSubmit = (data) => {
    localStorage.setItem('bombaDeAgua', JSON.stringify(data));
    navigate('resultado');
  };

  //Slider
  const marks = [
    {
      value: 40,
      label: '40%',
    },
    {
      value: 55,
      label: '55%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 80,
      label: '80%',
    },
  ];

  function valuetext(value) {
    return `${value}%`;
  }

  function handleChangeEficiencia(event, newValue) {
    setEficiencia(newValue);
  }

  return (
    <div className={styles.container}>
      <Paper elevation={0} className={styles.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {/* <Input
            value={vazaoAgua}
            onChange={(e) => setVazaoAgua(e.target.value)}
            label="Vazão de Água"
            metric="kg/h"
          /> */}
          <MaskedInput
            mask="decimalSeparatedNumber"
            label="Vazão de Água"
            sx={{ m: 1 }}
            {...register('vazaoAgua', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Kg/h</InputAdornment>
              ),
            }}
            error={errors.vazaoAgua}
            helperText={errors.vazaoAgua && 'Campo obrigatório'}
          />
          {/* <Input
            value={pressaoAgua}
            onChange={(e) => setPressaoAgua(e.target.value)}
            label="Pressão de Água"
            metric="kgf/cm²"
          /> */}
          <MaskedInput
            mask="decimalSeparatedNumber"
            label="Pressão de Água"
            sx={{ m: 1 }}
            {...register('pressaoAgua', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Kgf/cm²</InputAdornment>
              ),
            }}
            error={errors.pressaoAgua}
            helperText={errors.pressaoAgua && 'Campo obrigatório'}
          />
          {/* <Input
            value={temperatura}
            onChange={(e) => setTemperatura(e.target.value)}
            label="Temperatura Água"
            metric="g/cm³"
          /> */}

          <FormControl sx={{ m: 1 }} variant="outlined">
            <InputLabel id="temperatura">Temperatura Água</InputLabel>

            <Controller
              defaultValue={0}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="temperatura"
                  id="demo-simple-select"
                  label="Temperatura Água"
                >
                  {temps.map((temp) => (
                    <MenuItem key={temp} value={temp}>
                      {temp}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="temperatura"
              control={control}
            />
          </FormControl>

          {/* <Input
            value={fatorSeguranca}
            onChange={(e) => setFatorSeguranca(e.target.value)}
            label="Fator de segurança Vazão"
            metric=""
            tip="O valor padrão do fator de segurança é 1.2"
          /> */}
          <MaskedInput
            mask="currencyToFixed1"
            label="Fator de segurança Vazão"
            sx={{ m: 1 }}
            {...register('fatorSeguranca', { required: true })}
            helperText={
              errors.fatorSeguranca
                ? 'Campo obrigatório'
                : 'O valor padrão do fator de segurança é 1,2'
            }
            error={errors.fatorSeguranca}
          />

          <MaskedInput
            label="Eficiência da Bomba"
            sx={{ m: 1 }}
            mask="percent"
            {...register('eficiencia', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            error={errors.eficiencia}
            helperText={
              errors.eficiencia
                ? 'Campo obrigatório'
                : 'A eficiência ideal para a bomba é de 55% até 60%.'
            }
          />

          <Box sx={{ m: 1 }}>
            {/* <Stack direction="row" alignItems="center">
              <FormHelperText sx={{ m: 1 }} id="outlined-weight-helper-text">
                Eficiência do ventilador
              </FormHelperText>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="A eficiência ideal para a bomba é de 55% até 60%."
                  >
                    <Button onClick={handleTooltipOpen}>?</Button>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Stack>
            <Slider
              getAriaValueText={valuetext}
              step={1}
              aria-labelledby="input-slider"
              valueLabelDisplay="auto"
              marks={marks}
              value={eficiencia}
              onChange={handleChangeEficiencia}
              min={40}
              max={80}
            /> */}
            <Button
              //disabled={!isFormValid()}
              className={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              Calcular Bomba de Água Ideal
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}

function Input({ label, metric, value, onChange, tip }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ m: 1 }} variant="outlined">
      {!tip && (
        <FormHelperText id="outlined-weight-helper-text">
          {label}
        </FormHelperText>
      )}
      {tip && (
        <Stack direction="row" alignItems="center">
          <FormHelperText id="outlined-weight-helper-text">
            {label}
          </FormHelperText>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tip}
              >
                <Button onClick={handleTooltipOpen}>?</Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Stack>
      )}

      <OutlinedInput
        id="outlined-adornment-weight"
        endAdornment={<InputAdornment position="end">{metric}</InputAdornment>}
        value={value}
        onChange={onChange}
        aria-describedby="outlined-weight-helper-text"
      />
    </FormControl>
  );
}
