import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .inputBox {
    display: flex;
    align-items: flex-end;
    margin-top: 1rem;

    .metric {
      font-size: 1.2rem;
      margin-left: 1rem;
      font-weight: bold;
      color: gray;
    }

    input {
      border: none;
      border-bottom: 2px solid #bababa;
      width: 100%;

      font-size: 2.5rem;
      font-weight: 100;

      ::placeholder {
        color: #bababa;
      }

      &:focus {
        outline: none;
        border-bottom: 2px solid #ff3131;
      }
    }
  }

  .title {
    font-size: 1.2rem;
    font-weight: 600;
  }

  & + div {
    margin-top: 2rem;
  }
`;
