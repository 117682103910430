import { Outlet } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import { Container } from '../Eficiencia/styles';

export default function ConsumoVaporDesareador() {
  return (
    <Container>
      <Header title="Propiedades Do Vapor" />
      <Outlet />
    </Container>
  );
}
