import { useState, useCallback, useEffect } from 'react';

import Header from '../../../components/Header/Header';
import { Input } from '../../../components/Input';
import { MaskedInput } from '../../../components/MaskedInput';
import {
  Container,
  ContainerEficiencia,
  ContainerPasso3,
  Form,
  Label,
  Select,
  Stepper,
  TotalBox,
} from './styles';
import { useNavigate } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';

import { combustiveis } from '../../../../constantes/combustivel';
import { Paper, Typography } from '@mui/material';
import { entalpiaVapor } from 'constantes/entalpia.js';
import { formatNumber } from 'utils/formatNumber';

export function Eficiencia() {
  const [passo, setPasso] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setPasso(0);
  }, []);

  const passos = {
    0: <Passo1 setPasso={setPasso} passoAtual={0} />,
    // 1: <Passo2 setPasso={setPasso} passoAtual={1} />,
    1: <Passo3 setPasso={setPasso} passoAtual={1} />,
    2: <Passo4 setPasso={setPasso} passoAtual={2} />,
    3: <Passo5 setPasso={setPasso} passoAtual={3} />,
    // 4: <Passo6 setPasso={setPasso} passoAtual={4} />,
    4: <Passo7 setPasso={setPasso} passoAtual={4} navigate={navigate} />,
  };

  return (
    <>
      <ContainerEficiencia>
        <Header title="Cálculo de Eficiência" />
        {passos[passo]}
      </ContainerEficiencia>
    </>
  );
}

const handleProxPasso = (passo, data, setPasso, setEstado, navigate) => {
  const eficiencia = localStorage.getItem('eficiencia');

  if (eficiencia) {
    var oldInfos = JSON.parse(eficiencia);
    oldInfos[passo] = data;
    localStorage.setItem('eficiencia', JSON.stringify(oldInfos));
  } else {
    var obj = {};
    obj[passo] = data;
    localStorage.setItem('eficiencia', JSON.stringify(obj));
  }
  setEstado('saida');
  setTimeout(() => {
    if (passo === 4) {
      navigate('resultado');
    } else {
      setPasso(passo + 1);
    }
  }, 300);
};

const iniciarPasso = (passo, setValue) => {
  const eficiencia = JSON.parse(localStorage.getItem('eficiencia'));
  if (eficiencia) {
    const eficienciaAtual = eficiencia[passo];
    for (let key in eficienciaAtual) {
      setValue(key, eficienciaAtual[key]);
    }
  }
};

function Passo1({ setPasso, passoAtual }) {
  const { register, handleSubmit, watch, setValue, errors } = useForm();

  const [estado, setEstado] = useState('');

  const testaDisabled = () => {
    for (let key in watch()) {
      if (watch()[key] === '') {
        return false;
      }
    }
    return true;
  };

  const disabled = testaDisabled();

  const handleNextPasso = (data) => {
    handleProxPasso(passoAtual, data, setPasso, setEstado);
  };

  useEffect(() => {
    iniciarPasso(passoAtual, setValue);
    setEstado('entrada');
  }, []);

  const valorPressao = parseFloat(watch('pressao')) + 1;

  const entalpiaSaturado =
    !isNaN(valorPressao) && valorPressao < 61
      ? entalpiaVapor.filter(
          (item) => item.pressao_vapor === `${valorPressao}`
        )[0].entalpia_vapor_saturado
      : 'Pressão de trabalho não suportada';

  const temperaturaSaturado =
    !isNaN(valorPressao) && valorPressao < 61
      ? entalpiaVapor.filter(
          (item) => item.pressao_vapor === `${valorPressao}`
        )[0].temperatura_vapor_saturado
      : 'Pressão de trabalho não suportada';

  return (
    <Form className={estado}>
      <ComponentStepper passo={passoAtual} />

      <span className="steps">passo {passoAtual + 1} de 5</span>

      <Input
        title="Pressão máxima de trabalho"
        metric="Kgf/cm²"
        placeholder="00"
        //mask="decimalToFixed1"
        //type="tel"
        {...register('pressao')}
      />

      {/* <Input
        title="Vazão de Vapor"
        metric="Kg/h"
        placeholder="00,00"
        mask="currency"
        type="tel"
        {...register('vazaoVapor')}
      /> */}
      <Input
        title="Vazão de Vapor"
        metric="Kg/h"
        placeholder="0,000"
        mask="decimalToFixed3"
        type="tel"
        {...register('vazaoVapor')}
      />

      <Label>Vapor</Label>
      <Select name="tipoVapor" {...register('tipoVapor')}>
        <option value="superAquecido">Vapor SuperAquecido</option>
        <option value="vaporSaturado">Vapor Saturado</option>
      </Select>

      {watch('tipoVapor') === 'superAquecido' ? (
        <>
          <Label>Temperatura</Label>
          <Select name="temperatura" {...register('temperatura')}>
            <option value="320">320 °C</option>
            <option value="420">420 °C</option>
            <option value="450">450 °C</option>
          </Select>
        </>
      ) : (
        <>
          <TotalBox>
            <Typography variant="h6">Entalpia Saturado:</Typography>
            <Typography variant="h6">{entalpiaSaturado} Kcal/Kg</Typography>
          </TotalBox>
          <TotalBox>
            <Typography variant="h6">Temperatura Saturado:</Typography>
            <Typography variant="h6">{temperaturaSaturado} °C</Typography>
          </TotalBox>
        </>
      )}

      <div className="buttons">
        <button
          className="next"
          disabled={!disabled}
          type="button"
          onClick={handleSubmit(handleNextPasso)}
        >
          Próximo &gt;
        </button>
      </div>
    </Form>
  );
}

function Passo2({ setPasso, passoAtual }) {
  const { register, handleSubmit, watch, setValue } = useForm();

  const [estado, setEstado] = useState('');

  const testaDisabled = () => {
    for (let key in watch()) {
      if (watch()[key] === '') {
        return false;
      }
    }
    return true;
  };

  const disabled = testaDisabled();

  const handleNextPasso = (data) => {
    handleProxPasso(passoAtual, data, setPasso, setEstado);
  };

  useEffect(() => {
    iniciarPasso(passoAtual, setValue);
    setEstado('entrada');
  }, []);

  return (
    <Form className={estado}>
      <ComponentStepper passo={passoAtual} />

      <span className="steps">passo {passoAtual + 1} de 5</span>

      <Input
        title="Condensação"
        metric="%"
        placeholder="00%"
        mask="percent"
        type="tel"
        {...register('condensacao')}
      />

      <div className="buttons">
        <button className="back" onClick={() => setPasso(0)}>
          &lt; Voltar
        </button>
        <button
          className="next"
          disabled={!disabled}
          type="button"
          onClick={handleSubmit(handleNextPasso)}
        >
          Próximo &gt;
        </button>
      </div>
    </Form>
  );
}

function Passo3({ setPasso, passoAtual }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      carbono_umida: 0,
      hidrogenio_umida: 0,
      oxigenio_umida: 0,
      enxofre_umida: 0,
      nitrogenio_umida: 0,
      cinzas_umida: 0,
      umidade: 0,
    },
  });

  const [estado, setEstado] = useState('');
  const [disableUmidade, setDisableUmidade] = useState(false);

  // const disabled =
  //   watch().vazao && watch().tipoVapor && watch().temperaturaVapor;

  const handleNextPasso = (data) => {
    handleProxPasso(passoAtual, data, setPasso, setEstado);
  };

  useEffect(() => {
    iniciarPasso(passoAtual, setValue);
    setEstado('entrada');
  }, []);

  useEffect(() => {
    if (watch('combustivel') !== 'outro') {
      const combustivelAtual = combustiveis.filter(
        (combustivel) => combustivel.nome == watch('combustivel')
      )[0];

      setValue('carbono_seca', combustivelAtual.carbono);
      setValue('hidrogenio_seca', combustivelAtual.hidrogenio);
      setValue('oxigenio_seca', combustivelAtual.oxigenio);
      setValue('enxofre_seca', combustivelAtual.enxofre);
      setValue('nitrogenio_seca', combustivelAtual.nitrogenio);
      setValue('cinzas_seca', combustivelAtual.cinzas);
      setValue('carbono_umida', calcularUmidade(combustivelAtual.carbono));
      setValue(
        'hidrogenio_umida',
        calcularUmidade(combustivelAtual.hidrogenio)
      );
      setValue('oxigenio_umida', calcularUmidade(combustivelAtual.oxigenio));
      setValue('enxofre_umida', calcularUmidade(combustivelAtual.enxofre));
      setValue(
        'nitrogenio_umida',
        calcularUmidade(combustivelAtual.nitrogenio)
      );
      setValue('cinzas_umida', calcularUmidade(combustivelAtual.cinzas));
    } else {
      setValue('carbono_seca', 0);
      setValue('hidrogenio_seca', 0);
      setValue('oxigenio_seca', 0);
      setValue('enxofre_seca', 0);
      setValue('nitrogenio_seca', 0);
      setValue('cinzas_seca', 0);
    }

    if (
      ['GN', 'Óleo combustível 1A', 'Óleo Diesel', 'GLP'].includes(
        watch('combustivel')
      )
    ) {
      setValue('umidade', 0);
      setDisableUmidade(true);
    } else {
      setDisableUmidade(false);
    }
  }, [watch('combustivel'), watch('umidade')]);

  const totalSeca =
    (
      parseFloat(watch('carbono_seca')) +
      parseFloat(watch('hidrogenio_seca')) +
      parseFloat(watch('oxigenio_seca')) +
      parseFloat(watch('enxofre_seca')) +
      parseFloat(watch('nitrogenio_seca')) +
      parseFloat(watch('cinzas_seca'))
    ).toFixed(2) || 0;

  const totalUmida =
    (
      parseFloat(watch('carbono_umida')) +
        parseFloat(watch('hidrogenio_umida')) +
        parseFloat(watch('oxigenio_umida')) +
        parseFloat(watch('enxofre_umida')) +
        parseFloat(watch('nitrogenio_umida')) +
        parseFloat(watch('cinzas_umida')) +
        parseInt(watch('umidade')) || 0
    ).toFixed(2) || 0;

  const calcularUmidade = (value) => {
    const resp = value * (1 - parseInt(watch('umidade')) / 100);
    return resp.toFixed(2);
  };

  const pcsTeorico =
    (0.3491 * parseFloat(watch('carbono_seca')) +
      1.1783 * parseFloat(watch('hidrogenio_seca')) -
      0.1034 * parseFloat(watch('oxigenio_seca')) -
      0.0151 * parseFloat(watch('nitrogenio_seca')) -
      0.0211 * parseFloat(watch('cinzas_seca')) +
      0.1005 * parseFloat(watch('enxofre_seca'))) *
    239.005736;

  const pcsAtual = parseFloat(
    watch('pcs') ? watch('pcs').replace('.', '') : ''
  );
  const pcsBaseSeca = pcsAtual * 1.8;
  const pcsBaseUmida = pcsBaseSeca * (1 - parseInt(watch('umidade')) / 100);

  var H59 = parseFloat(pcsBaseUmida);
  const F24 = watch('tipo_combustivel') === 'solido_ou_liquido' ? 1 : 2;
  var H26 = parseFloat(watch('hidrogenio_umida'));
  var F20 = parseInt(watch('umidade'));
  var pciBtuPorLbs =
    H59 - ((2 - F24) * 1030 - (1 - F24) * 1060) * ((9 * H26 + F20) / 100);
  const pciKaclPorKg = pciBtuPorLbs / 1.8;

  return (
    <Form className={estado} onSubmit={handleSubmit(handleNextPasso)}>
      <ComponentStepper passo={passoAtual} />
      <span className="steps">passo {passoAtual + 1} de 5</span>
      <Label>Combustível</Label>
      <Controller
        name="combustivel"
        control={control}
        rules={{ required: true }}
        defaultValue={'GN'}
        render={({ field }) => (
          <Select {...field}>
            {combustiveis.map((combustivel) => {
              return (
                <option key={combustivel.nome} value={combustivel.nome}>
                  {combustivel.nome}
                </option>
              );
            })}
            <option value="outro">Outro</option>
          </Select>
        )}
      />

      {watch('combustivel') === 'outro' && (
        <>
          <Label>Tipo de Combustível</Label>
          <Controller
            name="tipo_combustivel"
            control={control}
            rules={{ required: true }}
            defaultValue={'solido_ou_liquido'}
            render={({ field }) => (
              <Select {...field}>
                <option value="solido_ou_liquido">Sólido e Líquido</option>
                <option value="gases">Gáses</option>
              </Select>
            )}
          />
        </>
      )}

      <Input
        title="Umidade do Combustível"
        metric="%"
        placeholder="00%"
        mask="percent"
        type="tel"
        disabled={disableUmidade}
        {...register('umidade')}
      />

      <ContainerPasso3>
        <div className="composicao">
          <span className="title">Composição</span>
          <span className="item">Carbono</span>
          <span className="item">Hidrogênio</span>
          <span className="item">Oxigênio</span>
          <span className="item">Enxofre</span>
          <span className="item">Nitrogênio</span>
          <span className="item">Cinzas</span>
          <span className="total">Total</span>
        </div>
        <div className={`base`}>
          <span className="title">Base Seca</span>
          <input type="number" step={0.01} {...register('carbono_seca')} />
          <input type="number" step={0.01} {...register('hidrogenio_seca')} />
          <input type="number" step={0.01} {...register('oxigenio_seca')} />
          <input type="number" step={0.01} {...register('enxofre_seca')} />
          <input type="number" step={0.01} {...register('nitrogenio_seca')} />
          <input type="number" step={0.01} {...register('cinzas_seca')} />
          <span className="total">{totalSeca}</span>
        </div>
        <div
          className={`base ${
            watch('tipo_base') !== 'baseUmida' ? 'disabled' : ''
          }`}
        >
          <span className="title">Base Umida</span>
          <input
            type="number"
            value={calcularUmidade(watch('carbono_seca'))}
            {...register('carbono_umida')}
            step={0.01}
          />
          <input
            type="number"
            value={calcularUmidade(watch('hidrogenio_seca'))}
            {...register('hidrogenio_umida')}
            step={0.01}
          />
          <input
            type="number"
            value={calcularUmidade(watch('oxigenio_seca'))}
            {...register('oxigenio_umida')}
            step={0.01}
          />
          <input
            type="number"
            value={calcularUmidade(watch('enxofre_seca'))}
            {...register('enxofre_umida')}
            step={0.01}
          />
          <input
            type="number"
            value={calcularUmidade(watch('nitrogenio_seca'))}
            {...register('nitrogenio_umida')}
            step={0.01}
          />
          <input
            type="number"
            value={calcularUmidade(watch('cinzas_seca'))}
            {...register('cinzas_umida')}
            step={0.01}
          />
          <span className="total">{totalUmida}</span>
        </div>
      </ContainerPasso3>
      {/* <Typography variant="h6">O total é igual 100.</Typography> */}
      <TotalBox>
        <Typography variant="h6">PCS teórico Base Seca:</Typography>
        <Typography variant="h6">
          {formatNumber(pcsTeorico.toFixed(0))} Kcal/Kg
        </Typography>
      </TotalBox>

      <span></span>

      <Input
        title="PCS - Base Seca"
        metric="Kcal/Kg"
        placeholder="0,000"
        mask="decimalToFixed3"
        type="tel"
        {...register('pcs', { required: 'Campo Obrigatório' })}
      />
      {errors.pcs && (
        <span
          style={{
            color: 'red',
            fontSize: '12px',
            marginTop: '10px',
          }}
        >
          {errors.pcs.message}
        </span>
      )}

      <TotalBox>
        <Typography variant="h6">PCI:</Typography>
        <Typography variant="h6">
          {formatNumber(pciKaclPorKg.toFixed(0))} Kcal/Kg
        </Typography>
      </TotalBox>

      <div className="buttons">
        <button className="back" onClick={() => setPasso(passoAtual - 1)}>
          &lt; Voltar
        </button>
        <button
          className="next"
          type="submit"
          // onClick={handleSubmit(handleNextPasso)}
        >
          Próximo &gt;
        </button>
      </div>
    </Form>
  );
}

function Passo4({ setPasso, passoAtual }) {
  const { register, handleSubmit, watch, setValue } = useForm();

  const [estado, setEstado] = useState('');

  const testaDisabled = () => {
    for (let key in watch()) {
      if (watch()[key] === '') {
        return false;
      }
    }
    return true;
  };

  const disabled = testaDisabled();

  const handleNextPasso = (data) => {
    handleProxPasso(passoAtual, data, setPasso, setEstado);
  };

  useEffect(() => {
    iniciarPasso(passoAtual, setValue);
    setEstado('entrada');
  }, []);

  return (
    <Form className={estado}>
      <ComponentStepper passo={passoAtual} />

      <span className="steps">passo {passoAtual + 1} de 5</span>

      {/* <Input
        title="PCS - Base Seca"
        metric="Kcal/Kg"
        placeholder="0,000"
        mask="decimalToFixed3"
        type="tel"
        {...register('pcs')}
      /> */}

      <Input
        title="Excesso de ar na fornalha"
        metric="%"
        placeholder="00"
        mask="percent"
        type="tel"
        {...register('excessoAr')}
      />

      <Input
        title="Altitude Local"
        metric="m"
        placeholder="000"
        mask="percent"
        type="tel"
        {...register('altitude')}
      />

      <div className="buttons">
        <button className="back" onClick={() => setPasso(passoAtual - 1)}>
          &lt; Voltar
        </button>
        <button
          className="next"
          disabled={!disabled}
          type="button"
          onClick={handleSubmit(handleNextPasso)}
        >
          Próximo &gt;
        </button>
      </div>
    </Form>
  );
}

function Passo5({ setPasso, passoAtual }) {
  const { register, handleSubmit, watch, setValue } = useForm();

  const [estado, setEstado] = useState('');
  const [disableOverfire, setDisableOverfire] = useState(false);

  const testaDisabled = () => {
    for (let key in watch()) {
      if (watch()[key] === '') {
        return false;
      }
    }
    return true;
  };

  const disabled = testaDisabled();

  const handleNextPasso = (data) => {
    handleProxPasso(passoAtual, data, setPasso, setEstado);
  };

  useEffect(() => {
    iniciarPasso(passoAtual, setValue);
    setEstado('entrada');
    verificaOverfire();
  }, []);

  const verificaOverfire = () => {
    const eficiencia = localStorage.getItem('eficiencia');
    if (eficiencia) {
      const resp = JSON.parse(eficiencia);
      if (
        ['GN', 'Óleo combustível 1A', 'Óleo Diesel', 'GLP'].includes(
          resp[1].combustivel
        )
      ) {
        setDisableOverfire(true);
        setValue('porcentagemOverfire', 0);
      }
    }
  };

  return (
    <Form className={estado}>
      <ComponentStepper passo={passoAtual} />

      <span className="steps">passo {passoAtual + 1} de 5</span>

      <Input
        title="Temperatura do combustível"
        metric="°C"
        placeholder="00"
        mask="percent"
        type="tel"
        {...register('temperaturaCombustivel')}
      />

      <Input
        title="Temperatura de entrada da Água"
        metric="°C"
        placeholder="00"
        mask="percent"
        type="tel"
        {...register('temperaturaEntradaAgua')}
      />

      <Input
        title="Temperatura de entrada do Ar"
        metric="°C"
        placeholder="00"
        mask="percent"
        type="tel"
        {...register('temperaturaEntradaAr')}
      />

      <Input
        title="Temperatura dos gases na saída da chaminé"
        metric="°C"
        placeholder="00"
        mask="percent"
        type="tel"
        {...register('temperaturaSaidaGases')}
      />

      <Input
        title="Porcentagem de overfire"
        metric="%"
        placeholder="00"
        mask="percent"
        type="tel"
        disabled={disableOverfire}
        {...register('porcentagemOverfire')}
      />

      <div className="buttons">
        <button className="back" onClick={() => setPasso(passoAtual - 1)}>
          &lt; Voltar
        </button>
        <button
          className="next"
          disabled={!disabled}
          type="button"
          onClick={handleSubmit(handleNextPasso)}
        >
          Próximo &gt;
        </button>
      </div>
    </Form>
  );
}

function Passo6({ setPasso, passoAtual }) {
  const { register, handleSubmit, watch, setValue } = useForm();

  const [estado, setEstado] = useState('');

  const testaDisabled = () => {
    for (let key in watch()) {
      if (watch()[key] === '') {
        return false;
      }
    }
    return true;
  };

  const disabled = testaDisabled();

  const handleNextPasso = (data) => {
    handleProxPasso(passoAtual, data, setPasso, setEstado);
  };

  useEffect(() => {
    iniciarPasso(passoAtual, setValue);
    setEstado('entrada');
  }, []);

  return (
    <Form className={estado}>
      <ComponentStepper passo={passoAtual} />

      <span className="steps">passo {passoAtual + 1} de 5</span>

      <Input
        title="Porcentagem de overfire"
        metric="%"
        placeholder="00"
        mask="percent"
        type="tel"
        {...register('porcentagemOverfire')}
      />

      {/* <Input
        title="Horas trabalhadas por dia"
        metric="h"
        placeholder="00"
        mask="percent"
        type="tel"
        {...register('horasTrabalhadasPorDia')}
      />

      <Input
        title="Dias Trabalhados por mês"
        metric="dias"
        placeholder="00"
        mask="percent"
        type="tel"
        {...register('diasTrabalhadosMes')}
      /> */}

      <div className="buttons">
        <button className="back" onClick={() => setPasso(passoAtual - 1)}>
          &lt; Voltar
        </button>
        <button
          className="next"
          disabled={!disabled}
          type="button"
          onClick={handleSubmit(handleNextPasso)}
        >
          Próximo &gt;
        </button>
      </div>
    </Form>
  );
}

function Passo7({ setPasso, passoAtual, navigate }) {
  const { register, handleSubmit, watch, setValue } = useForm();

  const [estado, setEstado] = useState('');
  const [showPerdasPorCombustivel, setShowPerdasPorCombustivel] =
    useState(true);

  const testaDisabled = () => {
    for (let key in watch()) {
      if (watch()[key] === '') {
        return false;
      }
    }
    return true;
  };

  const disabled = testaDisabled();

  const handleNextPasso = (data) => {
    handleProxPasso(passoAtual, data, setPasso, setEstado, navigate);
  };

  useEffect(() => {
    iniciarPasso(passoAtual, setValue);
    setEstado('entrada');
    verifyCombustivel();
  }, []);

  const combs = ['GN', 'GLP', 'Óleo Diesel', 'Óleo combustível 1A'];

  const verifyCombustivel = () => {
    const eficiencia = localStorage.getItem('eficiencia');
    if (eficiencia) {
      const resp = JSON.parse(eficiencia);
      combs.map((comb) => {
        if (resp[1].combustivel === comb) {
          setShowPerdasPorCombustivel(false);
          setValue('perdasNaoQueimado', '0');
        }
      });
    }
  };

  return (
    <Form className={estado}>
      <ComponentStepper passo={passoAtual} />

      <span className="steps">passo {passoAtual + 1} de 5</span>

      <Input
        title="Perdas por radiação e descarga de fundos"
        metric="%"
        placeholder="00"
        mask="currency"
        type="tel"
        {...register('perdasRadiacao')}
      />

      <Input
        title="Perdas por combustível não queimado"
        metric="%"
        placeholder="00"
        mask="currency"
        type="tel"
        disabled={!showPerdasPorCombustivel}
        {...register('perdasNaoQueimado')}
      />

      <Input
        title="Margem de Segurança"
        metric="%"
        placeholder="00"
        mask="currency"
        type="tel"
        {...register('margemFabricante')}
      />

      {showPerdasPorCombustivel && (
        <TotalBox>
          <Typography variant="h6">Sugestão: 1% em todos os campos!</Typography>
        </TotalBox>
      )}

      <div className="buttons">
        <button className="back" onClick={() => setPasso(passoAtual - 1)}>
          &lt; Voltar
        </button>
        <button
          className="next"
          disabled={!disabled}
          type="button"
          onClick={handleSubmit(handleNextPasso)}
        >
          Finalizar
        </button>
      </div>
    </Form>
  );
}

function ComponentStepper({ passo }) {
  return (
    <Stepper>
      <div className="wrapper-progressBar">
        <ul className="progressBar">
          {[0, 1, 2, 3, 4].map((item, index) => {
            if (item <= passo) {
              return <li className="active" key={index}></li>;
            }
            return <li key={index}></li>;
          })}
        </ul>
      </div>
    </Stepper>
  );
}
