import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
export const ContainerEficiencia = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div``;

export const Form = styled.form`
  background: white;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  transition: transform 0.3s ease-out;
  transform: translateX(0%);

  &.entrada {
    transform: translateX(0%);
  }

  &.saida {
    transform: translateX(-150%);
  }

  .steps {
    font-size: 0.8rem;
    font-weight: 600;
    color: #ff3131;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .back {
      background: white;
      border: none;
      height: 3rem;
      width: 10rem;

      font-size: 1rem;
      font-weight: 600;
    }

    .next {
      border-radius: 3rem;
      background: #ff3131;
      border: none;
      height: 3rem;
      width: 10rem;
      margin-left: auto;

      font-size: 1rem;
      color: white;
      font-weight: 600;
    }
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;

  .inputBox {
    display: flex;
    align-items: flex-end;
    margin-top: 1rem;

    .metric {
      font-size: 1.2rem;
      margin-left: 1rem;
      font-weight: bold;
      color: gray;
    }

    input {
      border: none;
      border-bottom: 2px solid #bababa;
      width: 100%;

      font-size: 2.5rem;
      font-weight: 100;

      ::placeholder {
        color: #bababa;
      }

      &:focus {
        outline: none;
        border-bottom: 2px solid #ff3131;
      }
    }
  }

  .title {
    font-size: 1.2rem;
    font-weight: 600;
  }

  & + div {
    margin-top: 2rem;
  }
`;

export const Stepper = styled.div`
  .wrapper-progressBar {
    position: relative;
    z-index: 1000;
    margin-bottom: 4rem;
  }

  .progressBar {
  }

  .progressBar li {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
  }

  .progressBar li:before {
    content: ' ';
    line-height: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: white;
  }

  .progressBar li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
  }

  .progressBar li:first-child:after {
    content: none;
  }

  .progressBar li.active {
    color: #ff3131;
  }

  .progressBar li.active:before {
    border-color: #ff3131;
    background-color: #ff3131;
  }

  .progressBar .active:after {
    background-color: #ff3131;
  }
`;

export const Select = styled.select`
  margin-bottom: 2rem;
  border: none;
  border-bottom: 2px solid #bababa;
  width: 100%;
  font-size: 2rem;
  font-weight: 100;
  color: black;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #ff3131;
  }
  &::placeholder {
    color: #bababa;
  }
  option {
    font-size: 1.5rem;
  }
`;

export const Label = styled.span`
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const ContainerPasso3 = styled.div`
  display: flex;

  .composicao {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 1rem;

    .title {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .total {
      font-size: 1.1rem;
      font-weight: 600;
      color: #ff3131;
    }

    .item {
      height: 29px;
    }
  }

  .base {
    //background: #ff3131;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    input {
      width: 100px;
      border: 1px solid gray;
      padding: 6px 12px;
      align-items: center;
      text-align: center;
      border-radius: 4px;
    }

    .title {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .total {
      font-size: 1.1rem;
      font-weight: 600;
      color: #ff3131;
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
`;

export const TotalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
  background: rgba(0, 0, 0, 0.2);
`;
