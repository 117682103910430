import { useState } from 'react';

import styles from './Login.module.scss';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

//Router
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <div className={styles.img}>
          <img src="assets/agua-home.webp" alt="caldeira" />
        </div>
        <div className={styles.loginContent}>
          <Typography variant="h4" component="h2">
            Login
          </Typography>
          <TextField
            id="outlined-basic"
            label="Usuário"
            variant="outlined"
            color="primary"
          />
          <PasswordField />
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate(`/home`)}
          >
            Entrar
          </Button>
          <div className={styles.bottom}>
            <InputCheckbox />
            <Link href="#" underline="none">
              Esqueceu sua senha?
            </Link>
          </div>
          <div className={styles.register}>
            <span>Ainda não tem conta?⠀</span>
            <Link href="#" underline="none">
              Cadastre-se
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

function PasswordField() {
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange('password')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Senha"
      />
    </FormControl>
  );
}

function InputCheckbox() {
  return (
    <FormControlLabel
      value="remember"
      control={<Checkbox />}
      label="Lembrar-me"
      labelPlacement="end"
    />
  );
}
