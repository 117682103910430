import { Outlet } from "react-router-dom";
import Header from "../../../components/Header/Header";
import { Container } from "../Eficiencia/styles";

export default function BombadeAgua() {
  return (
    <Container>
      <Header title="Cálculo de Bomba de Água" />
      <Outlet />
    </Container>
  );
}
