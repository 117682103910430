import {
  Button,
  CardMedia,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useState } from 'react';

import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { caldeiras } from 'constantes/caldeiras';

export default function Caldeiras() {
  const { caldeira, tipo } = useParams();
  const navigate = useNavigate();
  const [showLightbox, setShowLightbox] = useState(false);
  const [index, setIndex] = useState(0);

  const infos = caldeiras[tipo.replaceAll('-', '_')].find(
    (c) => c.nome === caldeira
  );

  const handleVoltar = () => {
    navigate(`/caldeiras/${tipo}`);
  };

  const handleBaixar = () => {
    window.open(infos.path_datasheet, '_blank');
  };

  const handlePedido = () => {
    navigate(`/contato/?caldeira=${caldeira}&tipo=Caldeira`);
  };

  return (
    <Paper style={{ flex: 1, padding: '1.5rem', borderRadius: 0 }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
        <IconButton onClick={handleVoltar} sx={{ alignSelf: 'flex-start' }}>
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography variant="h4" component="h1" gutterBottom>
          {infos.nome}
        </Typography>
        <Typography variant="h6" gutterBottom color="text.secondary">
          Informações sobre a JPO
        </Typography>
        <CardMedia
          component="img"
          sx={{ borderRadius: 3, width: '300px' }}
          image={infos.path_foto}
          alt="Foto principal da caldeira"
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={3}
        mt={2}
      >
        <Button onClick={handlePedido}>
          <Typography variant="h6" mr={1}>
            Faça seu pedido
          </Typography>
          <ShoppingCartIcon size="large" />
        </Button>
        <Button onClick={handleBaixar}>
          <Typography variant="h6" mr={1}>
            Download Datasheet
          </Typography>
          <PictureAsPdfIcon size="large" />
        </Button>
        <Typography
          variant="h6"
          gutterBottom
          color="text.primary"
          align="center"
          mt={3}
        >
          {infos.descricao}
        </Typography>
        {infos.descricao_completa.split('.').map((item) => (
          <>
            {item && (
              <Typography
                variant="h6"
                gutterBottom
                color="text.secondary"
                align="left"
                mt={3}
              >
                {item}.
              </Typography>
            )}
          </>
        ))}

        <Typography
          variant="h5"
          gutterBottom
          color="primary.main"
          align="left"
          mt={3}
          mb={3}
          alignSelf="flex-start"
        >
          Fotos
        </Typography>
        {showLightbox && (
          <Lightbox
            images={infos.fotos}
            onClose={() => setShowLightbox(false)}
            startIndex={index}
          />
        )}
        <ImageList cols={2} sx={{ aspectRatio: '3/1.3' }}>
          {infos.fotos.map((foto, index) => (
            <ImageListItem
              key={index}
              onClick={() => {
                setIndex(index);
                setShowLightbox(true);
              }}
            >
              <img
                src={foto.url}
                alt={foto.title}
                loading="lazy"
                onClick={() => {
                  setShowLightbox(true);
                  setIndex(index);
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Paper>
  );
}
