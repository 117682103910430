import { useState, useEffect } from 'react';

import styles from '../../../styles/Resultados.module.scss';
import Button from '@mui/material/Button';

import LinhaDeVapor from '../../../../Classes/linhaDeVapor';
import { useNavigate } from 'react-router-dom';
import ItemPrincipal from '../../../components/ItemPrincipal';
import ItemResultado from '../../../components/ItemResultado';
import { replaceDotAndComma } from 'utils/formatNumber';
import PopupPedido from 'view/components/PopupPedido';

export default function LinhaDeVaporResultados() {
  const navigate = useNavigate();

  const [carregando, setCarregando] = useState(true);
  const [resultados, setResultados] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const dados = JSON.parse(localStorage.getItem('linhaDeVapor'));

    if (dados) {
      const { vazaoVapor, pressaoVapor, velocidadeAdotada } = dados;

      const linhaDeVapor = new LinhaDeVapor(
        replaceDotAndComma(vazaoVapor),
        replaceDotAndComma(pressaoVapor),
        replaceDotAndComma(velocidadeAdotada)
      );

      const resultados = linhaDeVapor.getResultados();
      setResultados(resultados);
      setCarregando(false);
    }

    setTimeout(() => {
      setOpenPopup(true);
    }, 4000);
  }, []);

  const handleClick = () => {
    navigate('/linhaDeVapor');
  };

  return (
    <>
      <PopupPedido open={openPopup} handleClose={() => setOpenPopup(false)} />

      <div className={styles.container}>
        {!carregando && (
          <>
            <img src="/assets/smoke.jpg" alt="smoke" />

            <ItemPrincipal
              resultados={resultados}
              nome={resultados[0].nome}
              valor={resultados[0].valor}
              medida={resultados[0].medida}
              shareTitle="Resultados da Linha de Vapor"
            />

            <div className={styles.infos}>
              {resultados.map((item, index) => {
                if (index > 0) {
                  return (
                    <ItemResultado
                      key={index}
                      nome={item.nome}
                      valor={item.valor}
                      medida={item.medida}
                    />
                  );
                }
                return null;
              })}

              <Button onClick={handleClick} variant="contained">
                Recalcular
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
