import { formatNumber } from 'utils/formatNumber';

export default class DutoDeAr {
  constructor(vazaoArAmbiente, velocidadeDuto) {
    this.vazaoArAmbiente = vazaoArAmbiente;
    this.velocidadeDuto = velocidadeDuto;
  }

  getVazaoArSegundo() {
    return (this.vazaoArAmbiente / 3600).toFixed(2);
  }

  getArea() {
    return (this.getVazaoArSegundo() / this.velocidadeDuto).toFixed(2);
  }

  getRaio() {
    return Math.pow(this.getArea() / Math.PI, 0.5).toFixed(2);
  }

  getDiametro() {
    return (2 * this.getRaio()).toFixed(2);
  }

  getResultados() {
    const resultados = [
      {
        nome: 'Diâmetro Necessário para o Duto',
        valor: formatNumber(this.getDiametro()),
        medida: 'm',
      },
      {
        nome: 'Vazão de Ar Ambiente',
        valor: formatNumber(this.vazaoArAmbiente),
        medida: 'm³/h',
      },
      {
        nome: 'Velocidade Adotada no Duto',
        valor: formatNumber(this.velocidadeDuto),
        medida: 'm/s',
      },
      {
        nome: 'Vazão de Ar por Segundo',
        valor: formatNumber(this.getVazaoArSegundo()),
        medida: 'm³/s',
      },
      {
        nome: 'Área Necessária para Velocidade Adotada',
        valor: formatNumber(this.getArea()),
        medida: 'm²',
      },
      {
        nome: 'Raio Equivalente',
        valor: formatNumber(this.getRaio()),
        medida: 'm',
      },
    ];
    return resultados;
  }
}
