import { formatNumber } from 'utils/formatNumber';
const temp = {
  100: 958,
  90: 965,
  80: 971,
  70: 977,
  60: 983,
  50: 988,
  40: 992,
  30: 995,
  25: 997,
  20: 998,
  15: 999,
  10: 999,
  5: 999,
  0: 1000,
};

export default class LinhaDeAgua {
  constructor(vazaoAgua, temperaturaAgua, velocidadeAdotada) {
    this.vazaoAgua = vazaoAgua;
    this.temperaturaAgua = temperaturaAgua;
    this.velocidadeAdotada = velocidadeAdotada;
  }

  getDensidadeAgua() {
    return temp[this.temperaturaAgua];
  }

  getVazaoAgua() {
    return (this.vazaoAgua / this.getDensidadeAgua()).toFixed(2);
  }

  getVazaoAguaSegundo() {
    return (this.getVazaoAgua() / 3600).toFixed(3);
  }

  getArea() {
    return (this.getVazaoAguaSegundo() / this.velocidadeAdotada).toFixed(3);
  }

  getRaio() {
    return Math.pow(this.getArea() / Math.PI, 0.5).toFixed(3);
  }

  getDiametro() {
    return (this.getRaio() * 2000).toFixed(2);
  }

  getBitola() {
    return (this.getDiametro() / 25.4).toFixed(2);
  }

  getResultados() {
    const resultados = [
      {
        nome: 'Bitola',
        valor: formatNumber(this.getBitola()),
        medida: 'Pol',
      },
      {
        nome: 'Vazão de Água',
        valor: formatNumber(this.vazaoAgua),
        medida: 'kg/h',
      },
      {
        nome: 'Temperatura da Água',
        valor: formatNumber(this.temperaturaAgua),
        medida: '°C',
      },
      {
        nome: 'Densidade da Água na Temperatura',
        valor: formatNumber(this.getDensidadeAgua()),
        medida: 'kg/m³',
      },
      {
        nome: 'Vazão de Água Adotada',
        valor: formatNumber(this.getVazaoAgua()),
        medida: 'm³/h',
      },
      {
        nome: 'Vazão de Água por Segundo',
        valor: formatNumber(this.getVazaoAguaSegundo()),
        medida: 'm³/s',
      },
      {
        nome: 'Velocidade Adotada na Linha',
        valor: formatNumber(this.velocidadeAdotada),
        medida: 'm/s',
      },
      {
        nome: 'Área Necessária para Velocidade Adotada',
        valor: formatNumber(this.getArea()),
        medida: 'm²',
      },
      {
        nome: 'Raio Equivalente',
        valor: formatNumber(this.getRaio()),
        medida: 'm',
      },
      {
        nome: 'Diametro Interno Necessário para Tubulação',
        valor: formatNumber(this.getDiametro()),
        medida: 'mm',
      },
    ];
    return resultados;
  }
}
