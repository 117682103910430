export function cep(e) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
}

export function currency(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  e.currentTarget.value = value;
  return e;
}

export function currencyToFixed1(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d)$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  e.currentTarget.value = value;
  return e;
}

export function decimalToFixed3(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{3})$/, '$1.$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  e.currentTarget.value = value;
  return e;
}

export function decimalSeparatedNumber(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  //value = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
  value = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
  e.currentTarget.value = value;
  return e;
}

export function decimalToFixed1(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{1})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  e.currentTarget.value = value;
  return e;
}

export function percent(e) {
  e.currentTarget.maxLength = 3;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  e.currentTarget.value = value;
  return e;
}

export function decimalMask(e) {
  let value = e;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{3})$/, '$1.$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  return value;
}
