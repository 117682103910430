import { TextField } from '@mui/material';
import { forwardRef, useCallback } from 'react';

import {
  cep,
  currency,
  decimalSeparatedNumber,
  decimalToFixed1,
  percent,
  decimalToFixed3,
  currencyToFixed1,
} from '../MaskedInput/masks';

const MaskedInputBase = (props, ref) => {
  const { mask } = props;

  const functionMask = {
    cep,
    currency,
    decimalSeparatedNumber,
    decimalToFixed1,
    percent,
    decimalToFixed3,
    currencyToFixed1,
  };

  const handleKeyUp = useCallback((e) => {
    if (mask) functionMask[mask](e);
  }, []);

  const inputProps = {
    onKeyUp: handleKeyUp,
    ref,
  };

  return <TextField {...props} inputProps={inputProps} />;
};

export const MaskedInput = forwardRef(MaskedInputBase);
