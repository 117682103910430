import { useState, useEffect } from 'react';

//MUI
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

//SCSS
import styles from '../../../styles/Calculo.module.scss';

//React Router
import { useNavigate } from 'react-router-dom';
import { MaskedInput } from 'view/components/MaskedMuiInput';
import { useForm } from 'react-hook-form';

export default function DutoDeAr() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  //Campos do formulário
  const [vazaoArAmbiente, setVazaoArAmbiente] = useState(0);
  const [velocidadeDuto, setVelocidadeDuto] = useState(0);

  //Inicia campos com valor guardado no localStorage
  useEffect(() => {
    const dutoDeAr = JSON.parse(localStorage.getItem('dutoDeAr'));
    if (dutoDeAr) {
      // const { vazaoArAmbiente, velocidadeDuto } = dutoDeAr;

      // setVazaoArAmbiente(vazaoArAmbiente);
      // setVelocidadeDuto(velocidadeDuto);
      Object.keys(dutoDeAr).forEach((key) => {
        setValue(key, dutoDeAr[key]);
      });
    }
  }, []);

  //Validação dos campos
  const isFormValid = () => {
    return vazaoArAmbiente && velocidadeDuto;
  };

  const velocidadeIdeal = (velocidade) => {
    return velocidade <= 15;
  };

  //Calcula o valor do ventilador Ideal
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const data = {
  //     vazaoArAmbiente: parseInt(vazaoArAmbiente),
  //     velocidadeDuto: parseInt(velocidadeDuto),
  //   };

  //   localStorage.setItem('dutoDeAr', JSON.stringify(data));
  //   navigate('resultado');
  // };

  const onSubmit = (data) => {
    localStorage.setItem('dutoDeAr', JSON.stringify(data));
    navigate('resultado');
  };

  return (
    <div className={styles.container}>
      <Paper elevation={0} className={styles.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {/* <Input
            value={vazaoArAmbiente}
            onChange={(e) => setVazaoArAmbiente(e.target.value)}
            label="Vazão de Gases"
            metric="m³/h"
          /> */}
          <MaskedInput
            label="Vazão de Gases"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('vazaoArAmbiente', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">m³/h</InputAdornment>
              ),
            }}
            error={errors.vazaoArAmbiente}
            helperText={errors.vazaoArAmbiente && 'Campo obrigatório'}
          />
          {/* <Input
            value={velocidadeDuto}
            onChange={(e) => setVelocidadeDuto(e.target.value)}
            label="Velocidade Adotada no Duto"
            metric="m/s"
            tip="Velocidade Ideal nos dutos de ar é de 12 m/s"
            avisoVelocidade={velocidadeIdeal(velocidadeDuto)}
          /> */}
          <MaskedInput
            label="Velocidade Adotada no Duto"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('velocidadeDuto', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">m/s</InputAdornment>,
            }}
            error={errors.velocidadeDuto}
            helperText={
              errors.velocidadeDuto
                ? 'Campo obrigatório'
                : 'Velocidade Ideal nos dutos de ar é de 14 a 18 m/s'
            }
          />

          <Box sx={{ m: 1 }}>
            <Button
              //disabled={!isFormValid()}
              className={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              Calcular Diametro para Duto de Ar
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}

function Input({
  label,
  metric,
  value,
  onChange,
  tip,
  avisoVelocidade = true,
}) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ m: 1 }} variant="outlined">
      {!tip && (
        <FormHelperText id="outlined-weight-helper-text">
          {label}
        </FormHelperText>
      )}
      {tip && (
        <Stack direction="row" alignItems="center">
          <FormHelperText id="outlined-weight-helper-text">
            {label}
          </FormHelperText>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tip}
              >
                <Button onClick={handleTooltipOpen}>?</Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Stack>
      )}

      <OutlinedInput
        error={!avisoVelocidade}
        id="outlined-adornment-weight"
        endAdornment={<InputAdornment position="end">{metric}</InputAdornment>}
        value={value}
        onChange={onChange}
        aria-describedby="outlined-weight-helper-text"
      />
      {!avisoVelocidade && (
        <FormHelperText error id="outlined-weight-helper-text">
          Atenção a perda de carga para velocidades maiores que 15m/s
        </FormHelperText>
      )}
    </FormControl>
  );
}
