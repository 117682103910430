import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
//MUI
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

//SCSS
import styles from '../../../styles/Calculo.module.scss';

//React Router
import { useNavigate } from 'react-router-dom';
import { MaskedInput } from 'view/components/MaskedMuiInput';
import { formatNumber } from 'utils/formatNumber';

export default function DutoDeAr() {
  const navigate = useNavigate();

  useEffect(() => {
    const vaporDesareador = JSON.parse(localStorage.getItem('vaporDesareador'));
    if (vaporDesareador) {
      Object.keys(vaporDesareador).forEach((key) => {
        setValue(key, vaporDesareador[key]);
      });
    }
  }, []);

  const onSubmit = (data) => {
    localStorage.setItem('vaporDesareador', JSON.stringify(data));
    navigate('resultado');
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const retorno =
      parseFloat(watch('vazaoAgua').replace('.', '')) *
      (parseFloat(watch('retornoCondensadoPorcentagem')) / 100);
    if (!isNaN(retorno) && retorno) {
      setValue('retornoCondensado', formatNumber(retorno));
    }
  }, [watch('vazaoAgua'), watch('retornoCondensadoPorcentagem')]);

  return (
    <div className={styles.container}>
      <Paper elevation={0} className={styles.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {/* <Input
            label="Vazão de Água Desareada"
            metric="Litros/h"
            register={register}
            name="vazaoAgua"
            error={errors.vazaoAgua}
          /> */}
          <MaskedInput
            label="Vazão de Água Desareada"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('vazaoAgua', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Litros/hora</InputAdornment>
              ),
            }}
            error={errors.vazaoAgua}
            helperText={errors.vazaoAgua && 'Campo obrigatório'}
          />
          {/* <Input
            label="Porcentagem Retorno Condensado"
            metric="%"
            register={register}
            name="retornoCondensadoPorcentagem"
            error={errors.retornoCondensadoPorcentagem}
          /> */}
          <MaskedInput
            label="Porcentagem Retorno Condensado"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('retornoCondensadoPorcentagem', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            error={errors.retornoCondensadoPorcentagem}
            helperText={
              errors.retornoCondensadoPorcentagem && 'Campo obrigatório'
            }
          />
          {/* <Input
            label="Retorno Condensado"
            metric="Litros/h"
            register={register}
            name="retornoCondensado"
            error={errors.retornoCondensado}
          /> */}
          <MaskedInput
            label="Retorno Condensado"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('retornoCondensado', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Litros/hora</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            error={errors.retornoCondensado}
            helperText={errors.retornoCondensado && 'Campo obrigatório'}
          />
          {/* <Input
            label="Pressão do Vapor de Entrada"
            metric="kgf/cm²"
            register={register}
            name="pressaoVaporEntrada"
            error={errors.pressaoVaporEntrada}
          /> */}
          <MaskedInput
            label="Pressão do Vapor de Entrada"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('pressaoVaporEntrada', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">kgf/cm²</InputAdornment>
              ),
            }}
            error={errors.pressaoVaporEntrada}
            helperText={errors.pressaoVaporEntrada && 'Campo obrigatório'}
          />
          {/* <Input
            label="Temperatura do Desareador"
            metric="°C"
            register={register}
            name="temperaturaDesareador"
            error={errors.temperaturaDesareador}
          /> */}
          <MaskedInput
            label="Temperatura do Desareador"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('temperaturaDesareador', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
            error={errors.temperaturaDesareador}
            helperText={errors.temperaturaDesareador && 'Campo obrigatório'}
          />
          {/* <Input
            label="Temperatura do Condesado"
            metric="°C"
            register={register}
            name="temperaturaCondensado"
            error={errors.temperaturaCondensado}
          /> */}
          <MaskedInput
            label="Temperatura do Condesado"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('temperaturaCondensado', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
            error={errors.temperaturaCondensado}
            helperText={errors.temperaturaCondensado && 'Campo obrigatório'}
          />
          {/* <Input
            label="Temperatura da Água de Alimentação"
            metric="°C"
            register={register}
            name="temperaturaAguaAlimentacao"
            error={errors.temperaturaAguaAlimentacao}
          /> */}
          <MaskedInput
            label="Temperatura da Água de Alimentação"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('temperaturaAguaAlimentacao', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
            error={errors.temperaturaAguaAlimentacao}
            helperText={
              errors.temperaturaAguaAlimentacao && 'Campo obrigatório'
            }
          />

          <Box sx={{ m: 1 }}>
            <Button
              className={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              Calcular Consumo do Vapor Desareador
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}

function Input({ label, metric, value, onChange, tip, register, name, error }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ m: 1 }} variant="outlined">
      {!tip && (
        <FormHelperText id="outlined-weight-helper-text">
          {label}
        </FormHelperText>
      )}
      {tip && (
        <Stack direction="row" alignItems="center">
          <FormHelperText id="outlined-weight-helper-text">
            {label}
          </FormHelperText>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tip}
              >
                <Button onClick={handleTooltipOpen}>?</Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Stack>
      )}

      <OutlinedInput
        id="outlined-adornment-weight"
        endAdornment={<InputAdornment position="end">{metric}</InputAdornment>}
        value={value}
        onChange={onChange}
        aria-describedby="outlined-weight-helper-text"
        {...register(name, { required: true })}
        error={error}
        type="number"
      />
      {error && (
        <FormHelperText error id="outlined-weight-helper-text">
          Campo obrigatório!
        </FormHelperText>
      )}
    </FormControl>
  );
}
