import {
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { caldeiras } from 'constantes/caldeiras';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatNumber } from 'utils/formatNumber';

export default function Caldeiras() {
  const { tipo } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const [anchorEl, setAnchorEl] = useState(null);
  const [filtrado, setFiltrado] = useState(false);

  const [listCaldeiras, setListCaldeiras] = useState(
    caldeiras[tipo.replaceAll('-', '_')]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const tipos = {
    oleo_e_gas: 'Óleo e Gás',
    biomassa: 'Biomassa',
  };

  const handleVoltar = () => {
    navigate('/caldeiras');
  };

  const onSubmit = (data) => {
    const { min_vazao, max_vazao, min_pressao, max_pressao } = data;
    setListCaldeiras(
      caldeiras[tipo.replaceAll('-', '_')].filter((caldeira) => {
        return (
          caldeira.capacidade.max >= (min_vazao ? parseInt(min_vazao) : 0) &&
          caldeira.capacidade.max <=
            (max_vazao ? parseInt(max_vazao) : 100000000000) &&
          caldeira.pressao.max >= (min_pressao ? parseInt(min_pressao) : 0) &&
          caldeira.pressao.max <=
            (max_pressao ? parseInt(max_pressao) : 100000000000)
        );
      })
    );
    setFiltrado(true);
    handleClose();
  };

  const limparFiltros = () => {
    setListCaldeiras(caldeiras[tipo.replaceAll('-', '_')]);
    setFiltrado(false);
  };

  return (
    <Paper style={{ flex: 1, padding: '1.5rem', borderRadius: 0 }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
        <IconButton onClick={handleVoltar} sx={{ alignSelf: 'flex-start' }}>
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography variant="h4" component="h1" gutterBottom>
          {tipos[tipo.replaceAll('-', '_')]}
        </Typography>
        <Typography variant="h6" gutterBottom color="text.secondary">
          Escolha a caldeira ideal
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Button onClick={limparFiltros}>
            {filtrado && 'Limpar Filtros'}
          </Button>
          <IconButton aria-describedby={id} onClick={handleClick}>
            <FilterAltIcon color="primary" />
          </IconButton>
        </Stack>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack p={2} spacing={2}>
              <Typography variant="h6" gutterBottom>
                Filtros
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vazão de vapor
              </Typography>
              <Stack spacing={2} direction="row">
                <TextField
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label="Min."
                  fullWidth
                  {...register('min_vazao')}
                />
                <TextField
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label="Max."
                  fullWidth
                  {...register('max_vazao')}
                />
              </Stack>
              <Typography variant="body1" gutterBottom>
                Pressão de vapor
              </Typography>
              <Stack spacing={2} direction="row">
                <TextField
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label="Min."
                  fullWidth
                  {...register('min_pressao')}
                />
                <TextField
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label="Max."
                  fullWidth
                  {...register('max_pressao')}
                />
              </Stack>

              <Button variant="contained" type="submit">
                Aplicar Filtros
              </Button>
            </Stack>
          </form>
        </Popover>
      </Box>
      <Stack spacing={2}>
        {listCaldeiras.map((caldeira) => (
          <CardCaldeira key={caldeira.id} caldeira={caldeira} />
        ))}
      </Stack>
    </Paper>
  );
}

function CardCaldeira({ caldeira }) {
  const navigate = useNavigate();
  return (
    <Button
      sx={{
        display: 'block',
        padding: 0,
        textTransform: 'none',
      }}
      onClick={() => navigate(caldeira.nome)}
    >
      <Card sx={{ display: 'flex' }} elevation={5}>
        <CardMedia
          component="img"
          sx={{ width: 130 }}
          image={caldeira.path_foto}
          alt="Caldeira"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
              {caldeira.nome}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
              sx={{ overflow: 'ellipsis' }}
              gutterBottom
            >
              {/* {caldeira.descricao} */}
              <b>Capacidade:</b> {formatNumber(caldeira.capacidade.min)} a{' '}
              {formatNumber(caldeira.capacidade.max)} de vapor/h
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
              sx={{ maxHeight: 35, overflow: 'ellipsis' }}
            >
              <b>Pressão</b> de Trabalho até{' '}
              {caldeira.pressao.min
                ? `${caldeira.pressao.min} / ${caldeira.pressao.max}`
                : caldeira.pressao.max}{' '}
              BAR
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Button>
  );
}
