export const datasheets = [
  {
    id: 1,
    nome: 'JPO',
    url: '/datasheets/JPO-R1.pdf',
    pdf: 'JPO-R1.pdf',
  },
  {
    id: 2,
    nome: 'DF',
    url: '/datasheets/DF-R1.pdf',
    pdf: 'DF-R1.pdf',
  },
  {
    id: 3,
    nome: 'Four',
    url: '/datasheets/FOUR-R1.pdf',
    pdf: 'FOUR-R1.pdf',
  },
  {
    id: 4,
    nome: 'VMO-S',
    url: '/datasheets/VMO-S-R1.pdf',
    pdf: 'VMO-S-R1.pdf',
  },
  {
    id: 5,
    nome: 'VMI',
    url: '/datasheets/VMI-R1.pdf',
    pdf: 'VMI-R1.pdf',
  },
  {
    id: 6,
    nome: 'JPD',
    url: '/datasheets/JPD-R1.pdf',
    pdf: 'JPD-R1.pdf',
  },
  {
    id: 7,
    nome: 'VME-RG',
    url: '/datasheets/VME-RG-R1.pdf',
    pdf: 'VME-RG-R1.pdf',
  },
  {
    id: 8,
    nome: 'VMIC-RG',
    url: '/datasheets/VMIC-RG-R1.pdf',
    pdf: 'VMIC-RG-R1.pdf',
  },
  {
    id: 9,
    nome: 'VMFI',
    url: '/datasheets/VMFI-R1.pdf',
    pdf: 'VMFI-R1.pdf',
  },
  {
    id: 10,
    nome: 'VMU',
    url: '/datasheets/VMU-R1.pdf',
    pdf: 'VMU-R1.pdf',
  },
];
