import { useState, useEffect } from 'react';

import styles from '../../../styles/Resultados.module.scss';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';

import BombaDeAgua from '../../../../Classes/bombaDeAgua';
import { useNavigate } from 'react-router-dom';

import ShareButton from '../../../components/ShareButton';
import ItemResultado from '../../../components/ItemResultado';
import ItemPrincipal from '../../../components/ItemPrincipal';
import { replaceDotAndComma } from 'utils/formatNumber';
import PopupPedido from 'view/components/PopupPedido';

export default function BombaDeAguaResultados() {
  const navigate = useNavigate();

  const [carregando, setCarregando] = useState(true);
  const [resultados, setResultados] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const dados = JSON.parse(localStorage.getItem('bombaDeAgua'));

    if (dados) {
      const {
        vazaoAgua,
        pressaoAgua,
        temperatura,
        fatorSeguranca,
        eficiencia,
      } = dados;

      const bombaDeAgua = new BombaDeAgua(
        replaceDotAndComma(vazaoAgua),
        replaceDotAndComma(pressaoAgua),
        replaceDotAndComma(`${temperatura}`),
        replaceDotAndComma(fatorSeguranca),
        replaceDotAndComma(eficiencia) / 100
      );

      const resultados = bombaDeAgua.getResultados();

      setResultados(resultados);
      setCarregando(false);
    }

    setTimeout(() => {
      setOpenPopup(true);
    }, 4000);
  }, []);

  const handleClick = () => {
    navigate('/bombaDeAgua');
  };

  return (
    <>
      <PopupPedido open={openPopup} handleClose={() => setOpenPopup(false)} />

      <div className={styles.container}>
        {!carregando && (
          <>
            <img src="/assets/smoke.jpg" alt="smoke" />

            <ItemPrincipal
              nome={resultados[0].nome}
              valor={resultados[0].valor}
              medida={resultados[0].medida}
              resultados={resultados}
              shareTitle="Resultados da Bomba de Água"
            />

            <div className={styles.infos}>
              {resultados.map((resultado, index) => {
                if (index > 0) {
                  return (
                    <ItemResultado
                      key={index}
                      nome={resultado.nome}
                      valor={resultado.valor}
                      medida={resultado.medida}
                    />
                  );
                }
                return null;
              })}

              <Button onClick={handleClick} variant="contained">
                Recalcular
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
