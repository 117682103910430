import { useState, useEffect } from 'react';

import styles from '../../../styles/Resultados.module.scss';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';

import ItemPrincipal from '../../../components/ItemPrincipal';
import ItemResultado from '../../../components/ItemResultado';

import Combustivel from '../../../../Classes/combustivel';
import DadosProjeto from '../../../../Classes/dadosProjeto';
import { formatNumber } from 'utils/formatNumber';

export default function ComparativoCustoCombustivelResultados() {
  const navigate = useNavigate();

  const [combustivel1, setCombustivel1] = useState();
  const [combustivel2, setCombustivel2] = useState();
  const [dadosProjeto, setDadosProjeto] = useState();
  const [carregando, setCarregando] = useState(true);

  const [resultados, setResultados] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const combustiveis = JSON.parse(localStorage.getItem('combustiveis'));
    const combustiveisSelecionados = JSON.parse(
      localStorage.getItem('combustiveisSelecionados')
    );
    const dadosProjeto = JSON.parse(localStorage.getItem('dadosProjeto'));

    if (combustiveis && combustiveisSelecionados && dadosProjeto) {
      const dadosCombustivel1 =
        combustiveis[combustiveisSelecionados.combustivel1];
      const dadosCombustivel2 =
        combustiveis[combustiveisSelecionados.combustivel2];

      const projeto = new DadosProjeto(
        parseFloat(dadosProjeto.producaoVapor.replace('.', '')),
        parseFloat(dadosProjeto.temperaturaEntrada.replace('.', '')),
        parseFloat(dadosProjeto.pressaoMaxima.replace('.', '')),
        parseFloat(dadosProjeto.horasTrabalhadas.replace('.', '')),
        dadosCombustivel1.rendimentoTermico / 100,
        dadosCombustivel2.rendimentoTermico / 100
      );

      setDadosProjeto(projeto);

      const combustivel1 = new Combustivel(
        dadosCombustivel1.pci,
        dadosCombustivel1.custo,
        dadosCombustivel1.rendimentoTermico / 100,
        projeto.getCargaTermicaCombustivel1(),
        parseFloat(dadosProjeto.horasTrabalhadas.replace('.', ''))
      );

      setCombustivel1(combustivel1);

      const combustivel2 = new Combustivel(
        dadosCombustivel2.pci,
        dadosCombustivel2.custo,
        dadosCombustivel2.rendimentoTermico / 100,
        projeto.getCargaTermicaCombustivel2(),
        parseFloat(dadosProjeto.horasTrabalhadas.replace('.', ''))
      );

      setCombustivel2(combustivel2);

      setCarregando(false);

      setResultados([
        {
          nome: `Economia anual utilizando ${dadosCombustivel1.nome} ao invés de ${dadosCombustivel2.nome}`,
          valor: `R$ ${formatNumber(
            combustivel2.getCustoAnual() - combustivel1.getCustoAnual()
          )}`,
          medida: '',
        },
        {
          nome: `Economia por mês usando ${dadosCombustivel1.nome}`,
          valor: `R$ ${formatNumber(
            combustivel2.getCustoMensal() - combustivel1.getCustoMensal()
          )}`,
          medida: '',
        },
        {
          nome: `Economia por dia usando ${dadosCombustivel1.nome}`,
          valor: `R$ ${formatNumber(
            combustivel2.getCustoCombustivel24hrs() -
              combustivel1.getCustoCombustivel24hrs()
          )}`,
          medida: '',
        },
        {
          nome: dadosCombustivel1.nome,
        },
        {
          nome: `PCI ${dadosCombustivel1.nome}`,
          valor: formatNumber(dadosCombustivel1.pci),
          medida: `kcal/${dadosCombustivel1.metrica}`,
        },
        {
          nome: `Consumo máximo ${dadosCombustivel1.nome}`,
          valor: formatNumber(combustivel1.getConsumoMaximo()),
          medida: `${dadosCombustivel1.metrica}/h`,
        },
        {
          nome: `Custo ${dadosCombustivel1.nome}`,
          valor: `${formatNumber(dadosCombustivel1.custo)} R$/${
            dadosCombustivel1.metrica
          }`,
          medida: '',
        },
        {
          nome: `Custo diário ${dadosCombustivel1.nome}`,
          valor: `R$ ${formatNumber(combustivel1.getCustoCombustivel24hrs())}`,
          medida: '',
        },
        {
          nome: `Custo mensal ${dadosCombustivel1.nome}`,
          valor: `R$ ${formatNumber(combustivel1.getCustoMensal())}`,
          medida: '',
        },
        {
          nome: `Custo anual ${dadosCombustivel1.nome}`,
          valor: `R$ ${formatNumber(combustivel1.getCustoAnual())}`,
          medida: '',
        },
        {
          nome: `Rendimento térmico ${dadosCombustivel1.nome}`,
          valor: formatNumber(dadosCombustivel1.rendimentoTermico),
          medida: '%',
        },
        {
          nome: `Carga térmica ${dadosCombustivel1.nome}`,
          valor: formatNumber(projeto.getCargaTermicaCombustivel1()),
          medida: 'kcal/h',
        },
        {
          nome: dadosCombustivel2.nome,
        },
        {
          nome: `PCI ${dadosCombustivel2.nome}`,
          valor: formatNumber(dadosCombustivel2.pci),
          medida: `kcal/${dadosCombustivel2.metrica}`,
        },
        {
          nome: `Consumo máximo ${dadosCombustivel2.nome}`,
          valor: formatNumber(combustivel2.getConsumoMaximo()),
          medida: `${dadosCombustivel2.metrica}/h`,
        },
        {
          nome: `Custo ${dadosCombustivel2.nome}`,
          valor: `${formatNumber(dadosCombustivel2.custo)} R$/${
            dadosCombustivel2.metrica
          }`,
          medida: '',
        },
        {
          nome: `Custo diário ${dadosCombustivel2.nome}`,
          valor: `R$ ${formatNumber(combustivel2.getCustoCombustivel24hrs())}`,
          medida: '',
        },
        {
          nome: `Custo mensal ${dadosCombustivel2.nome}`,
          valor: `R$ ${formatNumber(combustivel2.getCustoMensal())}`,
          medida: '',
        },
        {
          nome: `Custo anual ${dadosCombustivel2.nome}`,
          valor: `R$ ${formatNumber(combustivel2.getCustoAnual())}`,
          medida: '',
        },
        {
          nome: `Rendimento térmico ${dadosCombustivel2.nome}`,
          valor: formatNumber(dadosCombustivel2.rendimentoTermico),
          medida: '%',
        },
        {
          nome: `Carga térmica ${dadosCombustivel2.nome}`,
          valor: formatNumber(projeto.getCargaTermicaCombustivel2()),
          medida: 'kcal/h',
        },
        {
          nome: 'Dados do Projeto',
        },
        {
          nome: 'Produção Vapor',
          valor: formatNumber(dadosProjeto.producaoVapor),
          medida: 'Kg/h',
        },
        {
          nome: 'Temperatura de entrada',
          valor: formatNumber(dadosProjeto.temperaturaEntrada),
          medida: '°C',
        },
        {
          nome: 'Pressão Máxima de Operação (PMO)',
          valor: formatNumber(dadosProjeto.pressaoMaxima),
          medida: '',
        },
        {
          nome: 'Pressão máxima de trabalho permitida (PMTA)',
          valor: formatNumber(projeto.getPressaoMaximaPermitida()),
          medida: 'Kgf/cm² (g)',
        },
        {
          nome: 'Pressão de teste hidrostático',
          valor: formatNumber(projeto.getPressaoTesteHidrostatico()),
          medida: 'Kgf/cm² (g)',
        },
        {
          nome: 'Capacidade térmica (output)',
          valor: formatNumber(projeto.getCapacidadeTermica()),
          medida: 'Kcal/h',
        },
        {
          nome: 'Entalpia do vapor',
          valor: formatNumber(projeto.getEntalpiaVapor()),
          medida: 'Kcal/Kg',
        },
        {
          nome: 'Horas trabalhadas por dia',
          valor: formatNumber(projeto.horasTrabalhadas),
          medida: 'horas',
        },
      ]);
    }
  }, []);

  const handleClick = () => {
    navigate('/comparativoCombustiveis');
  };

  return (
    <div className={styles.container}>
      {!carregando && (
        <>
          <img src="/assets/smoke.jpg" alt="smoke" />

          <ItemPrincipal
            nome={resultados[0].nome}
            valor={resultados[0].valor}
            medida=""
            resultados={resultados}
            shareTitle={resultados[0].nome}
          />

          <div className={styles.infos}>
            {resultados.map((item, index) => {
              if (index > 0) {
                return (
                  <ItemResultado
                    key={index}
                    nome={item.nome}
                    valor={item.valor}
                    medida={item.medida}
                  />
                );
              } else return null;
            })}

            <Button onClick={handleClick} variant="contained">
              Recalcular
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
