import Fab from '@mui/material/Fab';
import ShareIcon from '@mui/icons-material/Share';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Box } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function ShareButton({ resultados, title }) {
  const table = resultados.map((resultado) => {
    if (resultado.valor) {
      return [
        resultado.nome,
        { text: `${resultado.valor} ${resultado.medida}`, style: 'valor' },
      ];
    } else {
      return [resultado.nome, ''];
    }
  });

  const dimensions = [595.28, 841.89];

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  const gerarPdf = async (baixar = false) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    //image: await getBase64ImageFromURL('/assets/agua-home.webp'),
    const docDefinition = {
      header: {
        image: await getBase64ImageFromURL('/assets/agua-home.webp'),
        width: dimensions[0],
        height: 200,
        margin: [0, 0, 0, 0],
      },
      content: [
        {
          text: title,
          fontSize: 20,
          bold: true,
          alignment: 'center',
          margin: [0, 210, 0, 20],
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: table,
          },
          layout: {
            fillColor: function (rowIndex) {
              return rowIndex === 0 ? '#CCCCCC' : null;
            },
          },
        },
      ],
      styles: {
        valor: {
          alignment: 'center',
        },
      },
    };

    const pdf = pdfMake.createPdf(docDefinition);
    if (baixar) {
      pdf.download(`${title}.pdf`);
    } else {
      pdf.getBuffer(function (buffer) {
        const blob = new Blob([buffer], { type: 'application/pdf' });
        share(blob, title);
      });
    }
  };

  const share = async (shareFile, sharetitle) => {
    try {
      const file = new File([shareFile], `${title}.pdf`, {
        type: shareFile.type,
      });

      await navigator.share({
        title: sharetitle,
        files: [file],
      });
    } catch (err) {
      console.log(err.name, err.message);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        right: '1rem',
        bottom: '1rem',
      }}
    >
      <Fab aria-label="add" onClick={() => gerarPdf()}>
        <ShareIcon fontSize="large" />
      </Fab>
      <Fab sx={{ ml: 1 }} aria-label="add" onClick={() => gerarPdf(true)}>
        <ArrowDownwardIcon fontSize="large" />
      </Fab>
    </Box>
  );
}
