import { useState, useEffect } from 'react';

import styles from '../../../styles/Resultados.module.scss';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';

import Ventilador from '../../../../Classes/ventilador';
import { useNavigate } from 'react-router-dom';

import ShareButton from '../../../components/ShareButton';
import ItemResultado from '../../../components/ItemResultado';
import ItemPrincipal from '../../../components/ItemPrincipal';
import PopupPedido from 'view/components/PopupPedido';
import { replaceDotAndComma } from 'utils/formatNumber';

export default function VentiladorResultados() {
  const navigate = useNavigate();

  const [resultados, setResultados] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const dados = JSON.parse(localStorage.getItem('ventilador'));

    if (dados) {
      const {
        temperaturaAr,
        contraPressao,
        quantidadeGas,
        fatorSegurancaPressao,
        fatorSegurancaGas,
        eficiencia,
      } = dados;

      const ventilador = new Ventilador(
        replaceDotAndComma(temperaturaAr),
        replaceDotAndComma(contraPressao),
        replaceDotAndComma(quantidadeGas),
        replaceDotAndComma(fatorSegurancaPressao),
        replaceDotAndComma(fatorSegurancaGas),
        replaceDotAndComma(eficiencia) / 100
      );

      const resultados = ventilador.getResultados();

      setResultados(resultados);
      setCarregando(false);
    }

    setTimeout(() => {
      setOpenPopup(true);
    }, 4000);
  }, []);

  const formatNumbers = (number) => {
    return parseFloat(number.replace('.', '').replace(',', '.'));
  };

  const handleClick = () => {
    navigate('/ventiladorIdeal');
  };

  const handlePedido = () => {
    navigate('/contato?tipo=ventilador');
  };

  return (
    <>
      <PopupPedido open={openPopup} handleClose={() => setOpenPopup(false)} />
      <div className={styles.container}>
        {!carregando && resultados && (
          <>
            <img src="/assets/smoke.jpg" alt="smoke" />

            <ItemPrincipal
              nome={resultados[0].nome}
              valor={resultados[0].valor}
              medida={resultados[0].medida}
              resultados={resultados}
              shareTitle="Resultados do Ventilador"
            />

            <div className={styles.infos}>
              {resultados.map((item, index) => {
                if (index > 0) {
                  return (
                    <ItemResultado
                      key={index}
                      nome={item.nome}
                      valor={item.valor}
                      medida={item.medida}
                    />
                  );
                }
                return null;
              })}

              <Button
                onClick={handlePedido}
                variant="contained"
                color="success"
              >
                Fazer pedido
              </Button>
              <Button onClick={handleClick} variant="contained">
                Recalcular
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
