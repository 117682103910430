import { useState, useEffect } from 'react';

import {
  Button,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import styles from '../../../styles/Calculo.module.scss';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';

export default function CadastrarCombustivel() {
  let navigate = useNavigate();
  let { index } = useParams();

  const [nome, setNome] = useState('');
  const [pci, setPci] = useState('');
  const [custo, setCusto] = useState('');
  const [rendimentoTermico, setRendimentoTermico] = useState('');

  const [metrica, setMetrica] = useState('');

  useEffect(() => {
    if (index) {
      const combustivel = JSON.parse(localStorage.getItem('combustiveis'))[
        index
      ];
      setNome(combustivel.nome);
      setPci(combustivel.pci);
      setCusto(`${combustivel.custo}`.replace('.', ','));
      setRendimentoTermico(combustivel.rendimentoTermico);
      setMetrica(combustivel.metrica);
    }
  }, []);

  const metricas = ['Nm³', 'Litro', 'Kg'];

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = localStorage.getItem('combustiveis');

    if (index) {
      const combustiveis = JSON.parse(data);
      combustiveis[index] = {
        nome,
        pci,
        custo: parseFloat(custo.replace(',', '.')),
        rendimentoTermico,
        metrica: metrica,
      };
      localStorage.setItem('combustiveis', JSON.stringify(combustiveis));
    } else {
      if (data) {
        const dataParsed = JSON.parse(data);
        localStorage.setItem(
          'combustiveis',
          JSON.stringify([
            ...dataParsed,
            {
              nome,
              pci: parseFloat(pci),
              custo: parseFloat(custo.replace(',', '.')),
              rendimentoTermico: parseFloat(rendimentoTermico),
              metrica: metrica,
            },
          ])
        );
      } else {
        localStorage.setItem(
          'combustiveis',
          JSON.stringify([
            {
              nome,
              pci: parseFloat(pci),
              custo: parseFloat(custo),
              rendimentoTermico: parseFloat(rendimentoTermico),
              metrica: parseFloat(metrica),
            },
          ])
        );
      }
    }
    navigate('/comparativoCombustiveis');
  };

  const isFormValid = () => {
    return nome && pci && custo && rendimentoTermico;
  };

  return (
    <div className={styles.container}>
      <Paper elevation={0} className={styles.paper}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <Input
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            label="Nome Combustível"
          />

          <Box sx={{ m: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="metrica-label">Métrica</InputLabel>
              <Select
                labelId="metrica"
                id="metrica"
                value={metrica}
                label="Métrica"
                onChange={(e) => setMetrica(e.target.value)}
                displayEmpty
              >
                {metricas.map((metrica, index) => (
                  <MenuItem key={index} value={metrica}>
                    {metrica}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Input
            value={pci}
            onChange={(e) => setPci(e.target.value)}
            label="PCI adotado"
            metric={`kcal/${metrica}`}
          />
          <Input
            value={custo}
            onChange={(e) => setCusto(e.target.value)}
            label="Custo do combustível"
            metric={`R$/${metrica}`}
          />
          <Input
            value={rendimentoTermico}
            onChange={(e) => setRendimentoTermico(e.target.value)}
            label="Rendimento térmico do combustível"
            metric="%"
          />

          <Box sx={{ m: 1 }}>
            <Button
              disabled={!isFormValid()}
              className={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              {index ? 'Editar Combustível' : 'Cadastrar Combustível'}
            </Button>
            <Button
              className={styles.button}
              onClick={() => navigate('/comparativoCombustiveis')}
              fullWidth
            >
              Voltar
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}

//TODO: Transformar em um componente
function Input({ label, metric, value, onChange, tip }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ m: 1 }} variant="outlined">
      {!tip && (
        <FormHelperText id="outlined-weight-helper-text">
          {label}
        </FormHelperText>
      )}
      {tip && (
        <Stack direction="row" alignItems="center">
          <FormHelperText id="outlined-weight-helper-text">
            {label}
          </FormHelperText>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tip}
              >
                <Button onClick={handleTooltipOpen}>?</Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Stack>
      )}

      <OutlinedInput
        id="outlined-adornment-weight"
        endAdornment={<InputAdornment position="end">{metric}</InputAdornment>}
        value={value}
        onChange={onChange}
        aria-describedby="outlined-weight-helper-text"
      />
    </FormControl>
  );
}
