import { forwardRef, useCallback } from "react";

import {
  cep,
  currency,
  decimalSeparatedNumber,
  decimalToFixed1,
  percent,
  decimalToFixed3,
} from "./masks";

const MaskedInputBase = (props, ref) => {
  const { mask } = props;

  const functionMask = {
    cep,
    currency,
    decimalSeparatedNumber,
    decimalToFixed1,
    percent,
    decimalToFixed3,
  };

  const handleKeyUp = useCallback((e) => {
    if (mask) functionMask[mask](e);
  }, []);

  return <input {...props} onKeyUp={handleKeyUp} ref={ref} />;
};

export const MaskedInput = forwardRef(MaskedInputBase);
