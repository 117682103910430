import { Outlet } from "react-router-dom";
import Header from "../../../components/Header/Header";
import { Container } from "../Eficiencia/styles";

export default function DutoDeAr() {
  return (
    <Container>
      <Header title="Cálculo de Duto de Ar" />
      <Outlet />
    </Container>
  );
}
