import { formatNumber } from 'utils/formatNumber';
import { entalpiaVapor as entalpia } from '../constantes/entalpia';

export default class PropiedadesVapor {
  constructor(pressaoVapor) {
    this.pressaoVapor = pressaoVapor;
    this.pressao = parseInt(pressaoVapor) + 1;
  }

  getTemperaturaVaporSaturado() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .temperatura_vapor_saturado
        : 'Pressão Inválida';
    return resultado;
  }

  getDensidadeVaporSaturado() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .densidade_vapor_saturado
        : 'Pressão Inválida';
    return resultado;
  }

  getEntalpiaVaporSaturado() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .entalpia_vapor_saturado
        : 'Pressão Inválida';
    return resultado;
  }

  getDensidadeVaporAquecido320() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .densidade_vapor_saturado_320
        : 'Pressão Inválida';
    return resultado;
  }

  getEntalpiaVaporAquecido320() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .entalpia_vapor_saturado_320
        : 'Pressão Inválida';
    return resultado;
  }

  getDensidadeVaporAquecido420() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .densidade_vapor_saturado_420
        : 'Pressão Inválida';
    return resultado;
  }

  getEntalpiaVaporAquecido420() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .entalpia_vapor_saturado_420
        : 'Pressão Inválida';
    return resultado;
  }

  getDensidadeVaporAquecido450() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .densidade_vapor_saturado_450
        : 'Pressão Inválida';
    return resultado;
  }

  getEntalpiaVaporAquecido450() {
    const resultado =
      !isNaN(this.pressao) && this.pressao < 61 && this.pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${this.pressao}`)[0]
            .entalpia_vapor_saturado_450
        : 'Pressão Inválida';
    return resultado;
  }

  getResultados() {
    const resultados = [
      {
        nome: 'Pressão do Vapor',
        valor: formatNumber(this.pressaoVapor),
        medida: 'Kgf/cm²',
      },
      {
        nome: 'Temperatura do Vapor Saturado',
        valor: formatNumber(this.getTemperaturaVaporSaturado()),
        medida: 'ºC',
      },
      {
        nome: 'Densidade do Vapor Saturado',
        valor: formatNumber(this.getDensidadeVaporSaturado()),
        medida: 'Kg/m³',
      },
      {
        nome: 'Entalpia do Vapor Saturado',
        valor: formatNumber(this.getEntalpiaVaporSaturado()),
        medida: 'Kcal/kg',
      },
      {
        nome: 'Densidade do Vapor Superaquecido 320 °C',
        valor: formatNumber(this.getDensidadeVaporAquecido320()),
        medida: 'Kg/m³',
      },
      {
        nome: 'Entalpia do Vapor Superaquecido 320 °C',
        valor: formatNumber(this.getEntalpiaVaporAquecido320()),
        medida: 'Kcal/kg',
      },
      {
        nome: 'Densidade do Vapor Superaquecido 420 °C',
        valor: formatNumber(this.getDensidadeVaporAquecido420()),
        medida: 'Kg/m³',
      },
      {
        nome: 'Entalpia do Vapor Superaquecido 420 °C',
        valor: formatNumber(this.getEntalpiaVaporAquecido420()),
        medida: 'Kcal/kg',
      },
      {
        nome: 'Densidade do Vapor Superaquecido 450 °C',
        valor: formatNumber(this.getDensidadeVaporAquecido450()),
        medida: 'Kg/m³',
      },
      {
        nome: 'Entalpia do Vapor Superaquecido 450 °C',
        valor: formatNumber(this.getEntalpiaVaporAquecido450()),
        medida: 'Kcal/kg',
      },
    ];
    return resultados;
  }
}
