import { useEffect } from 'react';

//MUI
import { Button, InputAdornment, Paper } from '@mui/material';
import { Box } from '@mui/system';

//SCSS
import styles from '../../../styles/Calculo.module.scss';

//React Router
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MaskedInput } from 'view/components/MaskedMuiInput';

export default function VentiladorIdeal() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const linhaDeVapor = JSON.parse(localStorage.getItem('linhaDeVapor'));
    if (linhaDeVapor) {
      Object.keys(linhaDeVapor).forEach((key) => {
        setValue(key, linhaDeVapor[key]);
      });
    }
  }, []);

  const onSubmit = (data) => {
    localStorage.setItem('linhaDeVapor', JSON.stringify(data));
    navigate('resultado');
  };

  return (
    <div className={styles.container}>
      <Paper elevation={0} className={styles.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <MaskedInput
            label="Vazão de Vapor"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('vazaoVapor', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Kg/h</InputAdornment>
              ),
            }}
            error={errors.vazaoVapor}
            helperText={errors.vazaoVapor && 'Campo obrigatório'}
          />

          <MaskedInput
            label="Pressão do Vapor Saturado na Pressão"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('pressaoVapor', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Kgf/cm²</InputAdornment>
              ),
            }}
            error={errors.pressaoVapor}
            helperText={errors.pressaoVapor && 'Campo obrigatório'}
          />

          <MaskedInput
            label="Velocidade Adotada na Linha"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('velocidadeAdotada', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">m/s</InputAdornment>,
            }}
            error={errors.velocidadeAdotada}
            helperText={
              errors.velocidadeAdotada
                ? 'Campo obrigatório'
                : 'Velocidade Recomendada para Vapor Saturado 25 a 35 m/s'
            }
          />

          <Box sx={{ m: 1 }}>
            <Button
              className={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              Calcular Bitola da Linha de Vapor
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}
