import styles from '../../styles/Resultados.module.scss';

export default function ItemResultado({ nome, valor, medida }) {
  return (
    <div className={styles.item}>
      {(valor || valor === 0) && <div className={styles.box}></div>}
      <div className={styles.text}>{nome}</div>
      <div className={styles.value}>
        {valor} {medida}
      </div>
    </div>
  );
}
