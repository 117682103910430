import { formatNumber } from 'utils/formatNumber';

const densidadeVapor = {
  1: 0.59,
  2: 1.13,
  3: 1.65,
  4: 2.16,
  5: 2.67,
  6: 3.17,
  7: 3.67,
  8: 4.16,
  9: 4.65,
  10: 5.15,
  11: 5.64,
  12: 6.13,
  13: 6.61,
  14: 7.1,
  15: 7.6,
  16: 8.08,
  17: 8.57,
  18: 9.06,
  19: 9.55,
  20: 10.04,
  21: 10.53,
  22: 11.03,
  23: 11.52,
  24: 12.01,
  25: 12.51,
  26: 13.0,
  27: 13.5,
  28: 14.0,
  29: 14.5,
  30: 15.0,
  31: 15.5,
  32: 16.01,
  33: 16.51,
  34: 17.02,
  35: 17.53,
  36: 18.04,
  37: 18.7,
  38: 19.2,
  39: 19.71,
  40: 20.22,
  41: 20.72,
  42: 21.23,
  43: 21.75,
  44: 22.26,
  45: 22.78,
  46: 23.29,
  47: 23.81,
  48: 24.33,
  49: 24.86,
  50: 25.38,
  51: 25.91,
  52: 26.44,
  53: 26.97,
  54: 27.5,
  55: 28.03,
  56: 28.57,
  57: 29.11,
  58: 29.65,
  59: 30.2,
  60: 30.74,
};

export default class LinhaDeVapor {
  constructor(vazaoVapor, pressaoVapor, velocidadeAdotada) {
    this.vazaoVapor = vazaoVapor;
    this.pressaoVapor = pressaoVapor;
    this.velocidadeAdotada = velocidadeAdotada;
  }

  getDensidadeVapor() {
    return densidadeVapor[this.pressaoVapor + 1];
  }

  getVazao() {
    return (this.vazaoVapor / this.getDensidadeVapor()).toFixed(1);
  }

  getVazaoSegundo() {
    return (this.getVazao() / 3600).toFixed(3);
  }

  getArea() {
    return (this.getVazaoSegundo() / this.velocidadeAdotada).toFixed(3);
  }

  getRaio() {
    return Math.pow(this.getArea() / Math.PI, 0.5).toFixed(3);
  }

  getDiametro() {
    return (this.getRaio() * 2000).toFixed(2);
  }

  getBitola() {
    return (this.getDiametro() / 25.4).toFixed(2);
  }

  getResultados() {
    const resultados = [
      {
        nome: 'Bitola',
        valor: formatNumber(this.getBitola()),
        medida: 'Pol',
      },
      {
        nome: 'Vazão de Vapor',
        valor: formatNumber(this.vazaoVapor),
        medida: 'kg/h',
      },
      {
        nome: 'Pressão do Vapor Saturado',
        valor: formatNumber(this.pressaoVapor),
        medida: 'kgf/cm²',
      },
      {
        nome: 'Densidade do Vapor Saturado na Pressão',
        valor: formatNumber(this.getDensidadeVapor()),
        medida: 'kg/m³',
      },
      {
        nome: 'Vazão de Vapor Adotado',
        valor: formatNumber(this.getVazao()),
        medida: 'm³/h',
      },
      {
        nome: 'Vazão de Vapor Adotado por Segundo',
        valor: formatNumber(this.getVazaoSegundo()),
        medida: 'm³/s',
      },
      {
        nome: 'Velocidade Adotada na Linha',
        valor: formatNumber(this.velocidadeAdotada),
        medida: 'm/s',
      },
      {
        nome: 'Área Necessária para Velocidade Adotada',
        valor: formatNumber(this.getArea()),
        medida: 'm²',
      },
      {
        nome: 'Raio Equivalente',
        valor: formatNumber(this.getRaio()),
        medida: 'm',
      },
      {
        nome: 'Diametro Interno Necessário para Tubulação',
        valor: formatNumber(this.getDiametro()),
        medida: 'mm',
      },
    ];
    return resultados;
  }
}
