import { formatNumber } from 'utils/formatNumber';
import { entalpiaVapor as entalpia } from '../constantes/entalpia';

export default class VaporDesareador {
  constructor(
    vazaoAgua,
    retornoCondensadoPorcentagem,
    retornoCondensado,
    pressaoVaporEntrada,
    temperaturaDesareador,
    temperaturaCondensado,
    temperaturaAguaAlimentacao
  ) {
    this.C4 = vazaoAgua;
    this.C5 = retornoCondensadoPorcentagem;
    this.C6 = retornoCondensado;
    this.C7 = pressaoVaporEntrada;
    this.C8 = temperaturaDesareador;
    this.C9 = temperaturaCondensado;
    this.C10 = temperaturaAguaAlimentacao;
  }

  getCalorNecessarioAquecerMakeUp() {
    const resultado = (this.C4 - this.C6) * (this.C8 - this.C10);
    return resultado.toFixed(2);
  }

  getCalorNecessarioAquecerCondensado() {
    const resultado = this.C6 * (this.C8 - this.C9);
    return resultado.toFixed(2);
  }

  getCalorTotalNecessario() {
    const C11 = parseFloat(this.getCalorNecessarioAquecerCondensado());
    const C12 = parseFloat(this.getCalorNecessarioAquecerMakeUp());
    const resultado = C12 + C11;
    return resultado.toFixed(2);
  }

  getEntalpiaVapor() {
    const pressao = parseInt(this.C7) + 1;

    const resultado =
      !isNaN(pressao) && pressao < 61 && pressao > 0
        ? entalpia.filter((item) => item.pressao_vapor === `${pressao}`)[0]
            .entalpia_vapor_saturado
        : 'Pressão Inválida';
    return resultado;
  }

  getSaltoEntalpicoVapor() {
    const C14 = parseFloat(this.getEntalpiaVapor());
    const resultado = C14 - this.C8;
    return resultado.toFixed(2);
  }

  getQuantidadeVaporNecessario() {
    const C13 = parseFloat(this.getCalorTotalNecessario());
    const C15 = parseFloat(this.getSaltoEntalpicoVapor());
    const resultado = C13 / C15;
    return resultado.toFixed(2);
  }

  getResultados() {
    const resultados = [
      {
        nome: 'Quantidade de Vapor Necessário',
        valor: formatNumber(this.getQuantidadeVaporNecessario()),
        medida: 'Kgv/h',
      },
      {
        nome: 'Calor Necessário para Aquecer Make-up',
        valor: formatNumber(this.getCalorNecessarioAquecerMakeUp()),
        medida: 'Kcal/h',
      },
      {
        nome: 'Calor Necessário para Aquecer Condensado',
        valor: formatNumber(this.getCalorNecessarioAquecerCondensado()),
        medida: 'Kcal/h',
      },
      {
        nome: 'Calor Total Necessário',
        valor: formatNumber(this.getCalorTotalNecessario()),
        medida: 'Kcal/h',
      },
      {
        nome: 'Entalpia de Vapor',
        valor: formatNumber(this.getEntalpiaVapor()),
        medida: 'Kcal/kg',
      },
      {
        nome: 'Salto Entalpico do Vapor',
        valor: formatNumber(this.getSaltoEntalpicoVapor()),
        medida: 'Kcal/kg',
      },
      {
        nome: 'Vazão de Água Desaerada Necessária',
        valor: formatNumber(this.C4),
        medida: 'Litros/h',
      },
      {
        nome: 'Retorno de Condensado',
        valor: formatNumber(this.C5),
        medida: '%',
      },
      {
        nome: 'Retorno de Condensado',
        valor: formatNumber(this.C6),
        medida: 'Litros/h',
      },
      {
        nome: 'Pressão do Vapor de Entrada',
        valor: formatNumber(this.C7),
        medida: 'Kgf/cm²',
      },
      {
        nome: 'Temperatura do Desaerador',
        valor: formatNumber(this.C8),
        medida: '°C',
      },
      {
        nome: 'Temperatura do Condensado',
        valor: formatNumber(this.C9),
        medida: '°C',
      },
      {
        nome: 'Temperatura da Água de Alimentação',
        valor: formatNumber(this.C10),
        medida: '°C',
      },
    ];

    return resultados;
  }
}
