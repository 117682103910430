import { Box, Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

export default function Caldeiras() {
  return (
    <Paper style={{ flex: 1, padding: '2rem' }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Caldeiras
        </Typography>
        <Typography variant="h6" gutterBottom color="text.secondary">
          Escolha o tipo de combustível
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <CardEstilzido tipoCombustivel="Óleo e Gás" rota="oleo-e-gas" />
        <CardEstilzido tipoCombustivel="Biomassa" rota="biomassa" />
      </Grid>
    </Paper>
  );
}

function CardEstilzido({ tipoCombustivel, rota }) {
  const navigate = useNavigate();
  return (
    <Grid item xs={6}>
      <Card
        sx={{ minHeight: 130 }}
        elevation={5}
        className={styles.card}
        onClick={() => navigate(`${rota}`)}
      >
        <CardContent className={styles.cardContent}>
          <CheckCircleIcon sx={{ color: 'primary.main' }} />
          <Typography sx={{ fontSize: 18 }} align="center" bold="true" mt={1}>
            {tipoCombustivel}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
