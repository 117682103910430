import { useState, useEffect } from 'react';

import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { useSwitchTheme } from '../../../Context/SwitchTheme';

import MenuIcon from '@mui/icons-material/Menu';
import NightlightIcon from '@mui/icons-material/Nightlight';
import Brightness4Icon from '@mui/icons-material/Brightness4';

import { useLocation, useNavigate } from 'react-router-dom';
import MenuDrawerLateral from '../MenuDrawerLateral/MenuDrawerLateral';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EmailIcon from '@mui/icons-material/Email';
import { useOpenSideBar } from 'Context/OpenSidebar';

export default function Header({ title }) {
  // const [state, setState] = useState(false);

  const { state, setState } = useOpenSideBar();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  //const [title, setTitle] = useState("Home");
  const navigate = useNavigate();

  const { theme, setTheme } = useSwitchTheme();

  return (
    <>
      <AppBar position="relative" sx={{ backgroundColor: 'primary.main' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton color="inherit" onClick={() => navigate('/contato')}>
            {/* {theme === 'light' ? <NightlightIcon /> : <Brightness4Icon />} */}
            {/* <ShoppingCartIcon /> */}
            <EmailIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MenuDrawerLateral
        anchor="left"
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      />
    </>
  );
}
