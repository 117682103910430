export default class Combustivel {
  constructor(pci, custo, rendimentoTermico, cargaTermica, horasTrabalhadas) {
    this.pci = pci;
    this.custo = custo;
    this.rendimentoTermico = rendimentoTermico;
    this.cargaTermica = cargaTermica;
    this.horasTrabalhadas = horasTrabalhadas;
  }

  getConsumoMaximo() {
    const resultado = this.cargaTermica / this.pci;
    return resultado.toFixed(2);
  }

  getCustoCombustivel24hrs() {
    const resultado =
      this.getConsumoMaximo() * this.custo * this.horasTrabalhadas;
    return resultado.toFixed(2);
  }

  getCustoMensal() {
    const resultado = this.getCustoCombustivel24hrs() * 30;
    return resultado.toFixed(2);
  }

  getCustoAnual() {
    const resultado = this.getCustoMensal() * 12;
    return resultado.toFixed(2);
  }
}
