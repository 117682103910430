import { useState, useEffect } from 'react';

//MUI
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

//SCSS
import styles from '../../../styles/Calculo.module.scss';

//React Router
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { MaskedInput } from 'view/components/MaskedMuiInput';

export default function LinhaDeAgua() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  //Campos do formulário
  const [vazaoAgua, setVazaoAgua] = useState(0);
  const [temperaturaAgua, setTemperaturaAgua] = useState(0);
  const [velocidadeAdotada, setVelocidadeAdotada] = useState(0);
  const [tipoTubulacao, setTipoTubulacao] = useState(1);

  //Inicia campos com valor guardado no localStorage
  useEffect(() => {
    const linhaDeAgua = JSON.parse(localStorage.getItem('linhaDeAgua'));
    if (linhaDeAgua) {
      // const { vazaoAgua, temperaturaAgua, velocidadeAdotada, tipoTubulacao } =
      //   linhaDeAgua;

      // setVazaoAgua(vazaoAgua);
      // setTemperaturaAgua(temperaturaAgua);
      // setVelocidadeAdotada(velocidadeAdotada);
      // setTipoTubulacao(tipoTubulacao);
      Object.keys(linhaDeAgua).forEach((key) => {
        setValue(key, linhaDeAgua[key]);
      });
    }
  }, []);

  //Validação dos campos
  const isFormValid = () => {
    return vazaoAgua && temperaturaAgua && velocidadeAdotada;
  };

  //Calcula o valor do ventilador Ideal
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const data = {
  //     vazaoAgua: parseFloat(vazaoAgua),
  //     temperaturaAgua: parseFloat(temperaturaAgua),
  //     velocidadeAdotada: parseFloat(velocidadeAdotada),
  //     tipoTubulacao: parseInt(tipoTubulacao),
  //   };

  //   localStorage.setItem('linhaDeAgua', JSON.stringify(data));
  //   navigate('resultado');
  // };

  const onSubmit = (data) => {
    localStorage.setItem('linhaDeAgua', JSON.stringify(data));
    navigate('resultado');
  };

  const handleChangeTipoTubulacao = (event) => {
    setTipoTubulacao(event.target.value);
    if (event.target.value === 2) setVelocidadeAdotada(2.5);
    else setVelocidadeAdotada(1.5);
  };

  const velocidadeIdeal = () => {
    if (tipoTubulacao === 1) {
      return 'Velocidade Recomendada para Vapor Saturado 1 a 1,5 m/s';
    } else {
      return 'Velocidade Recomendada para Vapor Saturado 2 a 2,5 m/s';
    }
  };

  return (
    <div className={styles.container}>
      <Paper elevation={0} className={styles.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <FormControl sx={{ m: 1 }} variant="outlined">
            <InputLabel id="temperatura">Tipo de Tubulação</InputLabel>

            <Controller
              defaultValue={1}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="temperatura"
                  id="demo-simple-select"
                  label="Tipo de Tubulação"
                >
                  <MenuItem value={1}>Sucção</MenuItem>
                  <MenuItem value={2}>Recalque</MenuItem>
                </Select>
              )}
              name="tipoTubulacao"
              control={control}
            />
          </FormControl>

          {/* <Input
            value={vazaoAgua}
            onChange={(e) => setVazaoAgua(e.target.value)}
            label="Vazão de Água"
            metric="kg/h"
          /> */}
          <MaskedInput
            label="Vazão de Água"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('vazaoAgua', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Kg/h</InputAdornment>
              ),
            }}
            helperText={errors.vazaoAgua && 'Campo obrigatório'}
            error={errors.vazaoAgua}
          />

          {/* <Input
            value={temperaturaAgua}
            onChange={(e) => setTemperaturaAgua(e.target.value)}
            label="Temperatura da Água"
            metric="°C"
          /> */}
          <MaskedInput
            label="Temperatura da Água"
            sx={{ m: 1 }}
            mask="decimalSeparatedNumber"
            {...register('temperaturaAgua', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
            helperText={errors.temperaturaAgua && 'Campo obrigatório'}
            error={errors.temperaturaAgua}
          />

          {/* <Input
            value={velocidadeAdotada}
            onChange={(e) => setVelocidadeAdotada(e.target.value)}
            label="Velocidade Adotada na Linha"
            metric="m/s"
            tip={velocidadeIdeal()}
          /> */}
          <MaskedInput
            label="Velocidade Adotada na Linha"
            sx={{ m: 1 }}
            mask="currencyToFixed1"
            {...register('velocidadeAdotada', { required: true })}
            InputProps={{
              endAdornment: <InputAdornment position="end">m/s</InputAdornment>,
            }}
            helperText={
              errors.velocidadeAdotada
                ? 'Campo obrigatório'
                : watch('tipoTubulacao') === 1
                ? 'Velocidade Recomendada para Sucção 1 a 1,5 m/s'
                : 'Velocidade Recomendada para Recalque 2 a 2,5 m/s'
            }
            error={errors.velocidadeAdotada}
          />

          <Box sx={{ m: 1 }}>
            <Button
              //disabled={!isFormValid()}
              className={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              Calcular Bitola para Linha de Água
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}

function Input({ label, metric, value, onChange, tip }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ m: 1 }} variant="outlined">
      {!tip && (
        <FormHelperText id="outlined-weight-helper-text">
          {label}
        </FormHelperText>
      )}
      {tip && (
        <Stack direction="row" alignItems="center">
          <FormHelperText id="outlined-weight-helper-text">
            {label}
          </FormHelperText>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tip}
              >
                <Button onClick={handleTooltipOpen}>?</Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Stack>
      )}

      <OutlinedInput
        id="outlined-adornment-weight"
        endAdornment={<InputAdornment position="end">{metric}</InputAdornment>}
        value={value}
        onChange={onChange}
        aria-describedby="outlined-weight-helper-text"
      />
    </FormControl>
  );
}
