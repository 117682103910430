import {
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from 'view/components/Header/Header';
import { Container } from 'view/components/Input/styles';

import { caldeiras } from 'constantes/caldeiras';
import { Controller, useForm } from 'react-hook-form';

import { createTask } from 'services/deals';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

export default function Contato() {
  const [searchParams, setSearchParams] = useSearchParams();
  let listCaldeiras = [];
  Object.keys(caldeiras).forEach((key) =>
    caldeiras[key].forEach((caldeira) => listCaldeiras.push(caldeira.nome))
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const onSubmit = async (data) => {
    const mensagem = `
      Pedido feito por ${data.nome.toUpperCase()},

      Email: ${data.email}
      Telefone: ${data.telefone}

      Produto desejado: ${data.tipo_produto}
       ${
         data.tipo_produto === 'Caldeira'
           ? `Caldeira desejada: ${
               data.caldeira ? data.caldeira : searchParams.get('caldeira')
             }`
           : ''
       }
      Especificações: 
      ${data.especificacoes ? data.especificacoes : 'Não informado'}

      Mensagem: 
      ${data.mensagem ? data.mensagem : 'Não informado'}
    `;

    try {
      const horarioAtual = new Date();

      const data = {
        text: mensagem,
        type: 'PROPOSTA',
        assigned_users: [485552, 642776],
        due_date: horarioAtual,
      };

      const res = await createTask(data);

      if (res.status === 200) {
        toast.success('Pedido enviado com sucesso!');
      } else {
        toast.error('Erro ao enviar pedido!');
      }
    } catch (e) {
      toast.error('Erro ao enviar pedido!');
      console.log(e);
    }
  };

  useEffect(() => {
    setValue('tipo_produto', 'Caldeira');
  }, []);

  return (
    <Container>
      <Header title="Contato" />
      <Paper style={{ flex: 1, padding: '2rem', borderRadius: 0 }}>
        <Stack alignItems="center">
          <Typography variant="h4" sx={{ fontSize: '2rem' }} gutterBottom>
            Fale com a <span style={{ color: 'rgb(250, 2, 2)' }}>Steam</span>
            master
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            align="center"
            gutterBottom
          >
            Entre em contato conosco e veja qual de nossos produtos e serviços
            podem atender a sua necessidade.
          </Typography>
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{ width: '100%', mt: 2 }}>
              <Typography variant="h6">Informações de contato</Typography>
              <TextField
                label="Nome Empresa"
                fullWidth
                {...register('nome', { required: true })}
              />
              <TextField
                label="Telefone/Celular"
                fullWidth
                {...register('telefone', { required: true })}
              />
              <TextField
                label="E-mail"
                fullWidth
                {...register('email', { required: true })}
              />
              {/* {searchParams.get('tipo') ? ( */}
              <Typography variant="h6">Produto desejado</Typography>
              <FormControl fullWidth>
                <InputLabel id="produto">Produto Desejado</InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="produto"
                      id="demo-simple-select"
                      label="Produto Desejado"
                      defaultValue={searchParams.get('tipo')}
                    >
                      <MenuItem value="Caldeira">Caldeira</MenuItem>
                      <MenuItem value="Queimador">Queimador</MenuItem>
                      <MenuItem value="Tanque Desaérador">
                        Tanque Desaérador
                      </MenuItem>
                      <MenuItem value="Tanque de Condensado">
                        Tanque de Condensado
                      </MenuItem>
                      <MenuItem value="Tanque de Descarga">
                        Tanque de Descarga
                      </MenuItem>
                      <MenuItem value="Economizador">Economizador</MenuItem>
                      <MenuItem value="Pré Aquecedor">Pré Aquecedor</MenuItem>
                      <MenuItem value="Assistência Técnica">
                        Assistência Técnica
                      </MenuItem>
                      <MenuItem value="Outros">Outros</MenuItem>
                    </Select>
                  )}
                  control={control}
                  name="tipo_produto"
                  defaultValue={'Caldeira'}
                />
              </FormControl>
              {((searchParams.get('tipo') === 'Caldeira' &&
                watch('tipo_produto') === 'Caldeira') ||
                watch('tipo_produto') === 'Caldeira') && (
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={listCaldeiras}
                      getOptionLabel={(option) => option}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Caldeira Desejada"
                          fullWidth
                        />
                      )}
                    />
                  )}
                  defaultValue={searchParams.get('caldeira') || 'JPO'}
                  name="caldeira"
                  control={control}
                />
              )}
              <TextField
                label="Especificações desejadas (Opcional)"
                fullWidth
                multiline
                rows={4}
                {...register('especificacoes')}
              />
              <Typography variant="h6">Mensagem</Typography>
              <TextField
                id="outlined-multiline-flexible"
                label="Mensagem"
                fullWidth
                multiline
                rows={2}
                {...register('mensagem')}
              />
              <Button variant="contained" type="submit">
                Enviar
              </Button>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Container>
  );
}
