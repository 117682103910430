import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from 'view/components/Header/Header';
import { Container } from 'view/components/Input/styles';
import Logo from 'assets/logo.png';
import JPO from 'assets/jpo-1.jpg';
import Img from 'assets/DF-4-scaled.jpg';
import Img2 from 'assets/FOUR-10-1-scaled.jpg';
import Img3 from 'assets/VME-1.jpeg';

import styles from './styles.module.scss';
import { useOpenSideBar } from 'Context/OpenSidebar';

export default function Home() {
  const navigate = useNavigate();
  const { setState } = useOpenSideBar();

  return (
    <Container>
      <Header title="Home" />
      <Paper style={{ flex: 1, padding: '2rem', borderRadius: 0 }}>
        <img src={Logo} alt="logo" style={{ width: '300px' }} />
        <Grid container spacing={2} mt={3}>
          <Grid item xs={6} md={2} sm={6}>
            <CardHome
              title="Datasheets"
              img={JPO}
              handleClick={() => navigate('/datasheets')}
            />
          </Grid>
          <Grid item xs={6} md={2} sm={6}>
            <CardHome
              title="Caldeiras"
              img={Img}
              handleClick={() => navigate('/caldeiras')}
            />
          </Grid>
          <Grid item xs={6} md={2} sm={6}>
            <CardHome
              title="Cálculos"
              img={Img2}
              handleClick={() => setState(true)}
            />
          </Grid>
          <Grid item xs={6} md={2} sm={6}>
            <CardHome
              title="Contato"
              img={Img3}
              handleClick={() => navigate('/contato')}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

function CardHome({ title, handleClick, img }) {
  return (
    <Button
      sx={{
        display: 'block',
        padding: 0,
        textTransform: 'none',
      }}
      onClick={handleClick}
    >
      <Paper style={{ position: 'relative' }} elevation={5}>
        <img
          src={img}
          alt="background"
          style={{
            width: '100%',
            aspectRatio: '2 / 1.7',
            borderRadius: 4,
            display: 'block',
            filter: 'brightness(60%)',
          }}
        />
        <Typography
          variant="h5"
          sx={{
            position: 'absolute',
            left: '0.3rem',
            bottom: '0.3rem',
            color: 'white',
          }}
        >
          {title}
        </Typography>
      </Paper>
    </Button>
  );
}
