import AirIcon from '@mui/icons-material/Air';
import WaterIcon from '@mui/icons-material/Water';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ArticleIcon from '@mui/icons-material/Article';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';

import { useNavigate } from 'react-router-dom';

export default function MenuDrawerLateral({ anchor, open, onClose, onOpen }) {
  const navigate = useNavigate();

  const routes = [
    // {
    //     label: 'Home',
    //     icon: <HomeIcon />,
    //     route: '/home'
    // },
    {
      label: 'Eficiência',
      icon: <LocalFireDepartmentIcon />,
      route: '/eficiencia',
    },
    {
      label: 'Ventilador Ideal',
      icon: <AirIcon />,
      route: '/ventiladorIdeal',
    },
    {
      label: 'Bomba de Água',
      icon: <WaterIcon />,
      route: '/bombaDeAgua',
    },
    {
      label: 'Duto de Ar',
      icon: <AirIcon />,
      route: '/dutoDeAr',
    },
    {
      label: 'Linha de Água',
      icon: <WaterIcon />,
      route: '/linhaDeAgua',
    },
    {
      label: 'Linha de Vapor',
      icon: <AirIcon />,
      route: '/linhaDeVapor',
    },
    // {
    //   label: 'Comparativo Combustíveis',
    //   icon: <OilBarrelIcon />,
    //   route: '/home/comparativoCombustiveis',
    // },
    {
      label: 'Consumo de Vapor Desareador',
      icon: <AirIcon />,
      route: '/vaporDesareador',
    },
    {
      label: 'Propriedades do Vapor',
      icon: <AirIcon />,
      route: '/propiedadesVapor',
    },
    // {
    //   label: 'Testes',
    //   icon: <OilBarrelIcon />,
    //   route: '/testes',
    // },
  ];

  const handleNavigate = (route) => {
    navigate(route.route);
  };

  const list = () => (
    <Box
      sx={{ width: 280 }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      <Box
        sx={{
          backgroundColor: 'primary.main',
          padding: '16px',
        }}
      >
        <Stack spacing={2}>
          {/* <Avatar sx={{ bgcolor: 'white', color: 'primary.main' }}>IR</Avatar> */}
          <Stack spacing={0.2} mt={5}>
            <Typography variant="h6" sx={{ color: 'white' }}>
              Steammaster
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: 'white' }}
              onClick={() =>
                (document.location = 'mailto:comercial@steammaster.com.br')
              }
            >
              comercial@steammaster.com.br
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <List>
        <ListItem
          button
          onClick={() => {
            window.location.href = 'https://steammaster.com.br/';
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={'Home'} />
        </ListItem>
      </List>

      <List>
        <Typography variant="h6" sx={{ padding: '8px 16px' }}>
          Cálculos
        </Typography>

        {routes.map((route) => {
          return (
            <ButtonLista
              key={route.label}
              route={route}
              handleNavigate={handleNavigate}
            />
          );
        })}
      </List>

      <Divider />
      <List>
        <Typography variant="h6" sx={{ padding: '8px 16px' }}>
          Caldeira Ideal
        </Typography>

        <ListItem button onClick={() => navigate('/caldeiras')}>
          <ListItemIcon>
            <PriceCheckIcon />
          </ListItemIcon>
          <ListItemText primary={'Caldeira Ideal'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <Typography variant="h6" sx={{ padding: '8px 16px' }}>
          Custo Anual
        </Typography>

        <ListItem button onClick={() => navigate('/comparativoCombustiveis')}>
          <ListItemIcon>
            <LocalAtmIcon />
          </ListItemIcon>
          <ListItemText primary={'Comparativo de Combustíveis'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <Typography variant="h6" sx={{ padding: '8px 16px' }}>
          Caldeiras
        </Typography>
        <ListItem button onClick={() => navigate('/datasheets')}>
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary={'Datasheets'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <Typography variant="h6" sx={{ padding: '8px 16px' }}>
          Contato
        </Typography>
        <ListItem button onClick={() => navigate('/contato')}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary={'Contato'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <Typography variant="h6" sx={{ padding: '8px 16px' }}>
          Outras Opções
        </Typography>
        <ListItem button onClick={() => navigate('/configuracoes')}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={'Configurações'} />
        </ListItem>
        {/* <ListItem button>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Sair'} />
        </ListItem> */}
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      {list()}
    </SwipeableDrawer>
  );
}

function ButtonLista({ route, handleNavigate }) {
  return (
    <ListItem button onClick={() => handleNavigate(route)}>
      <ListItemIcon>{route.icon}</ListItemIcon>
      <ListItemText primary={route.label} />
    </ListItem>
  );
}
