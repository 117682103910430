import { useEffect } from 'react';

//MUI
import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

//SCSS
import styles from '../../../styles/Calculo.module.scss';

//React Router
import { useNavigate } from 'react-router-dom';
import { MaskedInput } from 'view/components/MaskedMuiInput';
import { useForm } from 'react-hook-form';

//Tirar da index.jsx e colocar na Dados do Projeto
export default function ComparativoCustoCombustivel() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const dadosProjeto = JSON.parse(localStorage.getItem('dadosProjeto'));
    if (dadosProjeto) {
      setValue('producaoVapor', dadosProjeto.producaoVapor);
      setValue('temperaturaEntrada', dadosProjeto.temperaturaEntrada);
      setValue('pressaoMaxima', dadosProjeto.pressaoMaxima);
      setValue('horasTrabalhadas', dadosProjeto.horasTrabalhadas);
    }
  }, []);

  const onSubmit = (data) => {
    localStorage.setItem('dadosProjeto', JSON.stringify(data));
    navigate('/comparativoCombustiveis');
  };

  return (
    <div className={styles.container}>
      <Paper elevation={0} className={styles.paper}>
        <Typography variant="h6" sx={{ p: 4 }}>
          Preencha as informações do projeto, antes de fazer a comparação de
          combustíveis.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <MaskedInput
            mask="decimalSeparatedNumber"
            label="Produção de vapor"
            metric="Kg/h"
            sx={{ m: 1 }}
            {...register('producaoVapor', { required: true })}
          />
          <MaskedInput
            mask="decimalSeparatedNumber"
            label="Temperatura da água de alimentação da caldeira"
            metric="°C"
            sx={{ m: 1 }}
            {...register('temperaturaEntrada', { required: true })}
          />
          <MaskedInput
            mask="decimalSeparatedNumber"
            label="Pressão máxima de Operação (PMO)"
            sx={{ m: 1 }}
            {...register('pressaoMaxima', { required: true })}
          />
          <MaskedInput
            mask="decimalSeparatedNumber"
            label="Horas trabalhadas por dia"
            metric="horas"
            sx={{ m: 1 }}
            {...register('horasTrabalhadas', { required: true })}
          />

          <Box sx={{ m: 1 }}>
            <Button
              className={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              Cadastrar dados de projeto
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}
