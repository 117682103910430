const entalpia = [
    639,
    646,
    651,
    654,
    656,
    658,
    660,
    661,
    662,
    663,
    664,
    665,
    666,
    666,
    667,
    667,
    667,
    668,
    668,
    668,
    669,
    669,
    669,
    669,
    669,
    669,
    669,
    669,
    670,
    670,
    670,
    670,
    370,
    669,
    669,
    669,
    669,
    669,
    669,
    669,
    669,
    669,
    669,
    668,
    668,
    668,
    668,
    668,
    668,
    667,
    667,
    667,
    667,
    667,
    666,
    666,
    666,
    666,
    665,
    665
]

export default class DadosProjeto {
    constructor(
        producaoVapor,
        temperaturaEntrada,
        pressaoMaxima,
        horasTrabalhadas,
        rendimentoTermicoCombustivel1,
        rendimentoTermicoCombustivel2,
    ) {
        this.producaoVapor = producaoVapor;
        this.temperaturaEntrada = temperaturaEntrada;
        this.pressaoMaxima = pressaoMaxima;
        this.horasTrabalhadas = horasTrabalhadas;
        this.rendimentoTermicoCombustivel1 = rendimentoTermicoCombustivel1;
        this.rendimentoTermicoCombustivel2 = rendimentoTermicoCombustivel2;
    }

    getPressaoMaximaPermitida() {
        const resultado = this.pressaoMaxima + 2;
        return Math.round(resultado).toFixed(0)
    }

    getPressaoTesteHidrostatico() {
        const resultado = this.getPressaoMaximaPermitida() * 1.5;
        return Math.round(resultado).toFixed(0);
    }

    getCapacidadeTermica() {
        const resultado = this.producaoVapor * (this.getEntalpiaVapor() - this.temperaturaEntrada);
        return Math.round(resultado).toFixed(0);
    }

    getCargaTermicaCombustivel1() {
        const resultado = this.getCapacidadeTermica() / this.rendimentoTermicoCombustivel1;
        return Math.round(resultado).toFixed(0);
    }

    getCargaTermicaCombustivel2() {
        const resultado = this.getCapacidadeTermica() / this.rendimentoTermicoCombustivel2;
        return Math.round(resultado).toFixed(0);
    }

    getEntalpiaVapor() {
        const indexEntalpia = this.pressaoMaxima;
        return entalpia[indexEntalpia];
    }
}