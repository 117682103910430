import React, { createContext, useContext, useState } from 'react';

export const SwitchTheme = createContext()

export default function SwitchThemeProvider({children}) {

    const [theme, setTheme] = useState('light')

    return (
        <SwitchTheme.Provider value={{theme, setTheme}}>
            {children}
        </SwitchTheme.Provider>
    )
}

export function useSwitchTheme(){
    const context = useContext(SwitchTheme)
    const {theme, setTheme} = context
    return {theme, setTheme}
}
