import { Box } from '@mui/material';
import styles from '../../styles/Resultados.module.scss';
import ShareButton from '../ShareButton';

export default function ItemPrincipal({
  nome,
  valor,
  medida,
  resultados,
  shareTitle,
}) {
  const gradient = (theme) => {
    return `linear-gradient(180deg, rgba(35, 35, 35, 0.189) 80%, ${theme} 100%)`;
  };

  return (
    <Box
      className={styles.header}
      sx={{
        background: (theme) => gradient(theme.palette.primary.main),
        position: 'relative',
      }}
    >
      <h1>{nome}</h1>
      <div className={styles.principal}>
        {valor} {medida}
      </div>
      {resultados && shareTitle && (
        <ShareButton
          sx={{ alignSelf: 'flex-end' }}
          resultados={resultados}
          title={shareTitle}
        />
      )}
    </Box>
  );
}
